import { Button } from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";
import LockImg from "../../Assets/Login/Lock.png";
import styles from "./index.module.css";

function LockScreen({setLockState = () => {}, setLoginState = () => {}}) {

  const handleClick = () => {
    setLoginState(1);
    setLockState(1);
  }
  
  return (
    <div>
      <div className={`${styles.mainLogin} ${styles.justifyContent}`}>
        <img src={LockImg} alt="lock screen" className={styles.lockedimg} />
        <h2 className={styles.head}>System Locked!</h2>
        <p className={styles.textlock}>
          You've reached the maximum allowed login attempts. For security
          reasons, the system has been locked. Please contact your administrator
          for assistance
        </p>
        <Button type="primary" onClick={handleClick} className={styles.btn}>Exit</Button>
      </div>
    </div>
  );
}

export default LockScreen;
