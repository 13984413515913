import React from 'react'
import { BrowserRouter as Router, Routes, Route, Navigate, Outlet } from 'react-router-dom';
import { getLocalData } from '../Utils/helperFunction';

function Protected({ element, ...rest }) {
    const isAuthenticated = getLocalData("dmc_user");
  
    // return isAuthenticated?.token ? (
    //   <Route {...rest} element={element} />
    // ) : (
    //   <Navigate to="/login" replace />
    // );
    return (isAuthenticated?.token) ? <Outlet /> : <Navigate to="/login" />;

}

export default Protected