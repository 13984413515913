import React, { useEffect, useState } from "react";
import { Table, Input, DatePicker } from "antd";
import { api_call_token } from "../../Utils/Network";
import moment from "moment";
import styles from "./index.module.css";

const Subcomponent = ({ id }) => {
  const [goals, setGoals] = useState([]);
  const [goalId, setGoalId] = useState(null);
  const [data, setData] = useState([]);
  const [date, setDate] = useState(null);

  const [checkAchievement, setCheckAchievement] = useState({});
  const [checkBlank, setCheckBlank] = useState({})

  useEffect(() => {
    api_call_token
      .get(`goalpip/foruser?goalType=${id}`)
      .then((res) => {
        console.log(res.data.data);
        setGoals(res.data.data);
        let arrs = [];
        res.data.data.length > 0 &&
          res.data.data.map((val, idx) => {


            val?.goalKpiList.map((values, idx) => {
              let obj = {
                key: values?.kpiId,
                goalId: val?.goalId,
                name: values?.kpiName,
                description: values?.kpiDescription,
                achievement: 0,
                uomId: values?.uomId,
                uom: values?.uomName,
                target: values?.kpiTarget,
              };
              arrs.push(obj);
            });
            setData(arrs);
          });
      })
      .catch((err) => {
        console.log(err);
      });
  }, [id]);

  // const handleValues = (val) => {
  //   let data = val;
  //   let arrs = [];

  //   data?.goalKpiList.map((values, idx) => {
  //     let obj = {
  //       key: values?.kpiId,
  //       name: values?.kpiName,
  //       description: values?.kpiDescription,
  //       achievement: values?.kpiTargetAchievmentValue || 0,
  //       uom: values?.uomName,
  //       target: values?.kpiTarget,
  //     };
  //     arrs.push(obj);
  //   });
  //   setData(arrs);
  //   setGoalId(val?.goalId);
  // };

  // const handleRecord = (e, vals) => {
  //   console.log(e.target.value, vals, "VALSSSSSSS");

  //   let indexValue = data.findIndex((val) => {
  //     return val?.key == vals.key;
  //   });
  //   data[indexValue].achievement = e.target.value;
  //   setData(data);
  //   console.log(indexValue, "VALSSSSSSS");
  // };

  const handleRecord = (e, vals) => {
    const updatedData = data.map((item) => {
      if (item.key === vals.key) {
        return {
          ...item,
          achievement: e.target.value,
        };
      }
      return item;
    });

    // Determine if remarks field should be required based on achievement value
    const updatedRow = updatedData.find((item) => item.key === vals.key);
    //  const remarksRequired = updatedRow && +updatedRow.achievement < +updatedRow.target;


    setData(updatedData);
    setCheckAchievement((prevState) => ({
      ...prevState,
      // [vals.key]: remarksRequired ? 'required' : null,
      [vals.key]: updatedRow?.achievement ? 'required' : null,
    }));
  };

  const handleRemarks = (e, vals) => {
    const updatedData = data.map((item) => {
      if (item.key === vals.key) {
        return {
          ...item,
          reasonAchievement: e.target.value,
        };
      }
      return item;
    });

    const updatedRow = updatedData.find((item) => item.key === vals.key);
    // Check if the Remarks field is filled
    const remarksFilled = updatedRow.reasonAchievement.trim() !== " ";

    // Update status based on Remarks field being filled
    setCheckBlank((prevState) => ({
      ...prevState,
      [vals.key]: remarksFilled ? "error" : null,
    }));

    setData(updatedData);
  };
  const handleSubmit = () => {
    console.log(data, "Final Data");
    let finalIndex = [];
    let allFieldsFilled = true; // Flag to check if all required fields are filled
    data.map((val, idx) => {
      let flData = {
        goalId: val?.goalId,
        kpiId: val?.key,
        achivement: +val?.achievement,
        achivementDate: date ? date : moment().format("YYYY-MM-DD"),
        goalTypeId: id,
        uomId: val?.uomId,
        uomName: val?.uom,
        kpiTarget: val?.target,
        reasonAchievement: val?.reasonAchievement,
        // departmentId:val?.departmentId,
      };

      // Check if the Remarks field is required and empty
      if ((checkAchievement[val.key] === "required") && (!val.reasonAchievement || val.reasonAchievement.trim() === "")) {
        allFieldsFilled = false;
      }

      finalIndex.push(flData);
    });

    if (!allFieldsFilled) {
      alert("Please fill in all required fields.");
      return; // Exit the function if any required field is empty
    }

    api_call_token
      .post(`goalkpiachievementpip`, finalIndex)
      .then((res) => {
        if (res.status == 200 || res.status == 201) {
          alert("success");
          window.location.reload();
        }
      })
      .catch((err) => console.log(err));

    console.log(finalIndex, "FinalIndex");
  };

  const columns = [
    {
      title: "Goal Name",
      dataIndex: "name",
      key: "name",
      width: 200,
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      width: 200,
    },
    {
      title: "Achievement",
      dataIndex: "achievement",
      key: "achievement",
      width: 300,
      render: (_, record) => (
        <Input onChange={(e) => handleRecord(e, record)} />
      ),
    },
    { title: "UOM", dataIndex: "uom", key: "uom", width: 200 },
    {
      title: "Remarks",
      dataIndex: "reasonAchievement",
      key: "reasonAchievement",
      width: 300,
      render: (_, record) => (<>
        <Input required={checkAchievement && checkAchievement[record.key] === "required"}
          // status={checkAchievement && checkAchievement[record.key] === "required" && "error"} 
          onChange={(e) => handleRemarks(e, record)} />
        {checkAchievement && checkAchievement[record.key] === "required" ? (<p style={{ margin: "0", color: "#ff4d4f", fontSize: "12px" }}>*required </p>) : ""}
      </>
      ),
    },
    {
      title: "Target", dataIndex: "target", key: "target",
      render: (_) => {
        const num = _;
        const roundedNum = parseFloat(num.toFixed(2));
        return (
          <div>{roundedNum}</div>
        )
      }
    },
  ];

  const handleChange = (e) => {
    console.log(e, "EEE");
    let data = moment(e?.$d).format("YYYY-MM-DD");
    console.log(data, "EEE");
    setDate(data);
  };
  return (
    <>
      <div>
        <div style={{ margin: "10px 0" }}>
          <span>Date</span>
          <DatePicker
            onChange={handleChange}
            disabledDate={(current) =>
              current.isAfter(moment().subtract(0, "day"))
            }
            style={{ display: "block", width: "fit-content" }}
          />
        </div>
      </div>
      {goals.length > 0 ? (
        <div>
          {/* <div className={styles.boxContainer}>
            {goals.length > 0 &&
              goals.map((val, idx) => (
                <div
                  className={`${styles.boxData} ${
                    val?.goalId == goalId ? styles.yellow : ""
                  }`}
                  onClick={() => handleValues(val)}
                >
                  {val?.goalName}
                </div>
              ))}
          </div> */}
          <Table dataSource={data} columns={columns} pagination={false} />
          <div className={styles.buttonContainer}>
            <button onClick={() => handleSubmit()} className={styles?.saveData}>
              Save Data
            </button>
          </div>
        </div>
      ) : (
        <div>No Goal Available for this Type</div>
      )}
    </>
  );
};

export default Subcomponent;

/**
 * [
        { key: '1', description: 'Machine Production', achievement: 0, uom: 'MT', target: 2000 },
        { key: '2', description: 'Rewinder Production', achievement: 0, uom: 'MT', target: 1000 },
        { key: '3', description: 'Another Production', achievement: 0, uom: 'MT', target: 200 },
    ]
 */
