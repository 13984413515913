import { Button } from 'antd'
import React from 'react'
import { api_call_token } from '../../Utils/Network'
import deleteIcon from "../../Assets/ICONS/deleteBox.png"
import { alertMessage } from '../../Utils/helperFunction';
 
function DeleteBox({data, setOpen = () => {},getGoals, text}) {

  console.log(data?.goalKPIAchievementsId, "DeleteBox");

const handelDelete = () => {
  api_call_token 
  .delete(`goalkpiachievement/${data?.goalKPIAchievementsId}`)
  .then((res) => {
    if(res?.status === 200 || res?.status === 201 || res?.status === 204){
      setOpen(false)
      getGoals();
      alertMessage("Goal Achivement Deleted ")
    }
   
  })
  .catch((err) => {
    console.log(err);
  })
}
  return (
    <div style={{textAlign:"center"}}>
      <div>
        <img src={deleteIcon} alt='delete'/>
      </div>
        <h2>{text}</h2>
        <p>By Deleteing this goal Achivement all data regarding this Achivement will be deleted.  </p>
        <Button type="primary" onClick={handelDelete}>Delete Achivement</Button>
    </div>
  )
}

export default DeleteBox