import { Pagination, Table } from "antd";
import React, { useEffect, useState } from "react";
import { api_call_token } from "../../../Utils/Network";
import moment from "moment";

function PIPList() {
  const [kpiData, setKpiData] = useState([]);
  const [filterObj, setFilterObj] = useState({
    page: 1,
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [totalNumberofPage, setTotalNumberofPage] = useState(null);

  useEffect(() => {
    getkpiData();
  }, [filterObj]);

  const getkpiData = (paramObj = { ...filterObj}) => {
    api_call_token
      .get("goalKpiPIP/pipforuser", { params: {...paramObj}})
      .then((res) => {
        console.log(res.data.data);
        setKpiData(res.data.data);
        setCurrentPage(res.data.currentPage);
        setTotalNumberofPage(res.data.totalRowCount);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handlePageChange = (page) => {
    console.log(page, "aaaaaaaaa");
    setFilterObj({ ...filterObj, page: page });
    setCurrentPage(page);
  };

  const columns = [
    { title: "KPI Id", dataIndex: "kpiId", key: "kpiId"},
    { title: "KPI Name", dataIndex: "kpiName", key: "kpiName" },
    { title: "Due Date", dataIndex: "kpiDueDate", key: "kpiDueDate", render: (_) => <div>{moment(_).format("DD-MMMM-YYYY")}</div> },
    {
      title: "KPI Description",
      dataIndex: "kpiDescription",
      key: "kpiDescription",
    },
    { title: "KPI Start Date", dataIndex: "kpiStartDate", key: "kpiStartDate", render: (_) => <div>{moment(_).format("DD-MMMM-YYYY")}</div>, },
    { title: "KPI Target", dataIndex: "kpiTarget", key: "kpiTarget", render: (_) => <div>{moment(_).format("DD-MMMM-YYYY")}</div>, },
    {
      title: "KPI Target Achievement",
      dataIndex: "kpiTargetAchievement",
      key: "kpiTargetAchievement",
    },
    { title: "KPI Type", dataIndex: "goalTypeName", key: "goalTypeName" },
    { title: "KPI Weightage", dataIndex: "kpiWeightage", key: "kpiWeightage" },
  ];

  const paginationConfig = {
    current: currentPage,
    pageSize: 10, // Number of items per page
    total: totalNumberofPage, // Total number of items
    onChange: handlePageChange,
  };

  return (
    <div>
      <Table
        columns={columns}
        dataSource={kpiData}
        pagination={false} // Adjust as needed
        scroll={{ x: true }} // Enable horizontal scrolling if needed
      />
      <div style={{ textAlign: "right", margin: "10px" }}>
        <Pagination {...paginationConfig} showSizeChanger={false}/>
      </div>
    </div>
  );
}

export default PIPList;
