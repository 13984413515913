import React, { useContext, useEffect, useState } from "react";
import styles from "./index.module.css";
import { Button, Col, DatePicker, Input, Pagination, Row, Select, Table } from "antd";
import { api_call_token, base_url } from "../../Utils/Network";
import moment from "moment";
import { UserCredsContext } from "../../Utils/UserCredsContext";
import Green from "../../Assets/reports/green.png";
import Red from "../../Assets/reports/red.png"; 
import { SearchOutlined } from "@ant-design/icons";


function PIPReports() {
    const [goalType, setGoalType] = useState([]);
    const [department, setDepartment] = useState([]);
    const [categoryType, setCategory] = useState([]);
    const [userType, setUserType] = useState([]);
    const { userState, logout } = useContext(UserCredsContext);
    const [data, setData] = useState([]);
    const [expandedRowKey, setExpandedRowKey] = useState(null);
    const [filterObj, setFilterObj] = useState({
      page: 1,
    });
    const [reportFilter, setReportFilter] = useState({})
    const [currentPage, setCurrentPage] = useState(1);
    const [totalNumberofPage, setTotalNumberofPage] = useState(null);
    const [selectedGoal, setSelectedGoal] = useState(null);
    const [kpiData, setKpiData] = useState([]);
    useEffect(() => {
      getKpi();
      getDepartment();
      getCategory();
    //   getallReports();
      getallUser();
    }, []);
  
    const getallUser = () => {
      api_call_token
        .get(`/createuser/alluser?size=100`)
        .then((res) => {
          setUserType(res.data.data);
        })
        .catch((err) => console.log(err));
    };
  
    // const getallReports = () => {
    //   api_call_token
    //     .get(`goalkpiachievement/mydata`)
    //     .then((res) => {})
    //     .catch((err) => console.log(err));
    // };
  
    const getCategory = () => {
      api_call_token
        .get(`category?size=100`)
        .then((res) => {
          setCategory(res.data.data);
        })
        .catch((err) => console.log(err));
    };
  
    const getDepartment = () => {
      api_call_token
        .get(`department?size=100`)
        .then((res) => {
          setDepartment(res.data.data);
        })
        .catch((err) => console.log(err));
    };
  
    const getKpi = () => {
      api_call_token
        .get(`goaltype`)
        .then((res) => {
          setGoalType(res.data.data);
        })
        .catch((err) => console.log(err));
    };
  
    const selectedGoals = (v) => {
      setSelectedGoal(v?.goalId);
      setKpiData(v.goalKpiList);
    };
  
    const expandedRowRender = (record) => {
      console.log(record, "RRRRRR");
      const columns = [
        { title: "Added By", dataIndex: "userName", key: "userName" },
        {
          title: "Achieved",
          dataIndex: "achivement",
          key: "achivement",
          render: (_, record) => {
            console.log(record, "sss");
            const num = _;
            const roundedNum = parseFloat(num.toFixed(2));
            return (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  width: "100px",
                  color: `${record.kpiTarget > _ ? "#DB4437" : "#2EB67D"}`,
                }}
              >
                {`${roundedNum} ${record.uomName}`}
                <img
                  style={{ marginLeft: "10px" }}
                  src={record.kpiTarget > _ ? Red : Green}
                  alt="img"
                />
              </div>
            );
          },
        },
        {
          title: "Target",
          dataIndex: "kpiTarget",
          key: "kpiTarget",
          render: (_, rec) => {
            const num = _;
            const roundedNum = parseFloat(num.toFixed(2));
            return <div>{`${roundedNum} ${rec.uomName}`}</div>;
          },
        },
        { title: "Date of Achievement", dataIndex: "date" },
        { title: "Date of Entry", dataIndex: "tillDate" },
        {
          title: "Month Target",
          dataIndex: "monthTarget",
          render: (_, rec) => {
            const num = _;
            const roundedNum = parseFloat(num.toFixed(2));
            return <div>{`${roundedNum} ${rec.uomName}`}</div>;
          },
        },
        {
          title: "Balance",
          dataIndex: "achivementBalance",
          render: (_, record) => {
            console.log(_, "RECORD");
            const num = _;
            const roundedNum = parseFloat(num.toFixed(2));
            return (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  width: "100px",
                  color: `#DB4437`,
                }}
              >
                {`${roundedNum} ${record.uomName}`}
                <img style={{ marginLeft: "10px" }} src={Red} alt="img" />
              </div>
            );
          },
        },
        {
          title: "Reason",
          dataIndex: "reasonAchievement",
          // key:"reasonAchievement",
          render: (_, record) => {
            console.log(_, record, "reasonAchievement");
            return <div>{_}</div>;
          },
        },
      ];
  
      const data = record.goalKpiAchievementList.map((kpi) => ({
        key: kpi.kpiId,
        userName: kpi.userName,
        goal: kpi.goalName,
        uomName: kpi.uomName,
        kpiTarget: kpi.kpiTarget,
        achivement: kpi.achivement,
        date: moment(kpi.achivementDate).format("DD-MMMM-YYYY"),
        tillDate: moment(kpi.createdAt).format("DD-MMMM-YYYY"),
        monthTarget: record.kpiTargetTotal, // Assuming kpiTarget is a date
        // balance: kpi.kpiTarget - kpi.kpiTargetAchievement, // Calculate balance here
        achivementBalance: kpi.achivementBalance, // Calculate balance here
        reasonAchievement: kpi.reasonAchievement,
  
        // Add more properties if needed
      }));
  
      return <Table columns={columns} dataSource={data} pagination={false} />;
    };
  
    useEffect(() => {
      getGoals();
    }, [filterObj]);
  
    // const redirectTo = (para) => {
    //   console.log(para);
    //   navigate(`creategoal`, { state: para });
    // };
  
    const getGoals = (paramObj = { ...filterObj }) => {
      api_call_token
        .get(`goalkpiachievementpip/mydata`, { params: { ...paramObj } })
        .then((res) => {
          if (res.status === 200 || res.status === 201 || res.status === 204) {
            console.log(res?.data?.data, "goalkpiachievementpip");
            // let arr = [];
            // res.data.data.length > 0 &&
            //   res?.data?.data?.map((v, i) => {
            //     v.goalKpiList.map((x, idx) => {
            //       arr.push(x);
            //     });
            //   });
            // setData(arr);
            // setKpiData(arr);
            setKpiData(res?.data?.data)
            setCurrentPage(res.data.currentPage);
            setTotalNumberofPage(res.data.totalRowCount);
          } else if (res.status === 401) {
            logout();
          }
        })
        .catch((err) => console.log(err));
    };
  
    const handlePageChange = (page) => {
      console.log(page, "aaaaaaaaa");
      setFilterObj({ ...filterObj, page: page });
      setCurrentPage(page);
    };
  
    const handleRowClick = (record) => {
      console.log(record, "RECORDSSSSSS");
      setExpandedRowKey((prevKey) =>
        prevKey === record.key ? null : record.key
      );
    };
  
    const columns = [
      // { title: "Id", dataIndex: "goalId", key: "goalId" },
      { title: "Kpis", dataIndex: "kpiName", key: "kpiName", width: 200 },
      {
        title: "Goal",
        dataIndex: "kpiName",
        key: "kpiName",
        width: 150,
        render: (_) => <div className={styles.goalInKpi}>{_}</div>,
      },
      {
        title: "Achieved",
        dataIndex: "kpiTotal",
        key: "kpiTotal",
        render: (_, record) => {
          console.log(record, "RECORD");
          const num = _;
          const roundedNum = parseFloat(num.toFixed(2));
          console.log(roundedNum, "RECORD");
          return (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                width: "100px",
                whiteSpace: "nowrap",
                color: `${record.kpiTargetTotal > _ ? "#DB4437" : "#2EB67D"}`,
              }}
            >
              {roundedNum}
              {/* {`${((_) % 1 !== 0) ? Math.round(_): _} ${record.uomName}`} */}
              <img
                style={{ marginLeft: "10px" }}
                src={record.kpiTargetTotal > _ ? Red : Green}
                alt="img"
              />
            </div>
          );
        },
      },
      //   { title: "Target", dataIndex: "kpiTargetTotal", key: "kpiTargetTotal",
      //     render: (_, record) => {
      //       const num = _;
      //       const renderNum = parseFloat(num.toFixed(2))
      //       return(
      //         <>
      //         {renderNum}
      //         </>
      //       )
      //     }
      // },
      {
        title: "Date",
        dataIndex: "kpiStartDate",
        key: "kpiStartDate",
        render: (_) => <div>{moment(_).format("DD-MMMM-YYYY")}</div>,
      },
      {
        title: "Till Date",
        dataIndex: "kpiDueDate",
        key: "kpiDueDate",
        render: (_) => <div>{moment(_).format("DD-MMMM-YYYY")}</div>,
      },
      {
        title: "Month Target",
        dataIndex: "kpiTargetTotal",
        key: "kpiTargetTotal",
  
        render: (_, record) => {
          const num = _;
          const roundedNum = parseFloat(num.toFixed(2));
          return (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                whiteSpace: "nowrap",
                width: "80px",
              }}
            >{`${roundedNum} ${record.uomName}`}</div>
          );
        },
      },
      {
        title: "Balance",
        dataIndex: "kpiBalance",
        key: "kpiBalance",
        render: (_, record) => {
          console.log(record, "RECORD");
          const num = _;
          const roundedNum = parseFloat(num.toFixed(2));
          return (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                width: "100px",
                color: `#DB4437`,
                whiteSpace: "nowrap",
              }}
            >
              {`${roundedNum} ${record.uomName}`}
              <img style={{ marginLeft: "10px" }} src={Red} alt="img" />
            </div>
          );
        },
      },
    ];
  
    const saveExcel = (paramObj = { ...filterObj }) => {
      api_call_token
        .get(`excel/mydata`, { params: { ...paramObj } })
        .then((res) => {
          console.log(res.data, "res");
          if (res.status == 200) {
            const data = res.data.filePath;
            let val = data.split("public/");
            window.open(`${base_url}${val[1]}`);
          } else {
            alert("Something went wrong");
          }
        })
        .catch((err) => console.log(err));
    };
  
    const savePdf = (paramObj = { ...filterObj }) => {
      api_call_token
        .get(`pdf/mydata`, { params: { ...paramObj } })
        .then((res) => {
          if (res.status == 200) {
            const data = res.data.filePath;
            let val = data.split("public/");
            window.open(`${base_url}${val[1]}`);
          } else {
            alert("Something went wrong");
          }
        })
        .catch((err) => console.log(err));
    };
  
    const paginationConfig = {
      current: currentPage,
      pageSize: 10, // Number of items per page
      total: totalNumberofPage, // Total number of items
      onChange: handlePageChange,
    };
  
    console.log(reportFilter, "HHHHHHHHHHHHHH");
  
    // download yearly reports
  
    const handleReportDownload = (paramObj = { ...reportFilter }) => {
  
      api_call_token
      .get(`excel/pip/monthwisedata`,{ params: { ...paramObj } })
      .then((res) => {
        console.log(res?.data);
  
        if (res?.status == 200 || res?.status == 201 || res?.status == 204) {
          if(res?.data?.filePath){
            const data = res.data.filePath;
          let val = data.split("public/");
          window.open(`${base_url}${val[1]}`);
          } else if(res?.data?.message){
            alert(`${res?.data?.message}`)
          }
        } 
      })
      .catch((err) => {
        console.log(err);
      })
    }

    const onSearch = (e) => {
      let value = e.target.value;
      let obj = filterObj;
      if (value) {
        obj.search = value;
        setCurrentPage(1);
      } else delete obj.search;
      setFilterObj({ ...obj, page: 1 });
    };
  return (
    <div>
      <div className={styles.mainTopHeading}>
        <div className={styles.main}>
          <h2>PIP Reports</h2>
        </div>
        <div className={styles.downLoadReport}>
          <div>
          <DatePicker
            style={{ width: "100%" }}
            picker="year"
            onChange={(e) => {
              // debugger;
              let value = e?.$d || e;
              let obj = reportFilter;
              if (value) {
                obj.year = moment(value).format("YYYY");
                // setCurrentPage(1);
              } else delete obj.year;
              setReportFilter({ ...obj });
            }}
          />
          </div>
          <div>
                {/* <span>User</span> */}
              <Select
                showSearch
                style={{
                  width: "100%",
                }}
                placeholder="User"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? "")
                    .toLowerCase()
                    .localeCompare((optionB?.label ?? "").toLowerCase())
                }
                allowClear
                onChange={(e) => {
                  let value = e;
                  let obj = reportFilter;
                  if (value) {
                    obj.userFilter = value;
                    // obj.page = 1;
                    // setCurrentPage(1);
                  } else delete obj.userFilter;
                  setReportFilter({ ...obj });
                }}
                options={
                  userType &&
                  userType?.map((obj) => {
                    const keys = Object.keys(obj);
                    const convertedObj = {
                      value: obj["userId"],
                      label: `${obj["firstName"]} ${obj["lastName"]}`,
                    };
  
                    for (let i = 2; i < keys.length; i++) {
                      convertedObj[keys[i]] = obj[keys[i]];
                    }
  
                    return convertedObj;
                  })
                }
              />
              </div>
              <div>
               {/* <span>Department</span> */}
            {/* <Select
              showSearch
              style={{
                width: "100%",
              }}
              placeholder="Department"
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              filterSort={(optionA, optionB) =>
                (optionA?.label ?? "")
                  .toLowerCase()
                  .localeCompare((optionB?.label ?? "").toLowerCase())
              }
              allowClear
              onChange={(e) => {
                let value = e;
                let obj = reportFilter;
                if (value) {
                  obj.departmentFilter = value;
                  // obj.page = 1;
                  // setCurrentPage(1);
                } else delete obj.departmentFilter;
                setReportFilter({ ...obj });
              }}
              options={
                department &&
                department?.map((obj) => {
                  const keys = Object.keys(obj);
                  const convertedObj = {
                    value: obj["departmentId"],
                    label: obj["departmentName"],
                  };

                  for (let i = 2; i < keys.length; i++) {
                    convertedObj[keys[i]] = obj[keys[i]];
                  }

                  return convertedObj;
                })
              }
            /> */}
              </div>
          <div>
            <Button onClick={() => handleReportDownload()} className={styles.topdownloadeBtn}>
                Download Report
            </Button>
          </div>

        </div>
      </div>
      <div className={styles.reportsFilter}>
        <Row gutter={[12, 6]} style={{ alignItems: "end" }}>
        <Col xs={24} lg={8}>
            {/* <span>Category</span> */}
            <Input
              placeholder="Search by Goal, Goal Category"
              prefix={<SearchOutlined />}
              style={{ width: "100%" }}
              onChange={(e) => onSearch(e)}
            />
          </Col>
          <Col xs={24} lg={8}>
            <div style={{ display: "flex" }}>
              <div style={{ marginRight: "10px" }}>
                <span>From Date</span>
                <DatePicker
                  style={{ width: "100%" }}
                  onChange={(e) => {
                    // debugger;
                    let value = e?.$d || e;
                    let obj = filterObj;
                    if (value) {
                      obj.fromDate = moment(value).format("YYYY-MM-DD");
                      obj.page = 1;
                      setCurrentPage(1);
                    } else delete obj.fromDate;
                    setFilterObj({ ...obj });
                  }}
                />
              </div>
              <div>
                <span>To Date</span>
                <DatePicker
                  style={{ width: "100%" }}
                  onChange={(e) => {
                    let value = e?.$d || e;
                    let obj = filterObj;
                    if (value) {
                      obj.endDate = moment(value).format("YYYY-MM-DD");
                      obj.page = 1;
                      setCurrentPage(1);
                    } else delete obj.endDate;
                    setFilterObj({ ...obj });
                  }}
                />
              </div>
            </div>
          </Col>
          {/* <Col xs={12} lg={5}>
            <span>Department</span>
            <Select
              mode="multiple"
              showSearch
              style={{
                width: "100%",
              }}
              placeholder="Department"
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              filterSort={(optionA, optionB) =>
                (optionA?.label ?? "")
                  .toLowerCase()
                  .localeCompare((optionB?.label ?? "").toLowerCase())
              }
              allowClear
              options={
                department &&
                department?.map((obj) => {
                  const keys = Object.keys(obj);
                  const convertedObj = {
                    value: obj["departmentId"],
                    label: obj["departmentName"],
                  };

                  for (let i = 2; i < keys.length; i++) {
                    convertedObj[keys[i]] = obj[keys[i]];
                  }

                  return convertedObj;
                })
              }
            />
          </Col> */}
          <Col xs={4} lg={3}>
            <span>Category</span>
            <Select
              showSearch
              style={{
                width: "100%",
              }}
              placeholder="Category"
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              filterSort={(optionA, optionB) =>
                (optionA?.label ?? "")
                  .toLowerCase()
                  .localeCompare((optionB?.label ?? "").toLowerCase())
              }
              allowClear
              onChange={(e) => {
                let value = e;
                let obj = filterObj;
                if (value) {
                  obj.category = value;
                  obj.page = 1;
                  setCurrentPage(1);
                } else delete obj.category;
                setFilterObj({ ...obj });
              }}
              options={
                categoryType &&
                categoryType?.map((obj) => {
                  const keys = Object.keys(obj);
                  const convertedObj = {
                    value: obj["categoryId"],
                    label: obj["categoryName"],
                  };

                  for (let i = 2; i < keys.length; i++) {
                    convertedObj[keys[i]] = obj[keys[i]];
                  }

                  return convertedObj;
                })
              }
            />
          </Col>
          <Col xs={4} lg={3}>
            <span>Type</span>

            <Select
              showSearch
              style={{
                width: "100%",
              }}
              placeholder="Goal Type"
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              filterSort={(optionA, optionB) =>
                (optionA?.label ?? "")
                  .toLowerCase()
                  .localeCompare((optionB?.label ?? "").toLowerCase())
              }
              allowClear
              onChange={(e) => {
                let value = e;
                let obj = filterObj;
                if (value) {
                  obj.kpiType = value;
                  obj.page = 1;
                  setCurrentPage(1);
                } else delete obj.kpiType;
                setFilterObj({ ...obj });
              }}
              options={
                goalType &&
                goalType?.map((obj) => {
                  const keys = Object.keys(obj);
                  const convertedObj = {
                    value: obj["goalTypeId"],
                    label: obj["goalTypeName"],
                  };

                  for (let i = 2; i < keys.length; i++) {
                    convertedObj[keys[i]] = obj[keys[i]];
                  }

                  return convertedObj;
                })
              }
            />
          </Col>
          {/* <Col xs={4} lg={3}>
            <span>User</span>
            <Select
              showSearch
              style={{
                width: "100%",
              }}
              placeholder="User"
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              filterSort={(optionA, optionB) =>
                (optionA?.label ?? "")
                  .toLowerCase()
                  .localeCompare((optionB?.label ?? "").toLowerCase())
              }
              allowClear
              onChange={(e) => {
                let value = e;
                let obj = filterObj;
                if (value) {
                  obj.userFilter = value;
                  obj.page = 1;
                  setCurrentPage(1);
                } else delete obj.userFilter;
                setFilterObj({ ...obj });
              }}
              options={
                userType &&
                userType?.map((obj) => {
                  const keys = Object.keys(obj);
                  const convertedObj = {
                    value: obj["userId"],
                    label: `${obj["firstName"]} ${obj["lastName"]}`,
                  };

                  for (let i = 2; i < keys.length; i++) {
                    convertedObj[keys[i]] = obj[keys[i]];
                  }

                  return convertedObj;
                })
              }
            />
          </Col> */}
          {/* <Col xs={4} lg={3}>
            <span>Department</span>
            <Select
              showSearch
              style={{
                width: "100%",
              }}
              placeholder="Department"
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              filterSort={(optionA, optionB) =>
                (optionA?.label ?? "")
                  .toLowerCase()
                  .localeCompare((optionB?.label ?? "").toLowerCase())
              }
              allowClear
              onChange={(e) => {
                let value = e;
                let obj = filterObj;
                if (value) {
                  obj.departmentFilter = value;
                  obj.page = 1;
                  setCurrentPage(1);
                } else delete obj.departmentFilter;
                setFilterObj({ ...obj });
              }}
              options={
                department &&
                department?.map((obj) => {
                  const keys = Object.keys(obj);
                  const convertedObj = {
                    value: obj["departmentId"],
                    label: obj["departmentName"],
                  };

                  for (let i = 2; i < keys.length; i++) {
                    convertedObj[keys[i]] = obj[keys[i]];
                  }

                  return convertedObj;
                })
              }
            />
          </Col> */}
          {/* <Col xs={24} lg={4}>
            <div className={styles.btnContainer}>
              <Button className={styles.btn} onClick={() => saveExcel()}>
                Save as Excel
              </Button>
              <Button
                className={`${styles.btn} ${styles.orange}`}
                onClick={() => savePdf()}
              >
                Save as PDF
              </Button>
            </div>
          </Col> */}
        </Row>
      </div>
      <div className={styles.mainData}>
        <div className={styles.container}>
          <p className={styles.goalHeader}>PIP</p>
          {data.map((v, i) => (
            <div
              className={`${styles.boxGoal} ${
                selectedGoal == v.goalId ? styles.selected : ""
              }`}
              onClick={() => selectedGoals(v)}
            >
              {v.goalName}
            </div>
          ))}
        </div>
        <Table
          className={styles.table_striped_rows}
          columns={columns}
          pagination={false}
          expandable={{
            expandedRowRender,
            expandedRowKeys: [expandedRowKey],
            onExpand: (_, record) => handleRowClick(record),
            expandIcon: ({ expanded, onExpand, record }) => (
              <span onClick={() => handleRowClick(record)}>
                {expanded ? "-" : "+"}
              </span>
            ),
          }}
          dataSource={kpiData.map((item, index) => ({
            ...item,
            key: index,
          }))}
          // className={styles.customTable}
        />

        <div style={{ textAlign: "right", margin: "10px" }}>
          <Pagination {...paginationConfig} showSizeChanger={false} />
        </div>
      </div>
    </div>
  )
}

export default PIPReports
