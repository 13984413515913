import { Button, Col, Row, Form, Input, Switch } from "antd";
import React, { useContext, useState } from "react";
import { alertMessage } from "../../../../Utils/helperFunction";
import { api_call_token } from "../../../../Utils/Network";
import { UserCredsContext } from "../../../../Utils/UserCredsContext";

function GoalStatusForm({
  field,
  key,
  getCategoryList = () => {},
  setOpen = () => {},
}) {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const { logout } = useContext(UserCredsContext);

  const handleData = (vals) => {
    console.log(vals, field);
    setIsLoading(true);
    if (field?.goalStatusId) {
      api_call_token
        .patch(`goalstatus/${field?.goalStatusId}`, {
          ...vals,
          active: vals?.active ? 1 : 0,
        })
        .then((res) => {
          if (res.status == 201 || res.status == 200 || res.status == 204) {
            getCategoryList();
            form.resetFields();
            setIsLoading(false);
            setOpen(false);
          } else if (res.status == 401) {
            logout();
          } else if (res.status == 403) {
            alertMessage("Updating a Goal Status is restricted for users.", 2);
            setOpen(false);
          }
        })
        .catch((err) => {
          setIsLoading(false);
          console.log(err);
        });
    } else {
      api_call_token
        .post(`goalstatus`, {
          ...vals,
          active: vals?.active ? 1 : 0,
          goalStatusDarkColour: "#000000",
          goalStatusLightColour: "#FFFFFF",
        })
        .then((res) => {
          if (res.status == 201 || res.status == 200 || res.status == 204) {
            getCategoryList();
            form.resetFields();
            setIsLoading(false);
            setOpen(false);
          } else if (res.status == 401) {
            logout();
          } else if (res.status == 403) {
            alertMessage("Updating a Goal Status is restricted for users.", 2);
            setOpen(false);
          }
        })
        .catch((err) => {
          console.log(err);
          setIsLoading(false);
        });
    }
  };
  return (
    <div>
      <h1>Goal Status</h1>
      <Form form={form} initialValues={field} key={key} onFinish={handleData}>
        <Row>
          <Col xs={24} md={24} lg={24}>
            <span>Goal Status Name</span>
            <Form.Item name="goalStatusName">
              <Input placeholder="Goal Status Name" />
            </Form.Item>
          </Col>
          <Col xs={24} md={24} lg={24}>
            <span>Goal Status Order</span>
            <Form.Item name="goalStatusOrder">
              <Input placeholder="Goal Status Order" type="number" />
            </Form.Item>
          </Col>
          <Col xs={24} md={24} lg={24}>
            <span>Active Status</span>
            {field?.active == "true" ? (
              <Form.Item name="active">
                <Switch
                  checkedChildren="Yes"
                  unCheckedChildren="No"
                  defaultChecked
                />
              </Form.Item>
            ) : (
              <Form.Item name="active">
                <Switch checkedChildren="Yes" unCheckedChildren="No" />
              </Form.Item>
            )}
          </Col>
          <Col>
            <Button type="primary" htmlType="submit" loading={isLoading}>
              Submit
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
}

export default GoalStatusForm;
