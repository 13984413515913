import logo from "./logo.svg";
import "./App.css";
import Mainrouters from "./Approuter/Mainrouters";
import UserCredsContextProvider, {
  UserCredsContext,
} from "./Utils/UserCredsContext";
import { getLocalData } from "./Utils/helperFunction";
import Login from "./Auth/Login/Login";
import { useEffect } from "react";
import Mainrouter from "./Approuter/Mainrouter";

const token = getLocalData("dmc_user");

function App() {
  useEffect(() => {
    
  }, [token])
  return (
    <div className="App">
      {/* {token ? ( */}
        <UserCredsContextProvider>
          {/* <Mainrouters token={token} /> */}
          <Mainrouter />
        </UserCredsContextProvider>
      {/* // ) : (
      //   <Login />
      // )} */}
    </div>
  );
}

export default App;
