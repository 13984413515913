import { Button, Col, Row, Form, Input, Select, Switch } from "antd";
import React, { useState } from "react";
import { alertMessage } from "../../../../Utils/helperFunction";
import { api_call_token } from "../../../../Utils/Network";

function DepartmentForm({
  field,
  key,
  getCategoryList = () => {},
  data,
  setOpen = () => {},
}) {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const handleData = (vals) => {
    console.log(vals);
    setIsLoading(true);
    if (field?.departmentId) {
      api_call_token
        .patch(`department/${field?.departmentId}`, {
          ...vals,
          active: vals?.active ? 1 : 0,
        })
        .then((res) => {
          if (res.status == 201 || res.status == 200 || res.status == 204) {
            getCategoryList();
            form.resetFields();
            setIsLoading(false);
            setOpen(false);
          } else if (res.status == 403) {
            alertMessage("Updating a Department is restricted for users.", 2);
            setOpen(false);
          }
        })
        .catch((err) => console.log(err));
    } else {
      api_call_token
        .post(`department`, { ...vals, active: vals?.active ? 1 : 0 })
        .then((res) => {
          if (res.status == 201 || res.status == 200 || res.status == 204) {
            getCategoryList();
            form.resetFields();
            setIsLoading(false);
            setOpen(false);
          } else if (res.status == 403) {
            alertMessage("Creating a Department is restricted for users.", 2);
            setOpen(false);
          }
        })
        .catch((err) => {
          setIsLoading(false);
          console.log(err);
        });
    }
  };

  return (
    <div>
      <Form
        form={form}
        initialValues={{
          ...field,
          departmentParent: +field?.departmentParent || null,
        }}
        key={key}
        onFinish={handleData}
      >
        <Row>
          <Col xs={24} md={24} lg={24}>
            <span>Department Name</span>
            <Form.Item name="departmentName">
              <Input placeholder="Department Name" />
            </Form.Item>
          </Col>
          <Col xs={24} md={24} lg={24}>
            <span>Department Details</span>
            <Form.Item name="departmentDetail">
              <Input placeholder="Department Details" />
            </Form.Item>
          </Col>
          <Col xs={24} md={24} lg={24}>
            <span>Department Head</span>
            <Form.Item name="departmentHeadName">
              <Input placeholder="Department Head" />
            </Form.Item>
          </Col>
          <Col xs={24} md={24} lg={24}>
            <span>Department Parent</span>
            <Form.Item name="departmentParent">
              <Select
                showSearch
                style={{
                  width: "100%",
                }}
                placeholder="Department Parent"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? "")
                    .toLowerCase()
                    .localeCompare((optionB?.label ?? "").toLowerCase())
                }
                allowClear
                options={
                  data &&
                  data?.map((obj) => {
                    const keys = Object.keys(obj);
                    const convertedObj = {
                      value: obj["departmentId"],
                      label: `${obj["departmentName"]}-${obj["departmentParentName"]}`,
                    };

                    for (let i = 2; i < keys.length; i++) {
                      convertedObj[keys[i]] = obj[keys[i]];
                    }

                    return convertedObj;
                  })
                }
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={24} lg={24}>
            <span>No. of Employee</span>
            <Form.Item name="noOfEmployee">
              <Input placeholder="No of Employee" type="number" />
            </Form.Item>
          </Col>
          <Col xs={24} md={24} lg={24}>
            <span>Active Status</span>
            {field?.active ? (
              <Form.Item name="active">
                <Switch
                  checkedChildren="Yes"
                  unCheckedChildren="No"
                  defaultChecked
                />
              </Form.Item>
            ) : (
              <Form.Item name="active">
                <Switch checkedChildren="Yes" unCheckedChildren="No" />
              </Form.Item>
            )}
          </Col>
          <Col>
            <Button type="primary" htmlType="submit" loading={isLoading}>
              Submit
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
}

export default DepartmentForm;
