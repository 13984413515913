import React, { useContext, useEffect, useState } from "react";
import { Input, Pagination, Table } from "antd";
import { api_call_token } from "../../../Utils/Network";
import { UserCredsContext } from "../../../Utils/UserCredsContext";
import moment from "moment";
import EditIcon from "../../../Assets/ICONS/pen_solid.png";
import { useNavigate } from "react-router-dom";
import styles from './index.module.css';
import { SearchOutlined } from "@ant-design/icons";

const GoalList = () => {
  const [data, setData] = useState([]);
  const [expandedRowKey, setExpandedRowKey] = useState(null);
  const [filterObj, setFilterObj] = useState({
    page: 1,
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [totalNumberofPage, setTotalNumberofPage] = useState(null);

  let navigate = useNavigate();
  let { logout } = useContext(UserCredsContext);

  const expandedRowRender = (record) => {
    const columns = [
      { title: "KPI Name", dataIndex: "kpiName", key: "kpiName" },
      { title: "Due Date", dataIndex: "kpiDueDate", key: "kpiDueDate", render: (_) => <div>{moment(_).format("DD-MMMM-YYYY")}</div> },
      {
        title: "KPI Description",
        dataIndex: "kpiDescription",
        key: "kpiDescription",
      },
      { title: "KPI Start Date", dataIndex: "kpiStartDate", key: "kpiStartDate", render: (_) => <div>{moment(_).format("DD-MMMM-YYYY")}</div>, },
      { title: "KPI Target", dataIndex: "kpiTarget", key: "kpiTarget", render: (_) => <div>{moment(_).format("DD-MMMM-YYYY")}</div>, },
      {
        title: "KPI Target Achievement",
        dataIndex: "kpiTargetAchievement",
        key: "kpiTargetAchievement",
      },
      {
        title: "KPI Target Achievement Value",
        dataIndex: "kpiTargetAchievmentValue",
        key: "kpiTargetAchievmentValue",
        render: (_) => {
          const num = _;
          const roundedNum = parseFloat(num.toFixed(2));
          return (
            <>
              {roundedNum}
            </>
          )
        }
      },
      { title: "KPI Type", dataIndex: "goalTypeName", key: "goalTypeName" },
    ];

    const data = record.goalKpiList.map((kpi) => ({
      key: kpi.kpiId,
      kpiName: kpi.kpiName,
      kpiDueDate: kpi.kpiDueDate,
      kpiDescription: kpi.kpiDescription,
      kpiTarget: kpi.kpiTarget,
      kpiTargetAchievement: kpi.kpiTargetAchievement,
      kpiTargetAchievmentValue: kpi.kpiTargetAchievmentValue,
      goalTypeName: kpi.goalTypeName,
      // Map other properties accordingly
    }));

    return <Table columns={columns} dataSource={data} pagination={false} />;
  };

  useEffect(() => {
    getGoals();
  }, [filterObj]);

  const redirectTo = (para) => {
    console.log(para);
    navigate(`creategoal`, { state: para });
  };

  const getGoals = (paramObj = { ...filterObj }) => {
    api_call_token
      .get(`goal/foruser`, { params: { ...paramObj } })
      .then((res) => {
        if (res.status === 200 || res.status === 201 || res.status === 204) {
          setData(res.data.data);
          setCurrentPage(res.data.currentPage);
          setTotalNumberofPage(res.data.totalRowCount);
        } else if (res.status === 401) {
          logout();
        }
      })
      .catch((err) => console.log(err));
  };

  const handlePageChange = (page) => {
    console.log(page, "aaaaaaaaa");
    setFilterObj({ ...filterObj, page: page });
    setCurrentPage(page);
  };

  const handleRowClick = (record) => {
    setExpandedRowKey((prevKey) =>
      prevKey === record.key ? null : record.key
    );
  };

  const columns = [
    { title: "Id", dataIndex: "goalId", key: "goalId" },
    { title: "Goal Unique Id", dataIndex: "goalUniqueId", key: "goalUniqueId" },
    { title: "Name", dataIndex: "goalName", key: "goalName" },
    { title: "Goal Category", dataIndex: "goalCategory", key: "goalCategory" },
    { title: "Goal Type", dataIndex: "goalType", key: "goalType" },
    { title: "Goal Progress", dataIndex: "goalProgress", key: "goalProgress", render: (_) => <div className={`${(_ > 100) ? styles.darkOrange : (_ > 60) ? styles?.category : styles?.orange} `}><div style={{ width: `${(_ > 100) ? 100 : _}%`, background: `${(_ > 100) ? '#b16a00' : (_ > 60) ? `#13CE66` : "#FD8902"}`, height: '100%' }}></div></div> },
    {
      title: "Start Date",
      dataIndex: "goalStartDate",
      key: "goalStartDate",
      render: (_) => <div>{moment(_).format("DD-MMMM-YYYY")}</div>,
    },
    {
      title: "End Date",
      dataIndex: "goalEndDate",
      key: "goalEndDate",
      render: (_) => <div>{moment(_).format("DD-MMMM-YYYY")}</div>,
    },
    // {
    //   title: "Assigned User",
    //   dataIndex: "userList",
    //   key: "userList",
    //   render: (_) => <div>{(_ && _.length > 0) ? _.map((vals,i) => <div style={{border: '1px solid grey',fontSize: '12px', padding: '3px 7px',borderRadius: '5px' ,marginBottom: '5px'}}>{vals.fullName}</div>) : ""}</div>,
    // },
    {
      title: "Action",
      dataIndex: "",
      render: (_, record) => {
        const handleViewClick = () => {
          redirectTo(record);
        };
        return (
          <div>
            <div
              onClick={() => handleViewClick()}
              style={{ width: "max-content", cursor: "pointer" }}
            >
              <img src={EditIcon} alt="consignment" />
            </div>
          </div>
        );
      },
    },
  ];

  const paginationConfig = {
    current: currentPage,
    pageSize: 10, // Number of items per page
    total: totalNumberofPage, // Total number of items
    onChange: handlePageChange,
  };

  console.log(expandedRowKey, "expandedRowKeyexpandedRowKey");
  const onSearch = (e) => {
    let value = e.target.value;
    let obj = filterObj;
    if (value) { obj.search = value;obj.page = 1; setCurrentPage(1) }
    else delete obj.search;
    setFilterObj({ ...obj });
  }

  return (
    <div className={styles.tableData}>
      <div className={styles.inputData}>
        <Input
          placeholder="Search by Goal, Goal Category"
          prefix={<SearchOutlined />}
          style={{ width: "100%" }}
          onChange={(e) => onSearch(e)}
        />
      </div>
      <Table
        columns={columns}
        pagination={false}
        expandable={{
          expandedRowRender,
          expandedRowKeys: [expandedRowKey],
          onExpand: (_, record) => handleRowClick(record),
          expandIcon: ({ expanded, onExpand, record }) => (
            <span onClick={() => handleRowClick(record)}>
              {expanded ? "-" : "+"}
            </span>
          ),
        }}
        dataSource={data.map((item, index) => ({
          ...item,
          key: index,
        }))}
      />

      <div style={{ textAlign: "right", margin: "10px" }}>
        <Pagination {...paginationConfig} showSizeChanger={false}/>
      </div>
    </div>
  );
};

export default GoalList;
