import React, { useContext, useEffect, useState } from "react";
import styles from "./index.module.css";
import BgImage from "../../Assets/Login/rect.png";
import { Form, Input, Button } from "antd";
import OTPInput, { ResendOTP } from "otp-input-react";
import LockScreen from "./LockScreen";
import { api_call } from "../../Utils/Network";
import { UserCredsContext } from "../../Utils/UserCredsContext";
import { getLocalData, saveLocalData } from "../../Utils/helperFunction";
import ErrorImage from "../../Assets/Login/error.png";
import { useNavigate } from "react-router-dom";

function Login() {
  const { setUsers, setMenuState, setStatus, setToken } =
    useContext(UserCredsContext);
  const [loginstate, setLoginState] = useState(1);
  const [lockstate, setLockState] = useState(1);
  const [userState, setUser] = useState({});
  const [usernameError, setUserNameError] = useState(false);
  const [OTP, setOTP] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const [otpError, setOTPError] = useState(false);
  const [countAttempt, setCountAttempts] = useState(0);
  const [messages, lockMessage] = useState("");
  let navigate = useNavigate();

  useEffect(() => {
    let auth = getLocalData('dmc_user');
    if(auth?.token){
      navigate('/dashboard')
    }
  }, [])

  const onFinish = (value) => {
    setIsLoading(true);
    api_call
      .post(`loginotp`, value)
      .then((res) => {
        console.log(res, "datas");
        if (res.status == 200 || res.status == 201 || res.status == 204) {
          setLoginState(2);
          setUser(value);
          setIsLoading(false);
        } else {
          setIsLoading(false);
          setUserNameError(true);
          if (res?.data?.message) {
            lockMessage(res.data.message);
          }
        }
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
        setUserNameError(true);
        if (err?.data?.message) {
          lockMessage(err.data.message);
        } else {
          lockMessage("Something went wrong please check email and password");
        }
      });
  };

  const handleOtp = (e) => {
    console.log(e);
    setOTP(e);
    setOTPError(false);
  };

  const onOtpSubmit = () => {
    console.log(OTP, "OTP Data");
    // setLockState(2)
    let data = {
      ...userState,
      otp: OTP,
    };

    api_call
      .post(`mylogin`, data)
      .then((res) => {
        console.log(res, "RES DATA");

        if (res.status == 200 || res.status == 201 || res.status == 204) {
          if (res.data.data.token) {
            saveLocalData("dmc_user", res.data.data);
            const { user_state, token, menu_state, statusMessage } =
              res.data.data;
            // setUserState({user_state, token, menu_state, statusMessage})
            setUsers(user_state);
            setMenuState(menu_state);
            setStatus(statusMessage);
            setToken(token);
            
            // setTimeout(() => {
            //   window.location.reload();
            // }, 100);
            navigate("/dashboard");
          } else {
            if (res.data.userCounter > 4) {
              setLockState(2);
            } else {
              setOTPError(true);
              setCountAttempts(res.data.userCounter);
            }
          }
        } else {
          if (res.data.userCounter > 4) {
            setLockState(2);
          } else {
            setOTPError(true);
            setCountAttempts(res.data.userCounter);
          }
        }
      })
      .catch((err) => {
        setOTPError(true);
      });
  };

  const handleClick = () => {
    navigate('/forgot')
  }

  return (
    <div>
      {lockstate == 1 ? (
        <div className={styles.mainLogin}>
          <div className={`${styles.box} ${styles.box1}`}>
            <img src={BgImage} alt="bgimage" className={styles.imgs} />
          </div>
          <div className={`${styles.box} `}>
            {loginstate == 1 ? (
              <div className={`${styles.boxPadding}`}>
                <h1 className={styles.textLogin}>LOGIN</h1>
                <Form onFinish={onFinish} autoComplete="off">
                  <Form.Item
                    name="username"
                    rules={[
                      {
                        required: true,
                        message: "Required",
                      },
                    ]}
                  >
                    <Input
                      placeholder="Email ID"
                      onChange={() => setUserNameError(false)}
                    />
                  </Form.Item>

                  <Form.Item
                    name="password"
                    rules={[
                      {
                        required: true,
                        message: "Required",
                      },
                    ]}
                  >
                    <Input.Password
                      placeholder="Password"
                      onChange={() => setUserNameError(false)}
                    />
                  </Form.Item>

                  <Form.Item>
                    <Button
                      type="primary"
                      htmlType="submit"
                      className={styles.btn}
                      loading={isLoading}
                    >
                      Send OTP
                    </Button>
                  </Form.Item>
                </Form>
                <div style={{color: 'blue', cursor: 'pointer'}} onClick={() => handleClick()}>Forgot Password ?</div>

                <img
                  src={BgImage}
                  alt="bgimage"
                  className={styles.mobileImage}
                />

                {usernameError && (
                  <div className={styles.errorBlock}>
                    <img
                      src={ErrorImage}
                      alt="image"
                      className={styles.images}
                    />
                    <p className={styles.para}>{messages}</p>

                    <div className={styles.triangle}></div>
                  </div>
                )}
              </div>
            ) : (
              <div className={`${styles.boxPad}`}>
                <h1 className={styles.otpHead}>OTP Sent!</h1>
                <p className={styles.otpText}>
                  An OTP is sent to your registered email. Please enter the OTP
                  to Login into your account
                </p>
                {otpError && (
                  <p className={styles.attempts}>
                    {countAttempt} out of 5 attempts
                  </p>
                )}
                <div className={styles.otpBoxes}>
                  <OTPInput
                    style={{ justifyContent: "center" }}
                    value={OTP}
                    onChange={handleOtp}
                    autoFocus
                    OTPLength={5}
                    otpType="number"
                    disabled={false}
                    styles={{ justifyContent: "center" }}
                  />
                </div>
                <img
                  src={BgImage}
                  alt="bgimage"
                  className={styles.mobileImage}
                />

                {/* <ResendOTP onResendClick={() => console.log("Resend clicked")} /> */}
                <Button
                  type="primary"
                  htmlType="submit"
                  onClick={onOtpSubmit}
                  className={styles.btn}
                >
                  LOGIN
                </Button>

                {otpError && (
                  <div className={styles.errorBlock}>
                    <img
                      src={ErrorImage}
                      alt="image"
                      className={styles.images}
                    />
                    <p className={styles.para}>Incorrect OTP</p>

                    <div className={styles.triangle}></div>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      ) : (
        <LockScreen setLoginState={setLoginState} setLockState={setLockState} />
      )}
    </div>
  );
}

export default Login;
