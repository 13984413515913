import React, { useEffect, useState } from "react";
import { PieChart, Pie, Cell } from "recharts";
import { api_call_token } from "../../../Utils/Network";

const Donut = ({data}) => {
  const [activeIndex, setActiveIndex] = useState(null);
  const screenWidth = window.innerWidth;


  // const handleMouseEnter = (event, index) => {
  //   debugger;
  //   event.style.transform = 'scale(1.1)';
  // };

  // const handleMouseLeave = (event, index) => {
  //   event.style.transform = 'scale(1)';
  // };


  console.log(screenWidth, "sssss")
  return (
    <PieChart width={(screenWidth > 1300) ? 450 : 360} height={(screenWidth > 1300) ? 400 : 350}>
      <Pie
        data={data}
        cx={220}
        cy={200}
        innerRadius={70}
        outerRadius={130}
        tooltipType
        fill="#8884d8"
        paddingAngle={5}
        dataKey="value"
      >
        {data.map((entry, index) => (
          <Cell key={`cell-${index}`} fill={entry.color}
          // onMouseEnter={(event) => handleMouseEnter(event, index)}
          // onMouseLeave={(event) => handleMouseLeave(event, index)}
          style={{ transition: 'transform 0.3s' }} />
        ))}
      </Pie>
    </PieChart>
  );
}

export default Donut;
