import {
  ArrowLeftOutlined,
  DeleteFilled,
  EditFilled,
  SearchOutlined,
} from "@ant-design/icons";
import { Button, Input, Select, Form, Table, Space, Pagination } from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./index.module.css";
import { api_call_token } from "../../../../Utils/Network";
import { isObject } from "../../../../Utils/helperFunction";
import Modals from "../../../../Component/Modals/Modals";
import DepartmentForm from "./DepartmentForm";

function DepartmentList() {
  let navigate = useNavigate();
  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);
  const [field, setFields] = useState(null);
  const [vals, setVals] = useState(null);
  const [filterObj, setFilterObj] = useState({
    page: 1,
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [totalNumberofPage, setTotalNumberofPage] = useState(null);
  const [fullData, setFullData] = useState({});
  const [form] = Form.useForm();

  useEffect(() => {
    getListingDept();
  }, [filterObj]);

  const getListingDept = (paramObj = { ...filterObj }) => {
    api_call_token
      .get(`department`, { params: { ...paramObj } })
      .then((res) => {
        setData(res.data.data);
        setCurrentPage(res.data.currentPage);
        setTotalNumberofPage(res.data.totalRowCount);
        setFullData(res.data);
      })
      .catch((err) => console.log(err));
  };

  const handleClick = () => {
    navigate("/master");
  };

  const onSearch = (e) => {
    let value = e.target.value;
    let obj = filterObj;
    if (value) { obj.search = value; setCurrentPage(1) }
    else delete obj.search;
    setFilterObj({ ...obj, page: 1 });
  }

  const handleform = () => {
    setFields({});
    setOpen(false);
    setVals(0);
  };

  const openModel = () => {
    setFields({ active: 1 });
    setVals(1);
    setOpen(true);
  };

  const handleOpenModal = (record) => {
    if (record !== undefined && record !== null && isObject(record)) {
      setFields(record);
    }
    setVals(1);
    setOpen(true);
  };
  const handlePageChange = (page) => {
    console.log(page, "aaaaaaaaa");
    setFilterObj({ ...filterObj, page: page });
    setCurrentPage(page);
  };

  const paginationConfig = {
    current: currentPage,
    pageSize: 10, // Number of items per page
    total: totalNumberofPage, // Total number of items
    onChange: handlePageChange,
  };

  const columns = [
    {
      title: "Dept ID",
      dataIndex: "departmentId",
      key: "departmentId",
      flex: 1,
    },
    {
      title: "Dept Name",
      dataIndex: "departmentName",
      key: "departmentName",
      flex: 1,
    },
    // {
    //   title: "Created By",
    //   dataIndex: "createdByName",
    //   key: "createdByName",
    //   flex: 1,
    // },
    {
      title: "Parent",
      key: "departmentParentName",
      dataIndex: "departmentParentName",
      flex: 1,
    },
    {
      title: "No. of Employees",
      key: "noOfEmployee",
      dataIndex: "noOfEmployee",
      flex: 1,
    },
    // {
    //   title: "Dept. Head Name",
    //   key: "departmentHeadName",
    //   dataIndex: "departmentHeadName",
    //   flex: 1,
    // },
    {
      title: "Status",
      key: "active",
      dataIndex: "active",
      flex: 1,
      render: (_) => {
        return (
          <div
            className={`${styles.container} ${_ ? styles.green : styles.orange
              }`}
          >
            {(_) ? "Active" : "Inactive"}
          </div>
        );
      },
    },
    {
      title: "Action",
      key: "action",
      flex: 1,
      render: (_, record) => (
        <Space size="middle">
          <DeleteFilled />
          <EditFilled onClick={() => handleOpenModal(record)} />
        </Space>
      ),
    },
  ];

  return (
    <div className={styles.boxKPI}>
      <div className={styles.headerData}>
        <div className={styles.kpiText} style={{ display: 'flex' }}>
          <ArrowLeftOutlined
            style={{ marginRight: "20px" }}
            onClick={handleClick}
          />
          <p>Department Master</p>
        </div>
        <div>
          <Button type="primary" className={styles.btn} onClick={openModel}>
            + NEW DEPARTMENT
          </Button>
        </div>
      </div>
      <div className={styles.midContainer}>
        <div className={styles.inputData}>
          <Input
            placeholder="Search by Department Name"
            onChange={(e) => onSearch(e)}
            prefix={<SearchOutlined />}
            style={{ width: "100%" }}
          />

        </div>
        <div className={styles.containerRight}>
          <div className={`${styles.boxDatas} ${styles.blueBorder}`}>
            <p>Total KPI</p>
            <p>{fullData?.totalRowCount}</p>
          </div>

          <div className={`${styles.boxDatas} ${styles.greenBorder}`}>
            <p style={{ color: '#2EB67D' }}>Active</p>
            <p>{fullData?.activeTotal}</p>
          </div>
          <div className={`${styles.boxDatas} ${styles.redBorder}`}>
            <p style={{ color: '#DB4437' }}>Inactive</p>
            <p>{fullData?.inActive}</p>
          </div>
        </div>
      </div>
      <div className={styles.tableData}>
        <Table columns={columns} dataSource={data} pagination={false} />
        <div style={{ textAlign: "right", margin: "10px" }}>
          <Pagination {...paginationConfig} showSizeChanger={false}/>

        </div>
      </div>

      {open && (
        <Modals open={open} handleCancel={() => handleform()}>
          {vals == 1 && (
            <DepartmentForm
              field={field}
              key={1}
              form={form}
              data={data}
              getCategoryList={getListingDept}
              setOpen={setOpen}
            />
          )}
        </Modals>
      )}
    </div>
  );
}

export default DepartmentList;

const datas = [
  {
    deptId: 101,
    departmentName: "Accounts",
    departmentParent: 1, //1 --> Finance, 2 --> Sales, 3 --> Vehicle
    createdBy: "Akshat",
    noOfEmployee: "120",
    departmentHeadName: "David Long",
    status: 1, //1 --> Active, 2 --> Inactive, 3 --> Cancelled
  },
  {
    deptId: 102,
    departmentName: "Sales",
    departmentParent: 2, //1 --> Finance, 2 --> Sales, 3 --> Vehicle
    createdBy: "Akshat",
    noOfEmployee: "120",
    departmentHeadName: "David Long",
    status: 2, //1 --> Active, 2 --> Inactive, 3 --> Cancelled
  },
  {
    deptId: 103,
    departmentName: "Fuel",
    departmentParent: 1, //1 --> Finance, 2 --> Sales, 3 --> Vehicle
    createdBy: "Akshat",
    noOfEmployee: "10",
    departmentHeadName: "David Long",
    status: 1, //1 --> Active, 2 --> Inactive, 3 --> Cancelled
  },
  {
    deptId: 104,
    departmentName: "Accounts",
    departmentParent: 3, //1 --> Finance, 2 --> Sales, 3 --> Vehicle
    createdBy: "Akshat",
    noOfEmployee: "120",
    departmentHeadName: "David Long",
    status: 2, //1 --> Active, 2 --> Inactive, 3 --> Cancelled
  },
  {
    deptId: 105,
    departmentName: "Accounts",
    departmentParent: 1, //1 --> Finance, 2 --> Sales, 3 --> Vehicle
    createdBy: "Akshat",
    noOfEmployee: "120",
    departmentHeadName: "David Long",
    status: 1, //1 --> Active, 2 --> Inactive, 3 --> Cancelled
  },
  {
    deptId: 106,
    departmentName: "Sales",
    departmentParent: 2, //1 --> Finance, 2 --> Sales, 3 --> Vehicle
    createdBy: "Akshat",
    noOfEmployee: "120",
    departmentHeadName: "David Long",
    status: 1, //1 --> Active, 2 --> Inactive, 3 --> Cancelled
  },
  {
    deptId: 107,
    departmentName: "Fuel",
    departmentParent: 1, //1 --> Finance, 2 --> Sales, 3 --> Vehicle
    createdBy: "Akshat",
    noOfEmployee: "10",
    departmentHeadName: "David Long",
    status: 1, //1 --> Active, 2 --> Inactive, 3 --> Cancelled
  },
  {
    deptId: 108,
    departmentName: "Accounts",
    departmentParent: 3, //1 --> Finance, 2 --> Sales, 3 --> Vehicle
    createdBy: "Akshat",
    noOfEmployee: "120",
    departmentHeadName: "David Long",
    status: 1, //1 --> Active, 2 --> Inactive, 3 --> Cancelled
  },
];
