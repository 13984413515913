import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  message,
  Modal,
  Row,
  Select,
} from "antd";
import React, { useEffect, useState, useContext } from "react";
import styles from "./index.module.css";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useLocation, useNavigate } from "react-router-dom";
import {
  ArrowLeftOutlined,
  DeleteOutlined,
  EditOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import dayjs from "dayjs";
import { api_call_token } from "../../../../Utils/Network";
import { UserCredsContext } from "../../../../Utils/UserCredsContext";
import { alertMessage } from "../../../../Utils/helperFunction";
import Modals from "../../../../Component/Modals/Modals";
import Approve from "../../../../Assets/ICONS/Approved.png";
import TextArea from "antd/es/input/TextArea";
import EditKpi from "./EditKpi";
import moment from "moment";

function AdminGoalApprovalpip() {
  const today = dayjs();
  let location = useLocation();
  let navigate = useNavigate();
  const [stateAccept, setStateAccept] = useState(1);
  const [open, setOpen] = useState(false);
  const [richValue, setRichValue] = useState("");
  const [form] = Form.useForm();
  const [forms] = Form.useForm();
  const [kpiList, setKpiList] = useState([]);
  const [kpiListData, setKpiListData] = useState([]);
  const [uom, setUom] = useState([]);
  const [goalType, setGoalType] = useState([]);
  const [goalstatus, setGoalStatus] = useState([]);
  const [categoryType, setCategory] = useState([]);
  const [department, setDepartment] = useState([]);
  const [priority, setPriority] = useState([]);
  const [userData, setUserData] = useState([]);
  const [openEdit, setOpenEdit] = useState(false);
  const [goalData, setGoalData] = useState(location.state);
  const { userState, logout } = useContext(UserCredsContext);

  // const today = moment();
  useEffect(() => {
    getUom();
    getGoalType();
    getGoalStatus();
    getCategory();
    getDepartment();
    getPriority();
    getUserData();
    getkpi();
  }, []);

  // useEffect(() => {

  // }, [])

  useEffect(() => {
    // debugger;
    if (location?.state?.goalKpiList?.length > 0) {
      setKpiListData(location?.state?.goalKpiList);
    }

    if (location?.state?.goalDescription) {
      setRichValue(location?.state?.goalDescription);
    }
  }, [location]);

  const getkpi = () => {
    api_call_token
      .get(`kpimaster?size=300`)
      .then((res) => {
        if (res.status == 200 || res.status == 201 || res.status == 204) {
          setKpiList(res.data.data);
        } else if (res.status == 401) {
          logout();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getPriority = () => {
    api_call_token
      .get(`priority`)
      .then((res) => {
        setPriority(res.data.data);
      })
      .catch((err) => console.log(err));
  };

  const getDepartment = () => {
    api_call_token
      .get(`department?size=300`)
      .then((res) => {
        setDepartment(res.data.data);
      })
      .catch((err) => console.log(err));
  };

  const getCategory = () => {
    api_call_token
      .get(`category`)
      .then((res) => {
        setCategory(res.data.data);
      })
      .catch((err) => console.log(err));
  };

  const getGoalType = () => {
    api_call_token
      .get(`goaltype`)
      .then((res) => {
        setGoalType(res.data.data);
      })
      .catch((err) => console.log(err));
  };

  const getGoalStatus = () => {
    api_call_token
      .get(`goalstatus`)
      .then((res) => {
        setGoalStatus(res.data.data);
      })
      .catch((err) => console.log(err));
  };

  const getUom = () => {
    api_call_token
      .get(`uom`)
      .then((res) => {
        setUom(res.data.data);
      })
      .catch((err) => console.log(err));
  };

  const getUserData = () => {
    api_call_token
      .get(`createuser/alluser?size=300`)
      .then((res) => {
        setUserData(res.data.data);
      })
      .catch((err) => console.log(err));
  };

  const handleData = (e) => {
    for (const key in e) {
      if (e.hasOwnProperty(key)) {
        const value = e[key];
        if (key === "goalKpiList" && Array.isArray(value)) {
          // If the key is 'goalKpiList' and the value is an array
          e[key] = value.map((kpi) => {
            debugger;
            // Map over each item in the 'goalKpiList' array
            const formattedKpi = { ...kpi };
            for (const kpiKey in formattedKpi) {
              if (formattedKpi.hasOwnProperty(kpiKey)) {
                const kpiValue = formattedKpi[kpiKey];
                if (
                  typeof kpiValue === "object" &&
                  (kpiValue.$d instanceof Date || kpiValue._d instanceof Date)
                ) {
                  formattedKpi[kpiKey] = dayjs(
                    kpiValue.$d ? kpiValue.$d : kpiValue._d
                  ).format("YYYY-MM-DD");
                }
              }
            }
            return formattedKpi;
          });
        } else if (
          typeof value === "object" &&
          (value.$d instanceof Date || value._d instanceof Date)
        ) {
          if (value.$d) {
            e[key] = dayjs(value.$d).format("YYYY-MM-DD");
          } else {
            e[key] = dayjs(value._d).format("YYYY-MM-DD");
          }
        } else if (typeof value === "string" && !isNaN(value)) {
          e[key] = Number(value);
        }
      }
    }

    console.log(kpiListData, "KPILIST");

    let kpiList = [];
    kpiListData.map((v, i) => {
      let userIds = [];
      let {
        kpiCategory,
        kpiDescription,
        kpiDueDate,
        kpiId,
        kpiLevel,
        kpiMasterId,
        kpiName,
        kpiStartDate,
        kpiTarget,
        kpiTargetAchievement,
        kpiType,
        kpiWeightage,
        priorityId,
        uomId,
        ...rest
      } = v;
      let data = {
        kpiCategory,
        kpiDescription,
        kpiDueDate: moment(kpiDueDate).format("YYYY-MM-DD"),
        kpiId,
        kpiLevel,
        kpiMasterId,
        kpiName,
        kpiStartDate: moment(kpiStartDate).format("YYYY-MM-DD"),
        kpiTarget,
        kpiTargetAchievement,
        kpiType,
        kpiWeightage,
        priorityId,
        uomId,
      };
      if (v.userList) {
        v.userList.map((x, i) => {
          userIds.push(x.userId);
        });
      } else if (v.userIds) {
        userIds = v.userIds;
      }
      data.userIds = userIds;

      kpiList.push(data);
    });
    console.log(kpiList, "KPILIST");

    let submitData = {
      ...e, 
      goalUniqueId: location?.state?.goalUniqueId,
      goalKpiList: kpiList,
    };

    api_call_token
      .patch(`goalpip/${location?.state?.goalId}`, submitData)
      .then((res) => {
        if (res.status == 201 || res.status == 200 || res.status == 204) {
          approve();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const approve = () => {
    setOpen(true);
    let data = {
      goalId: location?.state?.goalId,
      goalUniqueId: location?.state?.goalUniqueId,
      goalApprovedOn: dayjs(today).format("YYYY-MM-DD"),
      goalApprovedStatus: 1,
    };
    if (location?.state?.goalApprovedStatus) {
      api_call_token
        .patch(`goalpip/approvalpip/${location?.state?.goalApprovedId}`, data)
        .then((res) => {
          if (res.status == 200 || res.status == 201 || res.status == 204) {
            setTimeout(() => {
              navigate("/adminpip");
            }, 3000);
          }
        })
        .catch((err) => {
          alertMessage("Looks Like Something went wrong", 2);
        });
    } else {
      api_call_token
        .post(`goalpip/pipapproval`, data)
        .then((res) => {
          if (res.status == 200 || res.status == 201 || res.status == 204) {
            alertMessage("Goal Approved ");
            setTimeout(() => {
              navigate("/adminpip");
            }, 3000);
          }
        })
        .catch((err) => {
          alertMessage("Looks Like Something went wrong", 2);
        });
    }
  };

  const handleClick = () => {
    navigate("/adminpip");
  };

  const handleReject = () => {
    setStateAccept(2);
    setOpen(true);
  };

  const SubmitDataRejection = (e) => {
    let data = {
      ...e,
      goalId: location?.state?.goalId,
      goalUniqueId: location?.state?.goalUniqueId,
      goalApprovedOn: dayjs(today).format("YYYY-MM-DD"),
      goalApprovedStatus: 2,
    };

    if (location?.state?.goalApprovedStatus) {
      api_call_token
        .patch(`goalpip/approvalpip/${location?.state?.goalApprovedId}`, data)
        .then((res) => {
          if (res.status == 200 || res.status == 201 || res.status == 204) {
            setOpen(false);
            alertMessage("Message Sent ");
            setTimeout(() => {
              navigate("/adminpip");
            }, 2000);
          }
        })
        .catch((err) => {
          alertMessage("Looks Like Something went wrong", 2);
        });
    } else {
      api_call_token
        .post(`goalpip/pipapproval`, data)
        .then((res) => {
          if (res.status == 200 || res.status == 201 || res.status == 204) {
            setOpen(false);
            alertMessage("Message Sent ");
            setTimeout(() => {
              navigate("/adminpip");
            }, 2000);
          }
        })
        .catch((err) => {
          alertMessage("Looks Like Something went wrong", 2);
        });
    }
  };

  const handleCreation = (val) => {
    console.log(val, kpiListData, "1232312");
    // let data = location?.state;
    // data.goalKpiList.push(val);
    // setKpiList(data.goalKpiList)
    // setGoalData(goalData)
    if (kpiListData.length > 0) {
      setKpiListData([...kpiListData, val]);
    } else {
      setKpiListData([val]);
    }
  };

  const handleopenmodal = (v, i) => {
    // debugger;
    console.log(v, i);
    let data = [...kpiListData];
    data.splice(i, 1);
    setKpiList(data);
  };

  const handleAddKpi = () => {
    let data = location.state;
    setOpenEdit(true);

    console.log(data, "DATAS");
  };
  //   console.log(kpiListData, "kpiListData");
  console.log(goalData, kpiListData, "LOCATIONsssss");
  return (
    <div className={styles.goals}>
      <div className={styles.header}>
        <ArrowLeftOutlined
          style={{ marginRight: "20px" }}
          onClick={handleClick}
        />
        <h1 className={styles.masters}>Approve PIP</h1>
      </div>

      <Form
        form={form}
        onFinish={handleData}
        initialValues={{
          ...location?.state,
          // departmentIds: location?.state?.departmentList?.map(
          //   (obj) => obj?.departmentId
          // ),
          // userIds: location?.state?.userList.map((obj) => obj?.userId),
          goalStartDate: location?.state?.goalStartDate
            ? dayjs(location?.state?.goalStartDate)
            : dayjs(today),
          goalEndDate: location?.state?.goalEndDate
            ? dayjs(location?.state?.goalEndDate)
            : dayjs(today),

          // goalKpiList: location?.state?.goalKpiList.map(
          //   ({
          //     kpiId,
          //     kpiMasterId,
          //     kpiName,
          //     kpiWeightage,
          //     kpiLevel,
          //     kpiTarget,
          //     kpiDescription,
          //     uomId,
          //     kpiTargetAchievement,
          //     kpiCategory,
          //     // departmentList,
          //     userList,
          //     kpiType,
          //     kpiStartDate,
          //     kpiDueDate,
          //     priorityId,
          //   }) => ({
          //     kpiId,
          //     kpiMasterId,
          //     kpiName,
          //     kpiWeightage,
          //     kpiLevel,
          //     kpiTarget,
          //     kpiDescription,
          //     uomId,
          //     kpiTargetAchievement,
          //     kpiCategory,
          //     // departmentIds:
          //     //   (departmentList.length > 0 &&
          //     //     departmentList?.map((obj) => obj?.departmentId)) ||
          //     //   [],
          //     userIds:
          //       (userList && userList?.length > 0 && userList?.map((obj) => obj?.userId)) ||
          //       [],
          //     kpiType,
          //     kpiStartDate: dayjs(kpiStartDate),
          //     kpiDueDate: dayjs(kpiDueDate),
          //     priorityId, // Add the new field here
          //   })
          // ),
        }}
      >
        <Row gutter={[15, 4]}>
          <Col lg={6} sm={24}>
            <span>Goal Name</span>
            <Form.Item name="goalName">
              <Input placeholder="name" />
            </Form.Item>
          </Col>
          <Col lg={6} sm={24}>
            <span>Goal Start Date</span>
            <Form.Item name="goalStartDate">
              <DatePicker style={{ width: "100%" }} />
            </Form.Item>
          </Col>
          <Col lg={6} sm={24}>
            <span>Goal End Date</span>
            <Form.Item name="goalEndDate">
              <DatePicker style={{ width: "100%" }} />
            </Form.Item>
          </Col>
          <Col xs={24} md={14} lg={6}>
            <span>Goal Type</span>
            <Form.Item name="goalTypeId">
              <Select
                showSearch
                style={{
                  width: "100%",
                }}
                placeholder="Goal Type"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? "")
                    .toLowerCase()
                    .localeCompare((optionB?.label ?? "").toLowerCase())
                }
                allowClear
                options={
                  goalType &&
                  goalType?.map((obj) => {
                    const keys = Object.keys(obj);
                    const convertedObj = {
                      value: obj["goalTypeId"],
                      label: obj["goalTypeName"],
                    };

                    for (let i = 2; i < keys.length; i++) {
                      convertedObj[keys[i]] = obj[keys[i]];
                    }

                    return convertedObj;
                  })
                }
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={14} lg={6}>
            <span>Goal Status</span>
            <Form.Item name="goalStatusId">
              <Select
                showSearch
                style={{
                  width: "100%",
                }}
                placeholder="Search to Select"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? "")
                    .toLowerCase()
                    .localeCompare((optionB?.label ?? "").toLowerCase())
                }
                allowClear
                options={
                  goalstatus &&
                  goalstatus?.map((obj) => {
                    const keys = Object.keys(obj);
                    const convertedObj = {
                      value: obj["goalStatusId"],
                      label: obj["goalStatusName"],
                    };

                    for (let i = 2; i < keys.length; i++) {
                      convertedObj[keys[i]] = obj[keys[i]];
                    }

                    return convertedObj;
                  })
                }
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={14} lg={6}>
            <span>Goal Category</span>
            <Form.Item name="goalCategoryId">
              <Select
                showSearch
                style={{
                  width: "100%",
                }}
                placeholder="Goal Category"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? "")
                    .toLowerCase()
                    .localeCompare((optionB?.label ?? "").toLowerCase())
                }
                allowClear
                options={
                  categoryType &&
                  categoryType?.map((obj) => {
                    const keys = Object.keys(obj);
                    const convertedObj = {
                      value: obj["categoryId"],
                      label: obj["categoryName"],
                    };

                    for (let i = 2; i < keys.length; i++) {
                      convertedObj[keys[i]] = obj[keys[i]];
                    }

                    return convertedObj;
                  })
                }
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={14} lg={6}>
            <span>Goal Priority</span>

            <Form.Item name="goalPriorityId">
              <Select
                showSearch
                style={{
                  width: "100%",
                }}
                placeholder="Search to Select"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? "")
                    .toLowerCase()
                    .localeCompare((optionB?.label ?? "").toLowerCase())
                }
                allowClear
                options={
                  priority &&
                  priority?.map((obj) => {
                    const keys = Object.keys(obj);
                    const convertedObj = {
                      value: obj["priorityId"],
                      label: obj["priorityName"],
                    };

                    for (let i = 2; i < keys.length; i++) {
                      convertedObj[keys[i]] = obj[keys[i]];
                    }

                    return convertedObj;
                  })
                }
              />
            </Form.Item>
          </Col>
          {/* <Col xs={24} md={14} lg={6}>
            <span>Assign Department</span>
            <Form.Item name="departmentIds">
              <Select
                mode="multiple"
                showSearch
                style={{
                  width: "100%",
                }}
                placeholder="Assign Department"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? "")
                    .toLowerCase()
                    .localeCompare((optionB?.label ?? "").toLowerCase())
                }
                allowClear
                options={
                  department &&
                  department?.map((obj) => {
                    const keys = Object.keys(obj);
                    const convertedObj = {
                      value: obj["departmentId"],
                      label: obj["departmentName"],
                    };

                    for (let i = 2; i < keys.length; i++) {
                      convertedObj[keys[i]] = obj[keys[i]];
                    }

                    return convertedObj;
                  })
                }
              />
            </Form.Item>
          </Col> */}

          <Col lg={18} sm={24}>
            <span>Goal Description</span>

            <ReactQuill
              style={{ width: "100%", marginBottom: "20px" }}
              theme="snow"
              value={richValue}
              onChange={setRichValue}
            />
          </Col>
        </Row>
        <div style={{ textAlign: "right" }}>
          <Button
            onClick={() => handleAddKpi()}
            style={{ backgroundColor: "#167dff" }}
          >
            +
          </Button>
        </div>
        <div className={styles.goalKpiBox}>
          <div style={{ width: "2000px" }}>
            <div style={{ display: "flex" }}>
              {/* <Row gutter={[12, 5]}> */}
              {kpiDetails.map((v, i) =>
                v !== "Target Achievement" ? (
                  <div
                    style={{
                      width: "130px",
                      margin: "12px",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {v}
                  </div>
                ) : (
                  <div
                    style={{
                      width: "140px",
                      margin: "12px",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {v}
                  </div>
                )
              )}
            </div>

            <div className={styles.goalDataList}>
              {kpiListData.length > 0 &&
                kpiListData.map((v, i) => (
                  <div className={styles.kpiDatas}>
                    <div style={{ display: "flex" }}>
                      <div className={styles.boxData}>{v?.kpiName}</div>
                      <div className={styles.boxData}>{v?.kpiTarget}</div>
                      <div className={styles.boxData}>
                        {v?.kpiTargetAchievement}
                      </div>
                      <div className={styles.boxData}>
                        {v?.uomName
                          ? v?.uomName
                          : v?.uomId
                          ? uom[uom.findIndex((val) => val.uomId == v?.uomId)]
                              ?.uomName
                          : 0}
                      </div>
                      <div className={styles.boxData}>{v?.kpiWeightage}</div>
                      <div className={styles.boxData}>{v?.kpiTarget}</div>
                      <div className={styles.boxData}>
                        {v?.kpiCategory
                          ? categoryType[
                              categoryType.findIndex(
                                (val) => val.categoryId == v?.kpiCategory
                              )
                            ]?.categoryName
                          : 0}
                      </div>
                      <div className={styles.boxData}>
                        {dayjs(v?.kpiStartDate).format("YYYY-MM-DD")}
                      </div>
                      <div className={styles.boxData}>
                        {dayjs(v?.kpiDueDate).format("YYYY-MM-DD")}
                      </div>
                      <div className={styles.boxData}>
                        {v?.userIds ? (
                          <>
                            {v.userIds.map((val) => {
                              return <div>{userData[
                                userData.findIndex((x) => x.userId == val)
                              ]?.firstName}</div>;
                            })}
                          </>
                        ) : (
                          <>
                            {v.userList.map((val) => {
                              return <div>{userData[
                                userData.findIndex(
                                  (x) => x.userId == val.userId
                                )
                              ]?.firstName}</div>;
                            })}
                          </>
                        )}
                      </div>
                      <div className={styles.boxData}>
                        {/* <EditOutlined onClick={() => handleopenmodal(v, i)} /> */}
                        <DeleteOutlined onClick={() => handleopenmodal(v, i)}/>
                      </div>
                    </div>
                    {/* <Row gutter={[3, 6]}>
                      <Col lg={2} sm={4}>{v?.kpiName}</Col>
                      <Col lg={2} sm={4}>{v?.kpiTarget}</Col>
                      <Col lg={2} sm={4}>{v?.kpiTargetAchievement}</Col>
                      <Col lg={2} sm={4}>{v?.kpiTarget}</Col>
                      <Col lg={2} sm={4}>{v?.uomName}</Col>
                      <Col lg={2} sm={4}>{v?.kpiWeightage}</Col>

                      <Col lg={2} sm={4}>
                        {dayjs(v?.kpiStartDate).format("YYYY-MM-DD")}
                      </Col>
                      <Col lg={2}>{(v?.kpiLevel == 1) ? "Low" : (v?.kpiLevel == 2) ? "Medium" : (v?.kpiLevel == 3) ? "High" : ""}</Col>
                      <Col lg={2}>
                        <EditOutlined onClick={() => handleopenmodal(v, i)} />

                      </Col>
                    </Row> */}
                  </div>
                ))}
            </div>
          </div>
        </div>

        <div style={{ margin: "20px", textAlign: "right" }}>
          <Button onClick={() => handleReject()} className={styles.btnReject}>
            Reject Goal PIP
          </Button>
          <Button type="primary" htmlType="submit" className={styles.submit}>
            Approve Goal PIP
          </Button>
        </div>
      </Form>

      {open && (
        <Modals
          open={open}
          // onOk={handleOk}
          handleCancel={() => {
            setOpen(false);
            setStateAccept(1);
          }}
        >
          <div className={styles.approval}>
            <img src={Approve} alt="Approve" />

            {stateAccept == 1 ? (
              <>
                <h1 className={styles.goalHead}>
                  GoalPIP is approved Successfully
                </h1>
                <p className={styles.para}>The GoalPIP is approved by you</p>
                <span className={`${styles.para} ${styles.font}`}>
                  A notification will alert the employee of the approval of the
                  goalpip!
                </span>
              </>
            ) : (
              <>
                <h1 className={`${styles.goalHead} ${styles.rejectHead}`}>
                  GoalPIP is Rejected
                </h1>
                <p className={`${styles.para} ${styles.reject}`}>
                  Please write a reason for rejection.{" "}
                </p>
                <Form form={forms} onFinish={SubmitDataRejection}>
                  <span>Reason</span>
                  <Form.Item name="goalRejectionComment">
                    <TextArea />
                  </Form.Item>

                  <Button type="primary" htmlType="submit">
                    Submit
                  </Button>
                </Form>
              </>
            )}
          </div>
        </Modals>
      )}

      <Modal open={openEdit} onCancel={() => setOpenEdit(false)} footer={false}>
        <EditKpi
          handleCreation={handleCreation}
          setKpiListData={setKpiListData}
          kpiListData={kpiListData}
          uom={uom}
          goalType={goalType}
          categoryType={categoryType}
          department={department}
          userData={userData}
          // editKpiData={editKpiData}
          setOpen={setOpenEdit}
        />
      </Modal>
    </div>
  );
}

export default AdminGoalApprovalpip;

const kpiDetails = [
  "KPI Name",
  "KPI Target",
  "Target Achievement",
  "UOM",
  "kpiWeightage",
  "Kpi Type",
  "Kpi Category",
  "Start Date",
  "End Date",
  "User",
  "Action",
];

/**
 *
 *
 *
 */
