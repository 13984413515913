import { ArrowLeftOutlined, DeleteFilled, EditFilled, SearchOutlined } from "@ant-design/icons";
import { Button, Input, Select, Table, Space, Tag, Pagination } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./index.module.css";
import { api_call_token } from "../../../../Utils/Network";
import { UserCredsContext } from "../../../../Utils/UserCredsContext";

function KpiList() {
  let navigate = useNavigate();
  const { logout } = useContext(UserCredsContext);
  const [kpiData, setKpiData] = useState([]);
  const [overAllData, setOverAllData] = useState({})
  const [filterObj, setFilterObj] = useState({
    page: 1,
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [totalNumberofPage, setTotalNumberofPage] = useState(null);
  const [status, setStatus] = useState({
    active: 0,
    inactive: 0,
  })
  useEffect(() => {
    getKpiData();
  }, [filterObj])

  const getKpiData = (paramObj = { ...filterObj }) => {
    api_call_token
      .get(`kpimaster`, { params: { ...paramObj } })
      .then((res) => {

        if (res.status == 200) {
          let data = res.data.data;
          let active = 0;
          let inactive = 0;
          data && data.length > 0 && data.map((v, i) => {
            if (v.active) {
              active = +active + 1;
            } else {
              inactive = +inactive + 1;
            }
          })
          setStatus({
            active: active,
            inactive: inactive
          })
          setKpiData(res.data.data)
          setCurrentPage(res.data.currentPage);
          setTotalNumberofPage(res.data.totalRowCount);
          setOverAllData(res.data)
        } else if (res.status == 401) {
          logout();
        }
      })
      .catch((err) => { console.log(err) })
  }

  const handleClick = () => {
    navigate("/master");
  };

  const redirect = (record) => {
    navigate('create', { state: { data: record } })
  }

  const handleCreate = () => {
    navigate("create", { state: { data: { active: 1 } } });
  };
  const columns = [
    {
      title: 'Name',
      dataIndex: 'kpiName',
      key: 'kpiName',
    },
    // {
    //   title: 'Category',
    //   dataIndex: 'category',
    //   key: 'category',
    //   render: (_) => {
    //     return <div className={`${styles.container} ${(_ == 1) ? styles.green : (_ == 2) ? styles.red : styles.lightBlue}`}>
    //       {(_ == 1) ? "Finance" : (_ == 2) ? "Sales" : (_ == 3) ? "Vehicle" : ""}
    //     </div>
    //   }
    // },
    {
      title: 'Created By',
      dataIndex: 'createdByName',
      key: 'createdByName',
    },
    // {
    //   title: 'Type',
    //   key: 'type',
    //   dataIndex: 'type',

    // },
    {
      title: 'Weightage %',
      key: 'kpiWeightage',
      dataIndex: 'kpiWeightage',
      render: (_) => {
        const num = _ ;
        const roundedNum = parseFloat(num.toFixed(2));
        return <div>{roundedNum}%</div>
      }
    },
    {
      title: 'Target',
      key: 'kpiTarget',
      dataIndex: 'kpiTarget',
      render: (_) =>{
        const num = _ ;
        const roundedNum = parseFloat(num.toFixed(2));
        return(
            <div>{roundedNum}</div>
        )
    }
    },
    {
      title: 'UOM',
      key: 'uomName',
      dataIndex: 'uomName',
    },
    // {
    //   title: 'Department',
    //   key: 'department',
    //   dataIndex: 'department',
    //   render: (_) => {
    //     return <div className={`${styles.container} ${(_ == 1) ? styles.darkBlue : (_ == 2) ? styles.fadeBlue : styles.darkPurple}`}>
    //       {(_ == 1) ? "Department" : (_ == 2) ? "Individual" : (_ == 3) ? "Corporate" : ""}
    //     </div>
    //   }
    // },
    {
      title: 'Status',
      key: 'active',
      dataIndex: 'active',
      render: (_) => {
        return <div className={`${styles.container} ${(_) ? styles.green : styles.orange}`}>
          {(_) ? "Active" : "Inactive"}
        </div>
      }
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <Space size="middle">
          <DeleteFilled />
          <EditFilled onClick={() => redirect(record)} />
        </Space>
      ),
    },
  ];

  const handlePageChange = (page) => {
    console.log(page, "aaaaaaaaa");
    setFilterObj({ ...filterObj, page: page });
    setCurrentPage(page);
  };

  const paginationConfig = {
    current: currentPage,
    pageSize: 10, // Number of items per page
    total: totalNumberofPage, // Total number of items
    onChange: handlePageChange,
  };

  const onSearch = (e) => {
    let value = e.target.value;
    let obj = filterObj;
    if (value) { obj.search = value; setCurrentPage(1) }
    else delete obj.search;
    setFilterObj({ ...obj, page: 1 });
  }


  return (
    <div className={styles.boxKPI}>
      <div className={styles.headerData}>
        <div className={styles.kpiText}>
          <ArrowLeftOutlined
            style={{ marginRight: "20px" }}
            onClick={handleClick}
          />
          KPI Master
        </div>
        <div>
          <Button type="primary" className={styles.btn} onClick={() => handleCreate()}>
            + New KPI
          </Button>
        </div>
      </div>
      <div className={styles.midContainer}>
        <div className={styles.inputData}>
          <Input
            placeholder="Search by KPI"
            prefix={<SearchOutlined />}
            style={{ width: "100%" }}
            onChange={(e) => onSearch(e)}
          />
        </div>

       
        {/* </div>
      <div className={styles.containerRight}> */}
        <div className={styles.dataActive}>
          <div className={`${styles.boxDatas} ${styles.blueBorder}`}>
            <p>Total KPI</p>
            <p>{overAllData?.totalRowCount}</p>
          </div>

          <div className={`${styles.boxDatas} ${styles.greenBorder}`}>
            <p style={{ color: '#2EB67D' }}>Active</p>
            <p>{overAllData?.activeTotal}</p>
          </div>
          <div className={`${styles.boxDatas} ${styles.redBorder}`}>
            <p style={{ color: '#DB4437' }}>Inactive</p>
            <p>{overAllData?.inActive}</p>
          </div>
        </div>

      </div>
      <div className={styles.tables}>
        <Table columns={columns} dataSource={kpiData} pagination={false} />

        <div style={{ textAlign: "right", margin: "10px" }}>
          <Pagination {...paginationConfig} showSizeChanger={false}/>
        </div>
      </div>
    </div>
  );
}

export default KpiList;


const data = [
  {
    id: 1,
    name: "KPI Name 01",
    category: 1, //1 --> Finance, 2 --> Sales, 3 --> Vehicle
    createdBy: "Akshat",
    type: "Monthly",
    weightage: '30%',
    target: 120000,
    uom: 'Days',
    department: 1, //1 --> Department, 2 --> Individual, 3 --> Corporate
    status: 1, //1 --> Active, 2 --> Inactive, 3 --> Cancelled
  },
  {
    id: 2,
    name: "KPI Name 01",
    category: 3, //1 --> Finance, 2 --> Sales, 3 --> Vehicle
    createdBy: "Akshat",
    type: "Monthly",
    weightage: '20%',
    target: 80000,
    uom: 'Ksh',
    department: 1, //1 --> Department, 2 --> Individual, 3 --> Corporate
    status: 1, //1 --> Active, 2 --> Inactive, 3 --> Cancelled
  }, {
    id: 3,
    name: "KPI Name 01",
    category: 2, //1 --> Finance, 2 --> Sales, 3 --> Vehicle
    createdBy: "Akshat",
    type: "Monthly",
    weightage: '30%',
    target: 120000,
    uom: '%',
    department: 2, //1 --> Department, 2 --> Individual, 3 --> Corporate
    status: 2, //1 --> Active, 2 --> Inactive, 3 --> Cancelled
  }, {
    id: 4,
    name: "KPI Name 01",
    category: 3, //1 --> Finance, 2 --> Sales, 3 --> Vehicle
    createdBy: "Akshat",
    type: "Monthly",
    weightage: '30%',
    target: 120000,
    uom: 'Days',
    department: 3, //1 --> Department, 2 --> Individual, 3 --> Corporate
    status: 1, //1 --> Active, 2 --> Inactive, 3 --> Cancelled
  }, {
    id: 5,
    name: "KPI Name 01",
    category: 2, //1 --> Finance, 2 --> Sales, 3 --> Vehicle
    createdBy: "Akshat",
    type: "Monthly",
    weightage: '30%',
    target: 120000,
    uom: 'Days',
    department: 1, //1 --> Department, 2 --> Individual, 3 --> Corporate
    status: 2, //1 --> Active, 2 --> Inactive, 3 --> Cancelled
  }
]