import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Switch,
} from "antd";
import React, { useEffect, useState } from "react";
import styles from "./index.module.css";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import KpiCreation from "./KpiCreation";
import { api_call_token } from "../../../Utils/Network";
import moment from "moment";
import { useLocation, useNavigate } from "react-router-dom";
import { ArrowLeftOutlined, EditOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import EditKpi from "./EditKpi";

function GoalCreation() {
  const today = dayjs();
  let location = useLocation();
  let navigate = useNavigate();
  const [richValue, setRichValue] = useState("");
  const [form] = Form.useForm();
  const [kpiListData, setKpiListData] = useState([]);
  const [userData, setUserData] = useState([]);
  const [uom, setUom] = useState([]);
  const [goalType, setGoalType] = useState([]);
  const [goalstatus, setGoalStatus] = useState([]);
  const [categoryType, setCategory] = useState([]);
  const [department, setDepartment] = useState([]);
  const [priority, setPriority] = useState([]);
  const [open, setOpen] = useState(false);
  const [kpiList, setKpiList] = useState([]);
  const [editKpiData, setEditKpiData] = useState({});
  const [indexKpi, setIndexKpi] = useState(null)
// permission
const [selectedReadUsers, setSelectedReadUsers] = useState([]);
const [selectedWriteUsers, setSelectedWriteUsers] = useState([]);
// kip user selection
const [selectedKpiReadUsers, setSelectedKpiReadUsers] = useState([]);
const [selectedKpiWriteUsers, setSelectedKpiWriteUsers] = useState([]);


  // const today = moment();
  useEffect(() => {
    getUom();
    getGoalType();
    getGoalStatus();
    getCategory();
    getDepartment();
    getPriority();
    getUserData();
    getkpi();
  }, []);

  useEffect(() => {
    if (location?.state?.goalKpiList?.length > 0) {
      const newArray = location?.state?.goalKpiList.map((kpi) => {
        return {
          kpiId: kpi.kpiId,
          kpiMasterId: kpi.kpiMasterId,
          kpiName: kpi.kpiName,
          kpiWeightage: kpi.kpiWeightage,
          kpiLevel: kpi.kpiLevel,
          kpiDescription: kpi.kpiDescription,
          kpiTarget: kpi.kpiTarget,
          uomId: kpi.uomId,
          kpiTargetAchievement: kpi.kpiTargetAchievement,
          kpiCategory: kpi.kpiCategory,
          departmentIds:[kpi?.departmentId],
            // kpi.departmentList?.map((obj) => obj?.departmentId) || [],
          userIds: kpi.userList?.map((obj) => obj?.userId) || [],
          permissionUserIds : kpi.permissionUserList?.map((obj) => obj?.userId) || [],
          kpiType: kpi.kpiType,
          kpiStartDate: kpi.kpiStartDate,
          kpiDueDate: kpi.kpiDueDate,
          priorityId: kpi.priorityId,
          priorityName: kpi.priorityName,
          uomName: kpi.uomName,
        };
      });

      setKpiListData(newArray);
      setRichValue(location?.state?.goalDescription);
    }
  }, [location]);


  const getkpi = () => {
    api_call_token
      .get(`kpimaster?size=300`)
      .then((res) => {
        if (res.status == 200 || res.status == 201 || res.status == 204) {
          setKpiList(res.data.data);
        } 
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getUserData = () => {
    api_call_token
      .get(`createuser/alluser?size=100`)
      .then((res) => {
        setUserData(res.data.data);
      })
      .catch((err) => console.log(err));
  };

  const getPriority = () => {
    api_call_token
      .get(`priority`)
      .then((res) => {
        setPriority(res.data.data);
      })
      .catch((err) => console.log(err));
  };

  const getDepartment = () => {
    api_call_token
      .get(`department?size=1000`)
      .then((res) => {
        setDepartment(res.data.data);
      })
      .catch((err) => console.log(err));
  };

  const getCategory = () => {
    api_call_token
      .get(`category`)
      .then((res) => {
        setCategory(res.data.data);
      })
      .catch((err) => console.log(err));
  };

  const getGoalType = () => {
    api_call_token
      .get(`goaltype`)
      .then((res) => {
        setGoalType(res.data.data);
      })
      .catch((err) => console.log(err));
  };

  const getGoalStatus = () => {
    api_call_token
      .get(`goalstatus`)
      .then((res) => {
        setGoalStatus(res.data.data);
      })
      .catch((err) => console.log(err));
  };

  const getUom = () => {
    api_call_token
      .get(`uom`)
      .then((res) => {
        setUom(res.data.data);
      })
      .catch((err) => console.log(err));
  };

  const handleData = (e) => {
    for (const key in e) {
      if (e.hasOwnProperty(key)) {
        const value = e[key];
        if (
          typeof value === "object" &&
          (value.$d instanceof Date || value._d instanceof Date)
        ) {
          if (value.$d) {
            // e[key] = moment(value.$d).format("YYYY-MM-DD");
            e[key] = dayjs(value.$d).format("YYYY-MM-DD");
          } else {
            e[key] = dayjs(value._d).format("YYYY-MM-DD");
            // e[key] = moment(value._d).format("YYYY-MM-DD");
          }
        } else if (typeof value === "string" && !isNaN(value)) {
          e[key] = Number(value);
        }
      }
    }

    console.log(e, kpiListData);
    let goalData = {
      ...e,
      corporate: e.corporate ? 1 : 0,
      goalDescription: richValue,
      goalKpiList: kpiListData,
      departmentIds: [e?.departmentIds],
    };

    console.log(goalData, "GOALDATA");

    if (location?.state?.goalUniqueId) {
      let data = {
        ...goalData,
        goalUniqueId: location?.state?.goalUniqueId,
        departmentIds: goalData?.departmentIds[0]
      };

      console.log(data,goalData,e, "PATCH");
      api_call_token
        .patch(`goal/${location?.state?.goalId}`, data)
        .then((res) => {
          navigate("/goals");
        })
        .catch((err) => console.log(err));
    } else {
      if (goalData.goalKpiList && Array.isArray(goalData.goalKpiList)) {
        goalData.goalKpiList = goalData.goalKpiList.map((kpiObject) => {
          // Create a new object without 'kpiId' property
          const { kpiId, ...newKpiObject } = kpiObject;
          return newKpiObject;
        });
      }
      console.log(goalData, "GOALDATA");

      api_call_token
        .post(`goal`, goalData)
        .then((res) => {
          navigate("/goals");
        })
        .catch((err) => console.log(err));
    }
  };

  const handleopenmodal = (val) => {
    console.log(val);
    setOpen(true);
    setEditKpiData(val)
  };

  const handleClick = () => {
    navigate("/goals");
  };

  console.log(kpiListData, "kpiListData");

    //  chages with user permission  selcte drop down for goal

// const handleReadUserChange = (selectedUsers) => {
//   console.log(selectedUsers, 'handleReadUserChange');
//   setSelectedReadUsers(selectedUsers);
// };
// console.log(selectedReadUsers, "selectedReadUsers");


// const handleWriteUserChange = (selectedUsers) =>{
//   setSelectedWriteUsers(selectedUsers)
// }

useEffect(() =>{
  // Extracting the "userId" values
  if(location?.state?.userList?.length > 0){
    const userIdArray = location?.state?.userList.map(item => item.userId);
    setSelectedReadUsers(userIdArray)

  }
},[location?.state?.userList])

const handleReadUserChange = (selectedUsers) => {
  console.log(selectedUsers, 'handleReadUserChange');
  setSelectedReadUsers(selectedUsers);
};
console.log(selectedReadUsers, "selectedReadUsers");


useEffect(() => {
  if(location?.state?.permissionUserList){
    const userIdArray = location?.state?.permissionUserList.map(item => item.userId);
    setSelectedWriteUsers(userIdArray)
  }


}, [location?.state?.permissionUserList])

const handleWriteUserChange = (selectedUsers) =>{
  setSelectedWriteUsers(selectedUsers)
}





  return (
    <div className={styles.goals}>
      <div className={styles.header}>
        <ArrowLeftOutlined
          style={{ marginRight: "20px" }}
          onClick={handleClick}
        />
        <h1 className={styles.masters}>Create New Goal</h1>
      </div>

      <Form
        form={form}
        onFinish={handleData}
        initialValues={{
          ...location?.state,
          departmentIds: [location?.state?.departmentId],
          // location?.state?.departmentList?.map(
          //   (obj) => obj?.departmentId
          // ),
          userIds: location?.state?.userList.map((obj) => obj?.userId),
          permissionUserIds: location?.state?.permissionUserList?.map((obj) => obj?.userId),
          goalStartDate: location?.state?.goalStartDate
            ? dayjs(location?.state?.goalStartDate)
            : dayjs(today),
          goalEndDate: location?.state?.goalEndDate
            ? dayjs(location?.state?.goalEndDate)
            : dayjs(today),
        }}
      >
        <div style={{ margin: "20px", textAlign: "right" }}>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </div>

        <Row gutter={[15, 4]}>
          <Col lg={6} sm={24} xs={24}>
            <span>Goal Name</span>
            <Form.Item name="goalName"
              rules={[
                {
                  required: true,
                  message: "Goal Name Required",
                },
              ]}
            >
              <Input placeholder="name" />
            </Form.Item>
          </Col>
          <Col lg={6} sm={6} xs={12}>
            <span>Goal Start Date</span>
            <Form.Item name="goalStartDate">
              <DatePicker style={{ width: "100%" }} />
            </Form.Item>
          </Col>
          <Col lg={6} sm={6} xs={12}>
            <span>Goal End Date</span>
            <Form.Item name="goalEndDate">
              <DatePicker style={{ width: "100%" }} />
            </Form.Item>
          </Col>
          <Col lg={6} sm={6} xs={12}>
            <span>Goal Type</span>
            <Form.Item name="goalTypeId"
            rules={[
              {
                required: true,
                message: "Goal Type Required",
              },
            ]}
            >
              <Select
                showSearch
                style={{
                  width: "100%",
                }}
                placeholder="Goal Type"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? "")
                    .toLowerCase()
                    .localeCompare((optionB?.label ?? "").toLowerCase())
                }
                allowClear
                options={
                  goalType &&
                  goalType?.map((obj) => {
                    const keys = Object.keys(obj);
                    const convertedObj = {
                      value: obj["goalTypeId"],
                      label: obj["goalTypeName"],
                    };

                    for (let i = 2; i < keys.length; i++) {
                      convertedObj[keys[i]] = obj[keys[i]];
                    }

                    return convertedObj;
                  })
                }
              />
            </Form.Item>
          </Col>
          <Col lg={6} sm={6} xs={12}>
            <span>Goal Status</span>
            <Form.Item name="goalStatusId">
              <Select
                showSearch
                style={{
                  width: "100%",
                }}
                placeholder="Search to Select"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? "")
                    .toLowerCase()
                    .localeCompare((optionB?.label ?? "").toLowerCase())
                }
                allowClear
                options={
                  goalstatus &&
                  goalstatus?.map((obj) => {
                    const keys = Object.keys(obj);
                    const convertedObj = {
                      value: obj["goalStatusId"],
                      label: obj["goalStatusName"],
                    };

                    for (let i = 2; i < keys.length; i++) {
                      convertedObj[keys[i]] = obj[keys[i]];
                    }

                    return convertedObj;
                  })
                }
              />
            </Form.Item>
          </Col>
          <Col lg={6} sm={6} xs={12}>
            <span>Goal Category</span>
            <Form.Item name="goalCategoryId">
              <Select
                showSearch
                style={{
                  width: "100%",
                }}
                placeholder="Goal Category"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? "")
                    .toLowerCase()
                    .localeCompare((optionB?.label ?? "").toLowerCase())
                }
                allowClear
                options={
                  categoryType &&
                  categoryType?.map((obj) => {
                    const keys = Object.keys(obj);
                    const convertedObj = {
                      value: obj["categoryId"],
                      label: obj["categoryName"],
                    };

                    for (let i = 2; i < keys.length; i++) {
                      convertedObj[keys[i]] = obj[keys[i]];
                    }

                    return convertedObj;
                  })
                }
              />
            </Form.Item>
          </Col>
          <Col lg={6} sm={6} xs={12}>
            <span>Goal Priority</span>

            <Form.Item name="goalPriorityId">
              <Select
                showSearch
                style={{
                  width: "100%",
                }}
                placeholder="Search to Select"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? "")
                    .toLowerCase()
                    .localeCompare((optionB?.label ?? "").toLowerCase())
                }
                allowClear
                options={
                  priority &&
                  priority?.map((obj) => {
                    const keys = Object.keys(obj);
                    const convertedObj = {
                      value: obj["priorityId"],
                      label: obj["priorityName"],
                    };

                    for (let i = 2; i < keys.length; i++) {
                      convertedObj[keys[i]] = obj[keys[i]];
                    }

                    return convertedObj;
                  })
                }
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={14} lg={6}>
            <span>Assign Department</span>
            <Form.Item name="departmentIds">
              <Select
                // mode="multiple"
                showSearch
                style={{
                  width: "100%",
                }}
                placeholder="Assign Department"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? "")
                    .toLowerCase()
                    .localeCompare((optionB?.label ?? "").toLowerCase())
                }
                allowClear
                options={
                  department &&
                  department?.map((obj) => {
                    const keys = Object.keys(obj);
                    const convertedObj = {
                      value: obj["departmentId"],
                      label: obj["departmentName"],
                    };

                    for (let i = 2; i < keys.length; i++) {
                      convertedObj[keys[i]] = obj[keys[i]];
                    }

                    return convertedObj;
                  })
                }
              />
            </Form.Item>
          </Col>

          
          <Col xs={24} md={14} lg={6}>
            <span>Assign User with Read Permission</span>
            <Form.Item name="userIds">
              <Select
                mode="multiple"
                showSearch
                style={{
                  width: "100%",
                }}
                placeholder="Assign User"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? "")
                    .toLowerCase()
                    .localeCompare((optionB?.label ?? "").toLowerCase())
                }
                allowClear
                onChange={handleReadUserChange}
                options={
                  userData &&
                  userData?.map((obj) => {
                    const keys = Object.keys(obj);
                    const convertedObj = {
                      value: obj["userId"],
                      label: `${obj["firstName"]} ${obj["lastName"]}`,
                      disabled: selectedWriteUsers.includes(obj["userId"]) // Disable option if it's selected in the first Select
                    };

                    for (let i = 2; i < keys.length; i++) {
                      convertedObj[keys[i]] = obj[keys[i]];
                    }

                    return convertedObj;
                  })
                }
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={14} lg={6}>
            <span>Assign User with Write Premission</span>
            <Form.Item name="permissionUserIds">
              <Select
                mode="multiple"
                showSearch
                style={{
                  width: "100%",
                }}
                placeholder="Assign User"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? "")
                    .toLowerCase()
                    .localeCompare((optionB?.label ?? "").toLowerCase())
                }
                allowClear
                onChange={handleWriteUserChange}
                options={
                  userData &&
                  userData?.map((obj) => {
                    const keys = Object.keys(obj);
                    const convertedObj = {
                      value: obj["userId"],
                      label: `${obj["firstName"]} ${obj["lastName"]}`,
                      disabled: selectedReadUsers.includes(obj["userId"]) // Disable option if it's selected in the first Select
                    };

                    for (let i = 2; i < keys.length; i++) {
                      convertedObj[keys[i]] = obj[keys[i]];
                    }

                    return convertedObj;
                  })
                }
              />
            </Form.Item>
          </Col>
          <Col lg={24} sm={24}>
            <span>Goal Description</span>

            <ReactQuill
              style={{ width: "100%", marginBottom: "20px" }}
              theme="snow"
              value={richValue}
              onChange={setRichValue}
            />
          </Col>
          <Col>
            <span>Corporate</span>
            {location?.state?.corporate ? (
              <Form.Item name="corporate">
                <Switch
                  checkedChildren="Yes"
                  unCheckedChildren="No"
                  defaultChecked
                />
              </Form.Item>
            ) : (
              <Form.Item name="corporate">
                <Switch checkedChildren="Yes" unCheckedChildren="No" />
              </Form.Item>
            )}
          </Col>
        </Row>
        <KpiCreation
          setKpiListData={setKpiListData}
          kpiListData={kpiListData}
          uom={uom}
          goalType={goalType}
          categoryType={categoryType}
          department={department}
          userData={userData}
        />
      </Form>

      <div className={styles.mainGoal}>
        <div className={styles.goalData}>
          <Row gutter={[12, 6]}>
            {listData.map((val, idx) => {
              return val == "Kpi No" ? (
                <Col lg={1}>{val}</Col>
              ) : val == "Action" || val == "Priority" ? (
                <Col lg={2}>{val}</Col>
              ) : (
                <Col lg={3}>{val}</Col>
              );
            })}
          </Row>
        </div>
        <div className={styles.goalDataList}>

          {kpiListData.length > 0 &&
            kpiListData.map((v, i) => (
              <div className={styles.kpiDatas}>
                <Row gutter={[12, 6]}>
                  <Col lg={1} sm={4}>{v?.kpiMasterId}</Col>
                  <Col lg={3} sm={4}>{v?.kpiName}</Col>
                  <Col lg={3} sm={4}>{v?.kpiWeightage}</Col>
                  <Col lg={3} sm={4}>{v?.kpiTarget}</Col>
                  <Col lg={3} sm={4}>{v?.uomName}</Col>
                  <Col lg={3} sm={4}>{v?.kpiTargetAchievement}</Col>
                  <Col lg={3} sm={4}>
                    {dayjs(v?.kpiStartDate).format("YYYY-MM-DD")}
                  </Col>
                  <Col lg={2}>{(v?.kpiLevel == 1) ? "Low" : (v?.kpiLevel == 2) ? "Medium": (v?.kpiLevel == 3) ? "High" : ""}</Col>
                  <Col lg={2}>
                    <EditOutlined onClick={() => handleopenmodal(v, i)} />
                  </Col>
                </Row>
              </div>
            ))}
        </div>
      </div>

      <Modal open={open} onCancel={() => setOpen(false)} footer={false}>
        <EditKpi
          setKpiListData={setKpiListData}
          kpiListData={kpiListData}
          uom={uom}
          goalType={goalType}
          categoryType={categoryType}
          department={department}
          userData={userData}
          editKpiData={editKpiData}
          setOpen={setOpen}
        />
      </Modal>
    </div>
  );
}

export default GoalCreation;

const listData = [
  "Kpi No",
  "Kpi Name",
  "Weightage",
  "Target",
  "UOM",
  "Target Achievement",
  "Start Date",
  "Priority",
  "Action",
];

const rowData = [
  "kpiId",
  "kpiName",
  "kpiWeightage",
  "kpiTarget",
  "uomId",
  "departmentId",
  "priorityId",
];
