import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  message,
  Modal,
  Row,
  Select,
} from "antd";
import React, { useEffect, useState, useContext } from "react";
import styles from "./index.module.css";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useLocation, useNavigate } from "react-router-dom";
import { ArrowLeftOutlined, PlusOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import { api_call_token } from "../../../Utils/Network";
import { UserCredsContext } from "../../../Utils/UserCredsContext";
import { alertMessage } from "../../../Utils/helperFunction";
import Modals from "../../../Component/Modals/Modals";
import Approve from "../../../Assets/ICONS/Approved.png";
import TextArea from "antd/es/input/TextArea";

function AdminGoalApproval() {
  const today = dayjs();
  let location = useLocation();
  let navigate = useNavigate();
  const [stateAccept, setStateAccept] = useState(1);
  const [open, setOpen] = useState(false);
  const [richValue, setRichValue] = useState("");
  const [form] = Form.useForm();
  const [forms] = Form.useForm();
  const [kpiList, setKpiList] = useState([]);
  const [kpiListData, setKpiListData] = useState([]);
  const [uom, setUom] = useState([]);
  const [goalType, setGoalType] = useState([]);
  const [goalstatus, setGoalStatus] = useState([]);
  const [categoryType, setCategory] = useState([]);
  const [department, setDepartment] = useState([]);
  const [priority, setPriority] = useState([]);
  const [userData, setUserData] = useState([]);
  const { userState, logout } = useContext(UserCredsContext);
  const [selectedReadUsers, setSelectedReadUsers] = useState([]);
  const [selectedWriteUsers, setSelectedWriteUsers] = useState([]);
  // kip user selection
  const [selectedKpiReadUsers, setSelectedKpiReadUsers] = useState([]);
  const [selectedKpiWriteUsers, setSelectedKpiWriteUsers] = useState([]);
  console.log(uom, "goalType");

  // const today = moment();
  useEffect(() => {
    getUom();
    getGoalType();
    getGoalStatus();
    getCategory();
    getDepartment();
    getPriority();
    getUserData();
    getkpi();
  }, []);

  useEffect(() => {
    if (location?.state?.goalKpiList?.length > 0) {
      setKpiListData(location?.state?.goalKpiList);
      setRichValue(location?.state?.goalDescription);
    }
  }, [location]);

  const getkpi = () => {
    api_call_token
      .get(`kpimaster?size=300`)
      .then((res) => {
        if (res.status == 200 || res.status == 201 || res.status == 204) {
          setKpiList(res.data.data);
        } else if (res.status == 401) {
          logout();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getPriority = () => {
    api_call_token
      .get(`priority`)
      .then((res) => {
        setPriority(res.data.data);
      })
      .catch((err) => console.log(err));
  };

  const getDepartment = () => {
    api_call_token
      .get(`department?size=300`)
      .then((res) => {
        setDepartment(res.data.data);
      })
      .catch((err) => console.log(err));
  };

  const getCategory = () => {
    api_call_token
      .get(`category`)
      .then((res) => {
        setCategory(res.data.data);
      })
      .catch((err) => console.log(err));
  };

  const getGoalType = () => {
    api_call_token
      .get(`goaltype`)
      .then((res) => {
        setGoalType(res.data.data);
      })
      .catch((err) => console.log(err));
  };

  const getGoalStatus = () => {
    api_call_token
      .get(`goalstatus`)
      .then((res) => {
        setGoalStatus(res.data.data);
      })
      .catch((err) => console.log(err));
  };

  const getUom = () => {
    api_call_token
      .get(`uom`)
      .then((res) => {
        setUom(res.data.data);
      })
      .catch((err) => console.log(err));
  };

  const getUserData = () => {
    api_call_token
      .get(`createuser/alluser?size=300`)
      .then((res) => {
        setUserData(res.data.data);
      })
      .catch((err) => console.log(err));
  };

  const handleData = (e) => {
    for (const key in e) {
      if (e.hasOwnProperty(key)) {
        const value = e[key];
        if (key === "goalKpiList" && Array.isArray(value)) {
          // If the key is 'goalKpiList' and the value is an array
          e[key] = value.map((kpi) => {
            // debugger;
            // Map over each item in the 'goalKpiList' array
            const formattedKpi = { ...kpi };
            for (const kpiKey in formattedKpi) {
              if (formattedKpi.hasOwnProperty(kpiKey)) {
                const kpiValue = formattedKpi[kpiKey];
                if (
                  typeof kpiValue === "object" &&
                  (kpiValue.$d instanceof Date || kpiValue._d instanceof Date)
                ) {
                  formattedKpi[kpiKey] = dayjs(
                    kpiValue.$d ? kpiValue.$d : kpiValue._d
                  ).format("YYYY-MM-DD");
                }
              }
            }

            // Ensure departmentIds is an array
            if (
              formattedKpi.departmentIds &&
              !Array.isArray(formattedKpi.departmentIds)
            ) {
              formattedKpi.departmentIds = [formattedKpi.departmentIds];
            }
            return formattedKpi;
          });
        } else if (
          typeof value === "object" &&
          (value.$d instanceof Date || value._d instanceof Date)
        ) {
          if (value.$d) {
            e[key] = dayjs(value.$d).format("YYYY-MM-DD");
          } else {
            e[key] = dayjs(value._d).format("YYYY-MM-DD");
          }
        } else if (typeof value === "string" && !isNaN(value)) {
          e[key] = Number(value);
        }
      }
    }

    let data = {
      ...e,
      goalUniqueId: location?.state?.goalUniqueId,
      departmentIds: [e?.departmentIds],
      goalDescription: richValue,
    };
    console.log(data, e.goalKpiList, "897yihfkjdh");
    api_call_token
      .patch(`goal/${location?.state?.goalId}`, data)
      .then((res) => {
        if (res.status == 201 || res.status == 200 || res.status == 204) {
          approve();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const approve = () => {
    setOpen(true);
    let data = {
      goalId: location?.state?.goalId,
      goalUniqueId: location?.state?.goalUniqueId,
      goalApprovedOn: dayjs(today).format("YYYY-MM-DD"),
      goalApprovedStatus: 1,
    };
    if (location?.state?.goalApprovedStatus) {
      api_call_token
        .patch(`goal/approval/${location?.state?.goalApprovedId}`, data)
        .then((res) => {
          if (res.status == 200 || res.status == 201 || res.status == 204) {
            setTimeout(() => {
              navigate("/admingoals");
            }, 3000);
          }
        })
        .catch((err) => {
          alertMessage("Looks Like Something went wrong", 2);
        });
    } else {
      api_call_token
        .post(`goal/approval`, data)
        .then((res) => {
          if (res.status == 200 || res.status == 201 || res.status == 204) {
            alertMessage("Goal Approved ");
            setTimeout(() => {
              navigate("/admingoals");
            }, 3000);
          }
        })
        .catch((err) => {
          alertMessage("Looks Like Something went wrong", 2);
        });
    }
  };

  const handleClick = () => {
    navigate("/admingoals");
  };

  const handleReject = () => {
    setStateAccept(2);
    setOpen(true);
  };

  const SubmitDataRejection = (e) => {
    let data = {
      ...e,
      goalId: location?.state?.goalId,
      goalUniqueId: location?.state?.goalUniqueId,
      goalApprovedOn: dayjs(today).format("YYYY-MM-DD"),
      goalApprovedStatus: 2,
      // departmentIds:[e?.departmentIds],
    };

    if (location?.state?.goalApprovedStatus) {
      api_call_token
        .patch(`goal/approval/${location?.state?.goalApprovedId}`, data)
        .then((res) => {
          if (res.status == 200 || res.status == 201 || res.status == 204) {
            setOpen(false);
            alertMessage("Message Sent ");
            setTimeout(() => {
              navigate("/admingoals");
            }, 2000);
          }
        })
        .catch((err) => {
          alertMessage("Looks Like Something went wrong", 2);
        });
    } else {
      api_call_token
        .post(`goal/approval`, data)
        .then((res) => {
          if (res.status == 200 || res.status == 201 || res.status == 204) {
            setOpen(false);
            alertMessage("Message Sent ");
            setTimeout(() => {
              navigate("/admingoals");
            }, 2000);
          }
        })
        .catch((err) => {
          alertMessage("Looks Like Something went wrong", 2);
        });
    }
  };
  //   console.log(kpiListData, "kpiListData");
  console.log(location.state.goalKpiList, "LOCATION");

  //  chages with user permission  selcte drop down for goal

  useEffect(() => {
    // Extracting the "userId" values
    if (location?.state?.userList?.length > 0) {
      const userIdArray = location?.state?.userList.map((item) => item.userId);
      setSelectedReadUsers(userIdArray);
    }
  }, [location?.state?.userList]);

  const handleReadUserChange = (selectedUsers) => {
    console.log(selectedUsers, "handleReadUserChange");
    setSelectedReadUsers(selectedUsers);
  };
  console.log(selectedReadUsers, "selectedReadUsers");

  useEffect(() => {
    if (location?.state?.permissionUserList) {
      const userIdArray = location?.state?.permissionUserList.map(
        (item) => item.userId
      );
      setSelectedWriteUsers(userIdArray);
    }
  }, [location?.state?.permissionUserList]);

  const handleWriteUserChange = (selectedUsers) => {
    setSelectedWriteUsers(selectedUsers);
  };

  //  chages with user permission  selecte drop down for goal kpi

  useEffect(() => {
    if (location?.state?.goalKpiList) {
      // Extract userIds from userList within each item of goalKpiList
      const userIdArray = location.state.goalKpiList.map((kpiItem) => {
        // Extract userIds from userList
        const userIds = kpiItem.userList.map((user) => user.userId);
        return userIds;
      });
      setSelectedKpiReadUsers(userIdArray);
    }
  }, [location?.state?.goalKpiList]);

  const handleKpiReadUserChange = (selectedUsers, index) => {
    console.log(selectedUsers, index, "handleReadUserChange");
    // setSelectedKpiReadUsers(selectedUsers);
    // Extract userIds from selectedUsers
    const userIds = selectedUsers.filter((user) => user).map((user) => user);
    console.log(userIds, "handleKpiReadUserChange for index:", index);

    // Update selectedKpiReadUsers state for the specific index
    setSelectedKpiReadUsers((prevUsers) => {
      // Create a copy of the previous state
      const updatedUsers = [...prevUsers];
      // Update the userIds for the specific index
      updatedUsers[index] = userIds;
      return updatedUsers;
    });

    console.log(selectedKpiReadUsers, index, "handleReadUserChange");
  };
  console.log(selectedReadUsers, "selectedReadUsers");

  useEffect(() => {
    // if(location?.state?.userList?.length > 0){
    //   const userIdArray = location?.state?.permissionUserList.map(item => item.userId);
    //   setSelectedKpiWriteUsers(userIdArray)
    // }
    if (location?.state?.goalKpiList) {
      // Extract userIds from userList within each item of goalKpiList
      const userIdArray = location.state.goalKpiList.map((kpiItem) => {
        // Extract userIds from userList
        const userIds = kpiItem.permissionUserList.map((user) => user.userId);
        return userIds;
      });
      setSelectedKpiWriteUsers(userIdArray);
    }
  }, [location?.state?.goalKpiList]);

  const handleKpiWriteUserChange = (selectedUsers, index) => {
    // setSelectedKpiWriteUsers(selectedUsers)
    console.log(selectedUsers, index, "handleReadUserChange");
    // setSelectedKpiReadUsers(selectedUsers);
    // Extract userIds from selectedUsers
    const userIds = selectedUsers.filter((user) => user).map((user) => user);
    console.log(userIds, "handleKpiReadUserChange for index:", index);

    // Update selectedKpiReadUsers state for the specific index
    setSelectedKpiWriteUsers((prevUsers) => {
      // Create a copy of the previous state
      const updatedUsers = [...prevUsers];
      // Update the userIds for the specific index
      updatedUsers[index] = userIds;
      return updatedUsers;
    });

    console.log(selectedKpiReadUsers, index, "handleReadUserChange");
  };

  return (
    <div className={styles.goals}>
      <div className={styles.header}>
        <ArrowLeftOutlined
          style={{ marginRight: "20px" }}
          onClick={handleClick}
        />
        <h1 className={styles.masters}>Approve Goal</h1>
      </div>

      <Form
        form={form}
        onFinish={handleData}
        initialValues={{
          ...location?.state,
          departmentIds: location?.state?.departmentId,
          // location?.state?.departmentList?.map(
          //   (obj) => obj?.departmentId
          // ),
          userIds: location?.state?.userList.map((obj) => obj?.userId),
          permissionUserIds: location?.state?.permissionUserList?.map(
            (obj) => obj?.userId
          ),
          goalStartDate: location?.state?.goalStartDate
            ? dayjs(location?.state?.goalStartDate)
            : dayjs(today),
          goalEndDate: location?.state?.goalEndDate
            ? dayjs(location?.state?.goalEndDate)
            : dayjs(today),

          goalKpiList: location?.state?.goalKpiList.map(
            ({
              kpiId,
              kpiMasterId,
              kpiName,
              kpiWeightage,
              kpiLevel,
              kpiTarget,
              kpiDescription,
              uomId,
              kpiTargetAchievement,
              kpiCategory,
              departmentList,
              departmentId,
              userList,
              permissionUserList,
              kpiType,
              kpiStartDate,
              kpiDueDate,
              priorityId,
            }) => ({
              kpiId,
              kpiMasterId,
              kpiName,
              kpiWeightage,
              kpiLevel,
              kpiTarget,
              kpiDescription,
              uomId,
              kpiTargetAchievement,
              kpiCategory,
              departmentIds: [departmentId],
              // departmentIds:
              //   departmentList.length > 0 &&
              //   departmentList?.map((obj) => obj?.departmentId) || [],
              userIds:
                (userList.length > 0 && userList?.map((obj) => obj?.userId)) ||
                [],
              permissionUserIds:
                (permissionUserList.length > 0 &&
                  permissionUserList?.map((obj) => obj?.userId)) ||
                [],
              kpiType,
              kpiStartDate: dayjs(kpiStartDate),
              kpiDueDate: dayjs(kpiDueDate),
              priorityId, // Add the new field here
            })
          ),
        }}
      >
        <Row gutter={[15, 4]}>
          <Col lg={6} sm={24}>
            <span>Goal Name</span>
            <Form.Item name="goalName">
              <Input placeholder="name" />
            </Form.Item>
          </Col>
          <Col lg={6} sm={24}>
            <span>Goal Start Date</span>
            <Form.Item name="goalStartDate">
              <DatePicker style={{ width: "100%" }} />
            </Form.Item>
          </Col>
          <Col lg={6} sm={24}>
            <span>Goal End Date</span>
            <Form.Item name="goalEndDate">
              <DatePicker style={{ width: "100%" }} />
            </Form.Item>
          </Col>
          <Col xs={24} md={14} lg={6}>
            <span>Goal Type</span>
            <Form.Item name="goalTypeId">
              <Select
                showSearch
                style={{
                  width: "100%",
                }}
                placeholder="Goal Type"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? "")
                    .toLowerCase()
                    .localeCompare((optionB?.label ?? "").toLowerCase())
                }
                allowClear
                options={
                  goalType &&
                  goalType?.map((obj) => {
                    const keys = Object.keys(obj);
                    const convertedObj = {
                      value: obj["goalTypeId"],
                      label: obj["goalTypeName"],
                    };

                    for (let i = 2; i < keys.length; i++) {
                      convertedObj[keys[i]] = obj[keys[i]];
                    }

                    return convertedObj;
                  })
                }
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={14} lg={6}>
            <span>Goal Status</span>
            <Form.Item name="goalStatusId">
              <Select
                showSearch
                style={{
                  width: "100%",
                }}
                placeholder="Search to Select"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? "")
                    .toLowerCase()
                    .localeCompare((optionB?.label ?? "").toLowerCase())
                }
                allowClear
                options={
                  goalstatus &&
                  goalstatus?.map((obj) => {
                    const keys = Object.keys(obj);
                    const convertedObj = {
                      value: obj["goalStatusId"],
                      label: obj["goalStatusName"],
                    };

                    for (let i = 2; i < keys.length; i++) {
                      convertedObj[keys[i]] = obj[keys[i]];
                    }

                    return convertedObj;
                  })
                }
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={14} lg={6}>
            <span>Goal Category</span>
            <Form.Item name="goalCategoryId">
              <Select
                showSearch
                style={{
                  width: "100%",
                }}
                placeholder="Goal Category"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? "")
                    .toLowerCase()
                    .localeCompare((optionB?.label ?? "").toLowerCase())
                }
                allowClear
                options={
                  categoryType &&
                  categoryType?.map((obj) => {
                    const keys = Object.keys(obj);
                    const convertedObj = {
                      value: obj["categoryId"],
                      label: obj["categoryName"],
                    };

                    for (let i = 2; i < keys.length; i++) {
                      convertedObj[keys[i]] = obj[keys[i]];
                    }

                    return convertedObj;
                  })
                }
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={14} lg={6}>
            <span>Goal Priority</span>

            <Form.Item name="goalPriorityId">
              <Select
                showSearch
                style={{
                  width: "100%",
                }}
                placeholder="Search to Select"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? "")
                    .toLowerCase()
                    .localeCompare((optionB?.label ?? "").toLowerCase())
                }
                allowClear
                options={
                  priority &&
                  priority?.map((obj) => {
                    const keys = Object.keys(obj);
                    const convertedObj = {
                      value: obj["priorityId"],
                      label: obj["priorityName"],
                    };

                    for (let i = 2; i < keys.length; i++) {
                      convertedObj[keys[i]] = obj[keys[i]];
                    }

                    return convertedObj;
                  })
                }
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={14} lg={6}>
            <span>Assign Department</span>
            <Form.Item name="departmentIds">
              <Select
                // mode="multiple"
                showSearch
                style={{
                  width: "100%",
                }}
                placeholder="Assign Department"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? "")
                    .toLowerCase()
                    .localeCompare((optionB?.label ?? "").toLowerCase())
                }
                allowClear
                options={
                  department &&
                  department?.map((obj) => {
                    const keys = Object.keys(obj);
                    const convertedObj = {
                      value: obj["departmentId"],
                      label: obj["departmentName"],
                    };

                    for (let i = 2; i < keys.length; i++) {
                      convertedObj[keys[i]] = obj[keys[i]];
                    }

                    return convertedObj;
                  })
                }
              />
            </Form.Item>
          </Col>

          <Col xs={24} md={14} lg={6}>
            <span>Assign User with Read Permission</span>
            <Form.Item name="userIds">
              <Select
                mode="multiple"
                showSearch
                style={{
                  width: "100%",
                }}
                placeholder="Assign User with Read Permission"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? "")
                    .toLowerCase()
                    .localeCompare((optionB?.label ?? "").toLowerCase())
                }
                allowClear
                onChange={handleReadUserChange}
                options={
                  userData &&
                  userData?.map((obj) => {
                    const keys = Object.keys(obj);
                    const convertedObj = {
                      value: obj["userId"],
                      label: `${obj["firstName"]} ${obj["lastName"]}`,
                      disabled: selectedWriteUsers.includes(obj["userId"]), // Disable option if it's selected in the first Select
                    };

                    for (let i = 2; i < keys.length; i++) {
                      convertedObj[keys[i]] = obj[keys[i]];
                    }
                    return convertedObj;
                  })
                }
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={14} lg={6}>
            <span>Assign User with Write Permission</span>
            <Form.Item name="permissionUserIds">
              <Select
                mode="multiple"
                showSearch
                style={{
                  width: "100%",
                }}
                placeholder="Assign User with Write Permission"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? "")
                    .toLowerCase()
                    .localeCompare((optionB?.label ?? "").toLowerCase())
                }
                allowClear
                onChange={handleWriteUserChange}
                options={
                  userData &&
                  userData?.map((obj) => {
                    const keys = Object.keys(obj);
                    const convertedObj = {
                      value: obj["userId"],
                      label: `${obj["firstName"]} ${obj["lastName"]}`,
                      disabled: selectedReadUsers.includes(obj["userId"]), // Disable option if it's selected in the first Select
                    };

                    for (let i = 2; i < keys.length; i++) {
                      convertedObj[keys[i]] = obj[keys[i]];
                    }
                    return convertedObj;
                  })
                }
              />
            </Form.Item>
          </Col>
          <Col lg={24} sm={24}>
            <span>Goal Description</span>

            <ReactQuill
              style={{ width: "100%", marginBottom: "20px" }}
              theme="snow"
              value={richValue}
              onChange={setRichValue}
            />
          </Col>
        </Row>

        <div className={styles.goalKpiBox}>
          <div style={{ width: "2000px" }}>
            <div style={{ display: "flex" }}>
              {/* <Row gutter={[12, 5]}> */}
              {kpiDetails.map((v, i) =>
                v !== "Target Achievement" ? (
                  <div
                    style={{
                      width: "120px",
                      margin: "12px",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {v}
                  </div>
                ) : (
                  <div
                    style={{
                      width: "160px",
                      margin: "12px",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {v}
                  </div>
                )
              )}
              {/* </Row> */}
            </div>
            <Form.List name="goalKpiList">
              {(fields, { add, remove }) => (
                <div>
                  {fields.map(({ key, name, fieldKey, ...restField }) => (
                    <div key={key}>
                      <div className={styles.InnerData}>
                        <div style={{ display: "none" }}>
                          <Form.Item
                            {...restField}
                            name={[name, "kpiId"]}
                            fieldKey={[fieldKey, "kpiId"]}
                            rules={[
                              { required: true, message: "KPI Id is required" },
                            ]}
                          >
                            <Input />
                          </Form.Item>
                        </div>

                        <div className={styles.marginData}>
                          <Form.Item
                            {...restField}
                            name={[name, "kpiMasterId"]}
                            fieldKey={[fieldKey, "kpiMasterId"]}
                            rules={[
                              { required: true, message: "KPI Id is required" },
                            ]}
                          >
                            <Select
                              disabled
                              showSearch
                              style={{ width: "120px" }}
                              placeholder="Search to Select"
                              optionFilterProp="children"
                              filterOption={(input, option) =>
                                (option?.label ?? "")
                                  .toLowerCase()
                                  .includes(input.toLowerCase())
                              }
                              filterSort={(optionA, optionB) =>
                                (optionA?.label ?? "")
                                  .toLowerCase()
                                  .localeCompare(
                                    (optionB?.label ?? "").toLowerCase()
                                  )
                              }
                              allowClear
                              options={
                                kpiList &&
                                kpiList?.map((obj) => {
                                  const keys = Object.keys(obj);
                                  const convertedObj = {
                                    value: obj["kpiMasterId"],
                                    label: obj["kpiName"],
                                  };

                                  for (let i = 2; i < keys.length; i++) {
                                    convertedObj[keys[i]] = obj[keys[i]];
                                  }

                                  return convertedObj;
                                })
                              }
                            />
                          </Form.Item>
                        </div>
                        <div className={styles.marginData}>
                          <Form.Item
                            {...restField}
                            name={[name, "kpiTarget"]}
                            fieldKey={[fieldKey, "kpiTarget"]}
                            rules={[
                              { required: true, message: "Target is required" },
                            ]}
                            style={{ width: "120px" }}
                          >
                            <Input placeholder="Target" />
                          </Form.Item>
                        </div>

                        <div className={styles.marginData}>
                          <Form.Item
                            {...restField}
                            name={[name, "kpiTargetAchievement"]}
                            fieldKey={[fieldKey, "kpiTargetAchievement"]}
                            rules={[
                              {
                                required: true,
                                message: "Achievement is required",
                              },
                            ]}
                          >
                            <Select
                              showSearch
                              style={{ width: "160px" }}
                              placeholder="Target Achievement"
                              optionFilterProp="children"
                              filterOption={(input, option) =>
                                (option?.label ?? "")
                                  .toLowerCase()
                                  .includes(input.toLowerCase())
                              }
                              filterSort={(optionA, optionB) =>
                                (optionA?.label ?? "")
                                  .toLowerCase()
                                  .localeCompare(
                                    (optionB?.label ?? "").toLowerCase()
                                  )
                              }
                              allowClear
                              options={[
                                {
                                  value: "Overachievement",
                                  label: "Overachievement",
                                },
                                {
                                  value: "Underachievement",
                                  label: "Underachievement",
                                },
                              ]}
                            />
                          </Form.Item>
                        </div>
                        <div className={styles.marginData}>
                          <Form.Item
                            {...restField}
                            name={[name, "uomId"]}
                            fieldKey={[fieldKey, "uomId"]}
                          >
                            <Select
                              showSearch
                              style={{ width: "120px" }}
                              placeholder="UOM"
                              optionFilterProp="children"
                              filterOption={(input, option) =>
                                (option?.label ?? "")
                                  .toLowerCase()
                                  .includes(input.toLowerCase())
                              }
                              filterSort={(optionA, optionB) =>
                                (optionA?.label ?? "")
                                  .toLowerCase()
                                  .localeCompare(
                                    (optionB?.label ?? "").toLowerCase()
                                  )
                              }
                              // getOptionLabel={(option) => option.label}
                              // labelInValue
                              // onChange={(e) => handle(e, "uomId")}
                              allowClear
                              options={
                                uom &&
                                uom?.map((obj) => {
                                  const keys = Object.keys(obj);
                                  const convertedObj = {
                                    value: obj["uomId"],
                                    label: obj["uomName"],
                                  };

                                  for (let i = 2; i < keys.length; i++) {
                                    convertedObj[keys[i]] = obj[keys[i]];
                                  }
                                  console.log(convertedObj, "hello uom");
                                  return convertedObj;
                                })
                              }
                            />
                          </Form.Item>
                        </div>
                        <div className={styles.marginData}>
                          <Form.Item
                            {...restField}
                            name={[name, "kpiWeightage"]}
                            fieldKey={[fieldKey, "kpiWeightage"]}
                            style={{ width: "120px" }}
                          >
                            <Input
                              // value={kpiData?.kpiWeightage}
                              name="kpiWeightage"
                              // onChange={handleChange}
                            />
                          </Form.Item>
                        </div>
                        <div className={styles.marginData}>
                          <Form.Item
                            {...restField}
                            name={[name, "kpiType"]}
                            fieldKey={[fieldKey, "kpiType"]}
                          >
                            <Select
                              showSearch
                              style={{ width: "120px" }}
                              placeholder="Kpi Type"
                              optionFilterProp="children"
                              filterOption={(input, option) =>
                                (option?.label ?? "")
                                  .toLowerCase()
                                  .includes(input.toLowerCase())
                              }
                              filterSort={(optionA, optionB) =>
                                (optionA?.label ?? "")
                                  .toLowerCase()
                                  .localeCompare(
                                    (optionB?.label ?? "").toLowerCase()
                                  )
                              }
                              // onChange={(e) => handleNew(e, "kpiType")}
                              allowClear
                              options={
                                goalType &&
                                goalType?.map((obj) => {
                                  const keys = Object.keys(obj);
                                  const convertedObj = {
                                    value: obj["goalTypeId"],
                                    label: obj["goalTypeName"],
                                  };

                                  for (let i = 2; i < keys.length; i++) {
                                    convertedObj[keys[i]] = obj[keys[i]];
                                  }
                                  console.log(convertedObj, "hello kpi");

                                  return convertedObj;
                                })
                              }
                            />
                          </Form.Item>
                        </div>
                        <div className={styles.marginData}>
                          <Form.Item
                            {...restField}
                            name={[name, "kpiCategory"]}
                            fieldKey={[fieldKey, "kpiCategory"]}
                          >
                            <Select
                              showSearch
                              style={{ width: "120px" }}
                              placeholder="Kpi Category"
                              optionFilterProp="children"
                              filterOption={(input, option) =>
                                (option?.label ?? "")
                                  .toLowerCase()
                                  .includes(input.toLowerCase())
                              }
                              filterSort={(optionA, optionB) =>
                                (optionA?.label ?? "")
                                  .toLowerCase()
                                  .localeCompare(
                                    (optionB?.label ?? "").toLowerCase()
                                  )
                              }
                              // onChange={(e) => handleNew(e, "kpiCategory")}
                              allowClear
                              options={
                                categoryType &&
                                categoryType?.map((obj) => {
                                  const keys = Object.keys(obj);
                                  const convertedObj = {
                                    value: obj["categoryId"],
                                    label: obj["categoryName"],
                                  };

                                  for (let i = 2; i < keys.length; i++) {
                                    convertedObj[keys[i]] = obj[keys[i]];
                                  }

                                  return convertedObj;
                                })
                              }
                            />
                          </Form.Item>
                        </div>
                        <div className={styles.marginData}>
                          <Form.Item
                            {...restField}
                            name={[name, "kpiLevel"]}
                            fieldKey={[fieldKey, "kpiLevel"]}
                          >
                            <Select
                              showSearch
                              style={{ width: "120px" }}
                              placeholder="Goal Type"
                              optionFilterProp="children"
                              filterOption={(input, option) =>
                                (option?.label ?? "")
                                  .toLowerCase()
                                  .includes(input.toLowerCase())
                              }
                              filterSort={(optionA, optionB) =>
                                (optionA?.label ?? "")
                                  .toLowerCase()
                                  .localeCompare(
                                    (optionB?.label ?? "").toLowerCase()
                                  )
                              }
                              // onChange={(e) => handleNew(e, "kpiLevel")}
                              allowClear
                              options={[
                                {
                                  value: 1,
                                  label: "Low",
                                },
                                {
                                  value: 2,
                                  label: "Medium",
                                },
                                {
                                  value: 3,
                                  label: "High",
                                },
                              ]}
                            />
                          </Form.Item>
                        </div>
                        <div className={styles.marginData}>
                          <Form.Item
                            {...restField}
                            name={[name, "kpiStartDate"]}
                            fieldKey={[fieldKey, "kpiStartDate"]}
                          >
                            <DatePicker
                              style={{ display: "block", width: "120px" }}
                              // onChange={(e) => handleChanges(e, "kpiStartDate")}
                            />
                          </Form.Item>
                        </div>
                        <div className={styles.marginData}>
                          <Form.Item
                            {...restField}
                            name={[name, "kpiDueDate"]}
                            fieldKey={[fieldKey, "kpiDueDate"]}
                          >
                            <DatePicker
                              style={{ display: "block", width: "120px" }}
                              // onChange={(e) => handleChanges(e, "kpiDueDate")}
                            />
                          </Form.Item>
                        </div>
                        <div className={styles.marginData}>
                          <Form.Item
                            {...restField}
                            name={[name, "departmentIds"]}
                            fieldKey={[fieldKey, "departmentIds"]}
                          >
                            <Select
                              // mode="multiple"
                              showSearch
                              style={{ width: "120px" }}
                              placeholder="Search to Select"
                              optionFilterProp="children"
                              filterOption={(input, option) =>
                                (option?.label ?? "")
                                  .toLowerCase()
                                  .includes(input.toLowerCase())
                              }
                              filterSort={(optionA, optionB) =>
                                (optionA?.label ?? "")
                                  .toLowerCase()
                                  .localeCompare(
                                    (optionB?.label ?? "").toLowerCase()
                                  )
                              }
                              allowClear
                              // onChange={(e) => handleAdd(e, "departmentIds")}
                              options={
                                department &&
                                department?.map((obj) => {
                                  const keys = Object.keys(obj);
                                  const convertedObj = {
                                    value: obj["departmentId"],
                                    label: obj["departmentName"],
                                  };

                                  for (let i = 2; i < keys.length; i++) {
                                    convertedObj[keys[i]] = obj[keys[i]];
                                  }

                                  return convertedObj;
                                })
                              }
                            />
                          </Form.Item>
                        </div>
                        <div className={styles.marginData}>
                          <Form.Item
                            {...restField}
                            name={[name, "userIds"]}
                            fieldKey={[fieldKey, "userIds"]}
                          >
                            <Select
                              mode="multiple"
                              showSearch
                              style={{ width: "120px" }}
                              placeholder="Search to Select"
                              optionFilterProp="children"
                              filterOption={(input, option) =>
                                (option?.label ?? "")
                                  .toLowerCase()
                                  .includes(input.toLowerCase())
                              }
                              filterSort={(optionA, optionB) =>
                                (optionA?.label ?? "")
                                  .toLowerCase()
                                  .localeCompare(
                                    (optionB?.label ?? "").toLowerCase()
                                  )
                              }
                              allowClear
                              // onChange={handleKpiReadUserChange}
                              onChange={(selectedUsers) =>
                                handleKpiReadUserChange(selectedUsers, key)
                              }
                              // onChange={(e) => handleAdd(e, "userIds")}
                              options={
                                userData &&
                                userData?.map((obj) => {
                                  const keys = Object.keys(obj);
                                  const convertedObj = {
                                    value: obj["userId"],
                                    label: `${obj["firstName"]} ${obj["lastName"]}`,
                                    // disabled: selectedKpiWriteUsers.includes(obj["userId"]) // Disable option if it's selected in the first Select
                                    disabled: selectedKpiWriteUsers[
                                      fieldKey
                                    ]?.includes(obj["userId"]), // Disable option if it's selected in the first Select at the same index
                                  };

                                  for (let i = 2; i < keys.length; i++) {
                                    convertedObj[keys[i]] = obj[keys[i]];
                                  }

                                  return convertedObj;
                                })
                              }
                            />
                          </Form.Item>
                        </div>
                        <div className={styles.marginData}>
                          <Form.Item
                            {...restField}
                            name={[name, "permissionUserIds"]}
                            fieldKey={[fieldKey, "permissionUserIds"]}
                          >
                            <Select
                              mode="multiple"
                              showSearch
                              style={{ width: "120px" }}
                              placeholder="Search to Select"
                              optionFilterProp="children"
                              filterOption={(input, option) =>
                                (option?.label ?? "")
                                  .toLowerCase()
                                  .includes(input.toLowerCase())
                              }
                              filterSort={(optionA, optionB) =>
                                (optionA?.label ?? "")
                                  .toLowerCase()
                                  .localeCompare(
                                    (optionB?.label ?? "").toLowerCase()
                                  )
                              }
                              allowClear
                              // onChange={handleKpiWriteUserChange}
                              onChange={(selectedUsers) =>
                                handleKpiWriteUserChange(selectedUsers, key)
                              }
                              // onChange={(e) => handleAdd(e, "userIds")}
                              options={
                                userData &&
                                userData?.map((obj) => {
                                  const keys = Object.keys(obj);
                                  const convertedObj = {
                                    value: obj["userId"],
                                    label: `${obj["firstName"]} ${obj["lastName"]}`,
                                    // disabled: selectedKpiReadUsers.includes(obj["userId"]) // Disable option if it's selected in the first Select
                                    disabled: selectedKpiReadUsers[
                                      fieldKey
                                    ]?.includes(obj["userId"]), // Disable option if it's selected in the first Select at the same index
                                  };

                                  for (let i = 2; i < keys.length; i++) {
                                    convertedObj[keys[i]] = obj[keys[i]];
                                  }

                                  return convertedObj;
                                })
                              }
                            />
                          </Form.Item>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </Form.List>
          </div>
        </div>

        <div style={{ margin: "20px", textAlign: "right" }}>
          <Button onClick={() => handleReject()} className={styles.btnReject}>
            Reject Goal
          </Button>
          <Button type="primary" htmlType="submit" className={styles.submit}>
            Approve Goal
          </Button>
        </div>
      </Form>

      {open && (
        <Modals
          open={open}
          // onOk={handleOk}
          handleCancel={() => {
            setOpen(false);
            setStateAccept(1);
          }}
        >
          <div className={styles.approval}>
            <img src={Approve} alt="Approve" />

            {stateAccept == 1 ? (
              <>
                <h1 className={styles.goalHead}>
                  Goal is approved Successfully
                </h1>
                <p className={styles.para}>The Goal is approved by you</p>
                <span className={`${styles.para} ${styles.font}`}>
                  A notification will alert the employee of the approval of the
                  goal!
                </span>
              </>
            ) : (
              <>
                <h1 className={`${styles.goalHead} ${styles.rejectHead}`}>
                  Goal is Rejected
                </h1>
                <p className={`${styles.para} ${styles.reject}`}>
                  Please write a reason for rejection.{" "}
                </p>
                <Form form={forms} onFinish={SubmitDataRejection}>
                  <span>Reason</span>
                  <Form.Item name="goalRejectionComment">
                    <TextArea />
                  </Form.Item>

                  <Button type="primary" htmlType="submit">
                    Submit
                  </Button>
                </Form>
              </>
            )}
          </div>
        </Modals>
      )}
    </div>
  );
}

export default AdminGoalApproval;

const kpiDetails = [
  "KPI Name",
  "KPI Target",
  "Target Achievement",
  "UOM",
  "kpiWeightage",
  "Kpi Type",
  "Kpi Category",
  "Goal Type",
  "Start Date",
  "End Date",
  "Department",
  "User Read",
  "User Write",
];

/**
 * 
 

<Col lg={2} sm={24} style={{ display: 'none' }}>
                            <Form.Item
                              {...restField}
                              name={[name, "kpiId"]}
                              fieldKey={[fieldKey, "kpiId"]}
                              rules={[
                                { required: true, message: "KPI Id is required" },
                              ]}
                            >
                              <Input />
                            </Form.Item>
                          </Col>


                          <Col lg={2}>
                            <Form.Item
                              {...restField}
                              name={[name, "kpiMasterId"]}
                              fieldKey={[fieldKey, "kpiMasterId"]}
                              rules={[
                                { required: true, message: "KPI Id is required" },
                              ]}
                            >
                              <Select
                                disabled
                                showSearch
                                style={{ width: "120px" }}
                                placeholder="Search to Select"
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                  (option?.label ?? "")
                                    .toLowerCase()
                                    .includes(input.toLowerCase())
                                }
                                filterSort={(optionA, optionB) =>
                                  (optionA?.label ?? "")
                                    .toLowerCase()
                                    .localeCompare(
                                      (optionB?.label ?? "").toLowerCase()
                                    )
                                }
                                allowClear
                                options={
                                  kpiList &&
                                  kpiList?.map((obj) => {
                                    const keys = Object.keys(obj);
                                    const convertedObj = {
                                      value: obj["kpiMasterId"],
                                      label: obj["kpiName"],
                                    };

                                    for (let i = 2; i < keys.length; i++) {
                                      convertedObj[keys[i]] = obj[keys[i]];
                                    }

                                    return convertedObj;
                                  })
                                }
                              />
                            </Form.Item>
                          </Col>
                          <Col lg={2}>
                            <Form.Item
                              {...restField}
                              name={[name, "kpiTarget"]}
                              fieldKey={[fieldKey, "kpiTarget"]}
                              rules={[
                                { required: true, message: "Target is required" },
                              ]}
                              style={{width: '100px'}}
                            >
                              <Input placeholder="Target" />
                            </Form.Item>
                          </Col>

                          <Col lg={2} >
                            <Form.Item
                              {...restField}
                              name={[name, "kpiTargetAchievement"]}
                              fieldKey={[fieldKey, "kpiTargetAchievement"]}
                              rules={[
                                {
                                  required: true,
                                  message: "Achievement is required",
                                },
                              ]}
                            >
                              <Select
                                showSearch
                                style={{ width: "100%" }}
                                placeholder="Target Achievement"
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                  (option?.label ?? "")
                                    .toLowerCase()
                                    .includes(input.toLowerCase())
                                }
                                filterSort={(optionA, optionB) =>
                                  (optionA?.label ?? "")
                                    .toLowerCase()
                                    .localeCompare(
                                      (optionB?.label ?? "").toLowerCase()
                                    )
                                }
                                allowClear
                                options={[
                                  {
                                    value: "Overachievement",
                                    label: "Overachievement",
                                  },
                                  {
                                    value: "Underachievement",
                                    label: "Underachievement",
                                  },
                                ]}
                              />
                            </Form.Item>
                          </Col>
                          <Col lg={2}>
                            <Form.Item
                              {...restField}
                              name={[name, "uomId"]}
                              fieldKey={[fieldKey, "uomId"]}
                            >
                              <Select
                                showSearch
                                style={{ width: "100%" }}
                                placeholder="UOM"
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                  (option?.label ?? "")
                                    .toLowerCase()
                                    .includes(input.toLowerCase())
                                }
                                filterSort={(optionA, optionB) =>
                                  (optionA?.label ?? "")
                                    .toLowerCase()
                                    .localeCompare(
                                      (optionB?.label ?? "").toLowerCase()
                                    )
                                }
                                getOptionLabel={(option) => option.label}
                                labelInValue
                                // onChange={(e) => handle(e, "uomId")}
                                allowClear
                                options={
                                  uom &&
                                  uom?.map((obj) => {
                                    const keys = Object.keys(obj);
                                    const convertedObj = {
                                      value: obj["uomId"],
                                      label: obj["uomName"],
                                    };

                                    for (let i = 2; i < keys.length; i++) {
                                      convertedObj[keys[i]] = obj[keys[i]];
                                    }

                                    return convertedObj;
                                  })
                                }
                              />
                            </Form.Item>
                          </Col>
                          <Col lg={2}>
                            <Form.Item
                              {...restField}
                              name={[name, "kpiWeightage"]}
                              fieldKey={[fieldKey, "kpiWeightage"]}
                              style={{width: '100%'}}
                            >
                              <Input
                                // value={kpiData?.kpiWeightage}
                                name="kpiWeightage"
                              // onChange={handleChange}
                              />
                            </Form.Item>
                          </Col>
                          <Col lg={2}>
                            <Form.Item
                              {...restField}
                              name={[name, "kpiType"]}
                              fieldKey={[fieldKey, "kpiType"]}
                            >
                              <Select
                                showSearch
                                style={{ width: "100%" }}
                                placeholder="Kpi Type"
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                  (option?.label ?? "")
                                    .toLowerCase()
                                    .includes(input.toLowerCase())
                                }
                                filterSort={(optionA, optionB) =>
                                  (optionA?.label ?? "")
                                    .toLowerCase()
                                    .localeCompare(
                                      (optionB?.label ?? "").toLowerCase()
                                    )
                                }
                                // onChange={(e) => handleNew(e, "kpiType")}
                                allowClear
                                options={
                                  goalType &&
                                  goalType?.map((obj) => {
                                    const keys = Object.keys(obj);
                                    const convertedObj = {
                                      value: obj["goalTypeId"],
                                      label: obj["goalTypeName"],
                                    };

                                    for (let i = 2; i < keys.length; i++) {
                                      convertedObj[keys[i]] = obj[keys[i]];
                                    }

                                    return convertedObj;
                                  })
                                }
                              />
                            </Form.Item>
                          </Col>
                          <Col lg={2}>
                            <Form.Item
                              {...restField}
                              name={[name, "kpiCategory"]}
                              fieldKey={[fieldKey, "kpiCategory"]}
                            >
                              <Select
                                showSearch
                                style={{ width: "100%" }}
                                placeholder="Kpi Category"
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                  (option?.label ?? "")
                                    .toLowerCase()
                                    .includes(input.toLowerCase())
                                }
                                filterSort={(optionA, optionB) =>
                                  (optionA?.label ?? "")
                                    .toLowerCase()
                                    .localeCompare(
                                      (optionB?.label ?? "").toLowerCase()
                                    )
                                }
                                // onChange={(e) => handleNew(e, "kpiCategory")}
                                allowClear
                                options={
                                  categoryType &&
                                  categoryType?.map((obj) => {
                                    const keys = Object.keys(obj);
                                    const convertedObj = {
                                      value: obj["categoryId"],
                                      label: obj["categoryName"],
                                    };

                                    for (let i = 2; i < keys.length; i++) {
                                      convertedObj[keys[i]] = obj[keys[i]];
                                    }

                                    return convertedObj;
                                  })
                                }
                              />
                            </Form.Item>
                          </Col>
                          <Col lg={2}>
                            <Form.Item
                              {...restField}
                              name={[name, "kpiLevel"]}
                              fieldKey={[fieldKey, "kpiLevel"]}
                            >
                              <Select
                                showSearch
                                style={{ width: "100%" }}
                                placeholder="Goal Type"
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                  (option?.label ?? "")
                                    .toLowerCase()
                                    .includes(input.toLowerCase())
                                }
                                filterSort={(optionA, optionB) =>
                                  (optionA?.label ?? "")
                                    .toLowerCase()
                                    .localeCompare(
                                      (optionB?.label ?? "").toLowerCase()
                                    )
                                }
                                // onChange={(e) => handleNew(e, "kpiLevel")}
                                allowClear
                                options={[
                                  {
                                    value: 1,
                                    label: "Low",
                                  },
                                  {
                                    value: 2,
                                    label: "Medium",
                                  },
                                  {
                                    value: 3,
                                    label: "High",
                                  },
                                ]}
                              />
                            </Form.Item>
                          </Col>
                          <Col lg={2}>
                            <Form.Item
                              {...restField}
                              name={[name, "kpiStartDate"]}
                              fieldKey={[fieldKey, "kpiStartDate"]}
                            >
                              <DatePicker
                                style={{ display: "block", width: "100%" }}
                              // onChange={(e) => handleChanges(e, "kpiStartDate")}
                              />
                            </Form.Item>
                          </Col>
                          <Col lg={2}>
                            <Form.Item
                              {...restField}
                              name={[name, "kpiDueDate"]}
                              fieldKey={[fieldKey, "kpiDueDate"]}
                            >
                              <DatePicker
                                style={{ display: "block", width: "100%" }}
                              // onChange={(e) => handleChanges(e, "kpiDueDate")}
                              />
                            </Form.Item>
                          </Col>
                          <Col lg={2}>
                            <Form.Item
                              {...restField}
                              name={[name, "departmentIds"]}
                              fieldKey={[fieldKey, "departmentIds"]}
                            >
                              <Select
                                mode="multiple"
                                showSearch
                                style={{ width: "100%" }}
                                placeholder="Search to Select"
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                  (option?.label ?? "")
                                    .toLowerCase()
                                    .includes(input.toLowerCase())
                                }
                                filterSort={(optionA, optionB) =>
                                  (optionA?.label ?? "")
                                    .toLowerCase()
                                    .localeCompare(
                                      (optionB?.label ?? "").toLowerCase()
                                    )
                                }
                                allowClear
                                // onChange={(e) => handleAdd(e, "departmentIds")}
                                options={
                                  department &&
                                  department?.map((obj) => {
                                    const keys = Object.keys(obj);
                                    const convertedObj = {
                                      value: obj["departmentId"],
                                      label: obj["departmentName"],
                                    };

                                    for (let i = 2; i < keys.length; i++) {
                                      convertedObj[keys[i]] = obj[keys[i]];
                                    }

                                    return convertedObj;
                                  })
                                }
                              />
                            </Form.Item>
                          </Col>
                          <Col lg={2}>
                            <Form.Item
                              {...restField}
                              name={[name, "userIds"]}
                              fieldKey={[fieldKey, "userIds"]}
                            >
                              <Select
                                mode="multiple"
                                showSearch
                                style={{ width: "100%" }}
                                placeholder="Search to Select"
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                  (option?.label ?? "")
                                    .toLowerCase()
                                    .includes(input.toLowerCase())
                                }
                                filterSort={(optionA, optionB) =>
                                  (optionA?.label ?? "")
                                    .toLowerCase()
                                    .localeCompare(
                                      (optionB?.label ?? "").toLowerCase()
                                    )
                                }
                                allowClear
                                // onChange={(e) => handleAdd(e, "userIds")}
                                options={
                                  userData &&
                                  userData?.map((obj) => {
                                    const keys = Object.keys(obj);
                                    const convertedObj = {
                                      value: obj["userId"],
                                      label: `${obj["firstName"]} ${obj["lastName"]}`,
                                    };

                                    for (let i = 2; i < keys.length; i++) {
                                      convertedObj[keys[i]] = obj[keys[i]];
                                    }

                                    return convertedObj;
                                  })
                                }
                              />
                            </Form.Item>
                          </Col>
 */
