import React from 'react'
import KPI from '../../Assets/Master/kpi.png';
import Department from '../../Assets/Master/dept.png';
import User from '../../Assets/Master/user.png';
import Category from '../../Assets/Master/category.png';
import Status from '../../Assets/Master/Status.png';
import UOM from '../../Assets/Master/UOM.png';
import Role from '../../Assets/Master/Role.png';
import Priority from '../../Assets/Master/priority.png';
import styles from './index.module.css';
import { useNavigate } from 'react-router-dom';

function Master() {
    let navigate = useNavigate();

    const handleClick = (val) => {
        navigate(val.path)
    }
  return (
    <div className={styles.box}>
        <h1 className={styles.master}>Masters</h1>
        <div className={styles.masterBox}>
        {
            datas && datas.map((val, index) => (
                <div className={styles.dataBox} onClick={() => handleClick(val)}>
                    <img src={val.icons} alt={val.title} className={styles.images}/>
                    <p className={styles.para}>{val.title}</p>
                </div>
            ))
        }
        </div>
        
    </div>
  )
}

export default Master

const datas = [
    {
        id: 1,
        title: 'KPIs',
        path: 'kpi',
        icons: KPI,
    },
    {
        id: 2,
        title: 'Departments',
        path: 'dept',
        icons: Department,
    },{
        id: 3,
        title: 'Users',
        path: 'user',
        icons: User,
    },{
        id: 4,
        title: 'Category',
        path: 'category',
        icons: Category,
    },{
        id: 5,
        title: 'Goal Status',
        path: 'goalstatus',
        icons: Department,
    },
    {
        id: 9,
        title: 'Goal Type',
        path: 'goaltype',
        icons: Department,
    },{
        id: 6,
        title: 'Priority',
        path: 'priority',
        icons: Priority,
    },{
        id: 7,
        title: 'Role',
        path: 'role',
        icons: Role,
    },{
        id: 8,
        title: 'UOM',
        path: 'uom',
        icons: UOM,
    }
    ,{
        id: 10,
        title: 'Status',
        path: 'status',
        icons: Status,
    }
]

