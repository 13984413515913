import { Col, Form, Input, Row,Button, DatePicker } from 'antd';
import React, { useContext } from 'react'
import dayjs from "dayjs";
import { UserCredsContext } from '../../Utils/UserCredsContext';
import { api_call_token } from '../../Utils/Network';
import { alertMessage } from "../../Utils/helperFunction";

function AchivementEditFrom({ data, setOpen = () => {}, getGoals}) {
  
  const today = dayjs();
  const [form] = Form.useForm();
  const {userState} = useContext(UserCredsContext)
 
  console.log(data,userState, "field");
  const handleData = (vals) => {
    // console.log(vals, "handleData");

    const newData = {
      ...vals,
      userId : data?.userId,
      achivementDate : vals?.achivementDate ? dayjs(vals?.achivementDate).format("YYYY-MM-DD") : null,
      goalKPIAchievementsId : data?.goalKPIAchievementsId,
    }

    console.log(newData, "handleData");
    if(data?.goalKPIAchievementsId )
    api_call_token
    .patch(`goalkpiachievement/${data?.goalKPIAchievementsId}` , newData )
    .then((res) =>{
      if(res?.status === 200 || res?.status === 201 || res?.status === 204){
        alertMessage("Goal KPI Achieved updated ");
        setOpen(false);
        getGoals()
      }
    })
    .catch((err) =>{
      console.log(err);
    })


  }
  return (
    <>
      {/* <div>AchivementEditFrom</div> */}
      <div>
        <h1>Goal Achivement</h1>
        <Form form={form} 
        // initialValues={data}
        initialValues={{...data,
          achivementDate: data?.achivementDate ? dayjs(data?.achivementDate) : dayjs(today) ,
        }} 
        onFinish={handleData}>
          <Row>
            <Col xs={24} md={24} lg={24}>
              <span>Achivement</span>
              <Form.Item name="achivement">
                <Input placeholder="Achivement" />
              </Form.Item>
            </Col>
            {/* <Col xs={24} md={24} lg={24}>
              <span>UOM Description</span>
              <Form.Item name="uomDescription">
                <Input placeholder="UOM Description" />
              </Form.Item>
            </Col> */}
            <Col xs={24} md={24} lg={24}>
              <span>Reason Achievement</span>
              <Form.Item name="reasonAchievement">
                <Input placeholder="Reason Achievement" />
              </Form.Item>
            </Col>
            <Col xs={24} md={24} lg={24}>
            <span>Goal Start Date</span>
            <Form.Item name="achivementDate">
              <DatePicker style={{ width: "100%" }} />
            </Form.Item>
            </Col>
            <Col>
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </Col>
          </Row>
        </Form>
      </div>
    </>
  )
}

export default AchivementEditFrom