import React, { useState, useContext } from "react";
import { Button, Dropdown, Layout, Menu } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { getLocalData } from "../../Utils/helperFunction";
import Dashboard from "../../Pages/Dashboard/Dashboard";
import Home from "../../Assets/ICONS/house.png";
import MasterIcon from "../../Assets/ICONS/master.png";
import Bull from "../../Assets/ICONS/bull.png";
import Reports from "../../Assets/ICONS/report.png";
import Achieve from "../../Assets/ICONS/Achievement.png";
import Master from "../../Pages/Master/Master";
import Logo from "../../Assets/ICONS/Logo.png";
import PIPImg from "../../Assets/ICONS/pip.png";
import PIPAchievement from "../../Assets/ICONS/pipachieve.png";
import styles from "./index.module.css";
import { UserCredsContext } from "../../Utils/UserCredsContext";
import Goals from "../../Pages/Goals/Goals";
import MainGoals from "../../Pages/Goals/AdminGoals/MainGoals";
import Achievement from "../../Pages/Achievement/Achievement";
import GoalPIP from "../../Pages/Goals/AdminGoalPip/GoalPIP";
import PIP from "../../Pages/PIP/PIP";

const { Header, Content, Footer, Sider } = Layout;
const { SubMenu } = Menu;

const allRoutes = [
  {
    key: 1,
    path: "/dashboard",
    label: "Dashboard",
    component: Dashboard,
    icon: Home,
    child: true,
    id: 1,
  },
  {
    key: 8,
    path: "/achievement",
    label: "Achievement",
    component: Achievement,
    child: true,
    icon: Achieve,
    id: 4,
  },
  {
    key: 2,
    path: "/goals",
    label: "Goals & KPI",
    child: true,
    component: Goals,
    icon: Bull,
    id: 2,
    children: [
      {
        key: 21,
        path: "/goals",
        label: "My Goal",
        component: Goals,
      },
      // {
      //   key: 22,
      //   path: "/mypip",
      //   label: "My PIP",
      //   component: PIP,
      // },
      {
        key: 23,
        path: "/admingoals",
        label: "Goals Approval",
        component: MainGoals,
      },
      // {
      //   key: 24,
      //   path: "/adminpip",
      //   label: "PIP Approval",
      //   component: GoalPIP,
      // },
      
    ],
  },
  {
    key: 4,
    path: "/mypip",
    label: "PIP",
    child: true,
    component: PIP,
    icon: PIPImg,
    id: 6,
    children: [
      {
        key: 32,
        path: "/mypip",
        label: "My PIP",
        component: PIP,
      },
      {
        key: 34,
        path: "/adminpip",
        label: "PIP Approval",
        component: GoalPIP,
      },
      
    ],
  },
  {
    key: 3,
    path: "/master",
    label: "Master",
    child: true,
    component: Master,
    icon: MasterIcon,
    id: 3,
  },
  {
    key: 10,
    path: "/user-reports",
    label: "User Achievement",
    child: true,
    // component: Container,
    icon: Reports,
    id: 8,
  },
  {
    key: 5,
    path: "/reports",
    label: "Reports",
    child: true,
    // component: Container,
    icon: Reports,
    id: 5,
  },
  {
    key: 9,
    path: "/pipachievement",
    label: "PIP Achievement",
    child: true,
    // component: Container,
    icon: PIPAchievement,
    id: 7,
  },
  {
    key: 11,
    path: "/achievementupdate",
    label: "Achievement Edit",
    child: true,
    // component: Container,
    icon: Reports,
    id: 9,
  },
  {
    key: 12,
    path: "/achievementpip",
    label: "Edit PIP Achievement",
    child: true,
    // component: Container,
    icon: Reports,
    id: 10,
  },
  {
    key: 13,
    path: "/pipreports",
    label: "PIP Reports",
    child: true,
    // component: Container,
    icon: Reports,
    id: 11,
  },
];

function Sidenav({ visible, setVisible = () => { } }) {
  const navigate = useNavigate();
  const { logout, menu_state } = useContext(UserCredsContext);
  const [collapsed, setCollapsed] = useState(true);
  let data = getLocalData("dmc_user");

  const items = [
    {
      key: "1",
      label: <div onClick={() => handleLogout()}>Logout</div>,
    },
  ];

  const handleLogout = () => {
    logout();
    // navigate('/login')
  };

  const handlClick = (m) => {
    navigate(m?.path);
    const randomWidth = Math.floor(Math.random() * (768 - 320 + 1)) + 320;

    if (window.innerWidth >= 320 && window.innerWidth <= 768) {
      setVisible(!visible);
    }
  };

  console.log(menu_state, "menu_itemmenu_item")

  return (
    <div className={styles.positionSide}>
      {visible && (
        <div className={styles.sideHead}>
          <Sider
            theme="light"
            style={{
              overflow: "auto",
              height: "100vh",
              position: "fixed",
              zIndex: 999,
              left: 0,
            }}
            className={styles.asideBar}
          >
            <div className={styles.logoDiv}>
              <CloseOutlined
                className={styles.close}
                onClick={() => setVisible(!visible)}
              />
              <img src={Logo} alt="logo" />
            </div>
            <Menu
              theme="light"
              mode="inline"
              defaultSelectedKeys={["/dashboard"]}
              className={styles.lists}
            >
              {menu_state?.map((m,i) => {
                const RouteModule = allRoutes.find((a) => a.id === m.id);
                if(RouteModule.child !== m.child){
                  if(RouteModule?.children){
                    delete RouteModule?.children
                  }
                }
                console.log(RouteModule, i)
                if (RouteModule.children) {
                  return (
                    <SubMenu
                      key={RouteModule.key}
                      style={{ marginBottom: '16px'}}
                      title={
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <img src={RouteModule.icon} alt={RouteModule.icon} style={{ width: "23px" }} />
                          <p className={styles.sideData}>{RouteModule.label}</p>
                        </div>
                      }
                    >
                      {RouteModule.children.map((child) => (
                        <Menu.Item
                          key={child.key}
                          onClick={() => handlClick(child)}
                          className={styles.menu_item}
                        >
                          {child.label}
                        </Menu.Item>
                      ))}
                    </SubMenu>
                  );
                } else {
                  return (
                    <Menu.Item
                      key={RouteModule.key}
                      onClick={() => handlClick(RouteModule)}
                      icon={
                        <img
                          src={RouteModule.icon}
                          alt={RouteModule.icon}
                          style={{ width: "23px" }}
                        />
                      }
                      className={`${styles.menu_item} ${styles.mainMenuItem}`}
                    >
                      {RouteModule.label}
                    </Menu.Item>
                  );
                }
              })}
            </Menu>

            <div className={styles.users}>
              <Dropdown
                menu={{
                  items,
                }}
                placement="topRight"
                arrow
              >
                <Button style={{ width: "100%", zIndex: 999 }}>
                  {data?.user_state?.firstName}
                </Button>
              </Dropdown>
            </div>
          </Sider>
        </div>
      )}
    </div>
  );
}

export default Sidenav;


/**
 * 
 

const allRoutes = [
  {
    id: 1,
    label: "Dashboard",
    isChild: 0,
  },
  {
    id: 2,
    label: "Goals & KPI",
    isChild: 1,
  },  
  {
    id: 3,
    label: "Master",
    isChild: 0,
  },
  {
    key: 4,
    label: "Achievement",
    isChild: 0,
  },
  {
    key: 5,
    label: "Reports",
    isChild: 0
  },
];
 */