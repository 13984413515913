import React, { useContext, useEffect } from "react";
// import {
//   Route,
//   Router,
//   createBrowserRouter,
//   createRoutesFromElements,
//   RouterProvider,
//   useNavigate,
//   Navigate,
// } from "react-router-dom";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";

import {
  AchievementRoute,
  AdminGoalRoute,
  CategoryRoute,
  DepartmentRoute,
  GoalRoute,
  GoalStatusRoute,
  GoalTypeRoute,
  KPIRoute,
  MasterRoute,
  PriorityRoute,
  ReportsRoute,
  RoleRoute,
  StatusRoute,
  UOMRoute,
  UserRoute,
  ForgetRoute,
  LoginRouter,
  AdminPIPRoute,
  PIPRoute,
  PIPAchievementRoute,
  UserAchievementReportsRoute,
  UserAchievementEditRoute,
  UserAchievementPIPEditRoute,
  PIPReportsRoute,
} from "../Layout/InnerRoute";
import RouterLayout from "../Layout/RouterLayout";
import Dashboard from "../Pages/Dashboard/Dashboard";
import DepartmentList from "../Pages/Master/Department/DepartmentList/DepartmentList";
import CreateKPI from "../Pages/Master/KPI/CreateKPI/CreateKPI";
import KpiList from "../Pages/Master/KPI/KpiList/KpiList";
import Master from "../Pages/Master/Master";
import UserList from "../Pages/Master/User/UserList/UserList";
import CategoryList from "../Pages/Master/Category/CategoryList.js/CategoryList";
import GoalStatus from "../Pages/Master/GoalStatus/CategoryList.js/GoalStatus";
import GoalTypeList from "../Pages/Master/GoalType/GoalTypeList/GoalTypeList";
import PriorityList from "../Pages/Master/Priority/PriorityList/PriorityList";
import RoleList from "../Pages/Master/Role/RoleList/RoleList";
import UomList from "../Pages/Master/UOM/RoleList/UomList";
import StatusList from "../Pages/Master/Status/RoleList/StatusList";
import Notfound from "../Pages/Notfound/Notfound";
import Goals from "../Pages/Goals/Goals";
import MainGoals from "../Pages/Goals/AdminGoals/MainGoals";
import GoalCreation from "../Pages/Goals/GoalCreation/GoalCreation";
import AdminGoalApproval from "../Pages/Goals/AdminGoalApproval/AdminGoalApproval";
import Achievement from "../Pages/Achievement/Achievement";
import UserForm from "../Pages/Master/User/UserForm/UserForm";
import Reports from "../Pages/Reports/Reports";
import Forget from "../Auth/Forget/Forget";
import Login from "../Auth/Login/Login";
import { UserCredsContext } from "../Utils/UserCredsContext";
import Reset from "../Auth/Forget/Reset";
import { getLocalData } from "../Utils/helperFunction";
import Protected from "./Protected";
import GoalPIP from "../Pages/Goals/AdminGoalPip/GoalPIP";
import AdminGoalApprovalpip from "../Pages/Goals/AdminGoalPip/AdminGoalApprovalPip/AdminGoalApprovalpip";
import PIP from "../Pages/PIP/PIP";
import GoalPIPCreation from "../Pages/PIP/GoalPIPCreation/GoalPIPCreation";
import PIPAchievement from "../Pages/PIPAchievement/PIPAchievement";
import AchievementReports from "../Pages/AchievementReports/AchievementReports";
import AchivementEdit from "../Pages/AchivementEdit/AchivementEdit";
import PIPAchivementEdit from "../Pages/PIPAchivementEdit/PIPAchivementEdit";
import PIPReports from "../Pages/PIPReports/PIPReports";


function Mainrouter() {
  return (
    <Router>
      <Routes>
        <Route element={< Protected />}>
          <Route path="/" element={<RouterLayout />}>
            <Route path="dashboard" element={<Dashboard />} />
            <Route path="master" element={<MasterRoute />}>
              <Route index element={<Master />} />
              <Route path="kpi" element={<KPIRoute />}>
                <Route index element={<KpiList />} />
                <Route path="create" element={<CreateKPI />} />
              </Route>
              <Route path="dept" element={<DepartmentRoute />}>
                <Route index element={<DepartmentList />} />
              </Route>
              <Route path="user" element={<UserRoute />}>
                <Route index element={<UserList />} />
                <Route path="create" element={<UserForm />} />
              </Route>
              <Route path="category" element={<CategoryRoute />}>
                <Route index element={<CategoryList />} />
              </Route>
              <Route path="goalstatus" element={<GoalStatusRoute />}>
                <Route index element={<GoalStatus />} />
              </Route>
              <Route path="goaltype" element={<GoalTypeRoute />}>
                <Route index element={<GoalTypeList />} />
              </Route>
              <Route path="priority" element={<PriorityRoute />}>
                <Route index element={<PriorityList />} />
              </Route>
              <Route path="role" element={<RoleRoute />}>
                <Route index element={<RoleList />} />
              </Route>
              <Route path="uom" element={<UOMRoute />}>
                <Route index element={<UomList />} />
              </Route>
              <Route path="status" element={<StatusRoute />}>
                <Route index element={<StatusList />} />
              </Route>
            </Route>
            <Route path="achievement" element={<AchievementRoute />}>
              <Route index element={<Achievement />} />
              {/* <Route path='creategoal' element={<GoalCreation />} /> */}
            </Route>
            <Route path="pipachievement" element={<PIPAchievementRoute />}>
              <Route index element={<PIPAchievement />} />
              {/* <Route path='creategoal' element={<GoalCreation />} /> */}
            </Route>
            <Route path="goals" element={<GoalRoute />}>
              <Route index element={<Goals />} />
              <Route path="creategoal" element={<GoalCreation />} />
            </Route>
            <Route path="mypip" element={<PIPRoute />}>
              <Route index element={<PIP />} />
              <Route path="creategoalpip" element={<GoalPIPCreation />} />
            </Route>
            <Route path="admingoals" element={<AdminGoalRoute />}>
              <Route index element={<MainGoals />} />
              <Route path="approvegoal" element={<AdminGoalApproval />} />
            </Route>
            <Route path="adminpip" element={<AdminPIPRoute />}>
              <Route index element={<GoalPIP />} />
              <Route path="approvegoalpip" element={<AdminGoalApprovalpip />} />
            </Route>
            <Route path="reports" element={<ReportsRoute />}>
              <Route index element={<Reports />} />
            </Route>
            <Route path="user-reports" element={<UserAchievementReportsRoute />}>
              <Route index element={<AchievementReports />} />
            </Route>
            <Route path="achievementupdate" element={<UserAchievementEditRoute />}>
              <Route index element={<AchivementEdit />} />
            </Route>
            <Route path="achievementpip" element={<UserAchievementPIPEditRoute />}>
              <Route index element={<PIPAchivementEdit />} />
            </Route>
            <Route path="pipreports" element={<PIPReportsRoute />}>
              <Route index element={<PIPReports />} />
            </Route>

            <Route path="forgetpassword" element={<ForgetRoute />}>
              <Route index element={<Forget />} />
            </Route>
            <Route path="*" element={<Notfound />} />
          </Route>
        </Route>
        <Route path="/" element={<LoginRouter />}>
          <Route path="login" element={<Login />} />
          <Route path="forgot" element={<Forget />} />
          <Route path="forgotPassword/:token" element={<Reset />} />
        </Route>
      </Routes>
    </Router>
  );
}

export default Mainrouter;

/**
 * 
 
 */
