import React, { useState, useEffect } from "react";
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  LabelList,
  ResponsiveContainer,
} from "recharts";
import { api_call_token, base_url } from "../../Utils/Network";

// const data = [
//   {
//     name: "Jan 2023",
//     hp: 4000,
//     lp: 2400,
//   },
//   {
//     name: "Feb 2023",
//     hp: 3000,
//     lp: 1398,
//   },
//   {
//     name: "Mar 2023",
//     hp: 2000,
//     lp: 8,
//   },
//   {
//     name: "Apr 2023",
//     hp: 2780,
//     lp: 3908,
//   },
//   {
//     name: "May 2023",
//     hp: 18,
//     lp: 4800,
//   },
//   {
//     name: "Jun 2023",
//     hp: 2390,
//     lp: 3800,
//   },
//   {
//     name: "Jul 2023",
//     hp: 3490,
//     lp: 4300,
//   },
// ];

const Graphs = ({filterObj}) => {
  const [data, setData] = useState([]);

  console.log(filterObj, "filterObj");

  useEffect(() => {
    getGraphData();
  }, [filterObj]);

  const getGraphData = (paramObj = { ...filterObj }) => {
    api_call_token
      .get(`graph`, { params: { ...paramObj } })
      .then((response) => {
        if (response?.data) {
          setData(response?.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <BarChart
      width={window.innerWidth > 1300 ? 650 : 400}
      height={400}
      data={data}
      margin={{
        top: 5,
        right: 30,
        left: 20,
        bottom: 5,
      }}
    >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="name" />
      <YAxis />
      {/* <Tooltip /> */}
      <Legend />
      <Bar
        dataKey="actual"
        fill="#1479FF"
        minPointSize={4}
        barSize={window.innerWidth > 1300 ? 40 : 30}
        radius={[8, 8, 0, 0]}
      >
        {/* <LabelList dataKey="name" /> */}
      </Bar>
      <Bar
        dataKey="target"
        fill="#D5E6FF"
        barSize={window.innerWidth > 1300 ? 40 : 30}
        minPointSize={8}
        radius={[8, 8, 0, 0]}
      />
    </BarChart>
  );
};

export default Graphs;
