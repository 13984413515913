import React, { useContext, useEffect, useState } from "react";
import { api_call_token } from "../../../../Utils/Network";
import { Button, Form, Space, Table } from "antd";
import { ArrowLeftOutlined, DeleteFilled, EditFilled } from "@ant-design/icons";
import styles from "./index.module.css";
import { useNavigate } from "react-router-dom";
import Modals from "../../../../Component/Modals/Modals";
import RoleForm from "./StatusForm";
import { isObject } from "../../../../Utils/helperFunction";
import UomForm from "./StatusForm";
import StatusForm from "./StatusForm";
import { UserCredsContext } from "../../../../Utils/UserCredsContext";

function StatusList() {
  const [categoryList, setCategoryList] = useState();
  const [open, setOpen] = useState(false);
  const [field, setFields] = useState(null);
  const [vals, setVals] = useState(null);
  const [form] = Form.useForm();
  const { logout } = useContext(UserCredsContext);
  let navigate = useNavigate();

  useEffect(() => {
    getCategoryList();
  }, []);

  const getCategoryList = () => {
    api_call_token
      .get(`status`)
      .then((res) => {
        if (res.status == 200 || res.status == 201 || res.status == 204) {
          setCategoryList(res.data.data);
        } else if (res.status == 401) {
          logout();
        }
      })
      .catch((err) => console.log(err));
  };

  const columns = [
    {
      title: "Status ID",
      dataIndex: "statusId",
      key: "statusId",
    },
    {
      title: "Name",
      dataIndex: "statusName",
      key: "statusName",
    },
    {
      title: "Category",
      dataIndex: "statusCategory",
      key: "statusCategory",
    },
    {
      title: "Status",
      key: "active",
      dataIndex: "active",
      render: (_) => {
        return (
          <div
            className={`${styles.container} ${_ ? styles.green : styles.orange
              }`}
          >
            {_ ? "Active" : "Inactive"}
          </div>
        );
      },
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <DeleteFilled />
          <EditFilled onClick={() => handleOpenModal(record)} />
        </Space>
      ),
    },
  ];

  const handleClick = () => {
    navigate("/master");
  };

  const handleform = () => {
    setFields({});
    setOpen(false);
    setVals(0);
  };

  const openModel = () => {
    setFields({active: 1});
    setVals(1);
    setOpen(true);
  };

  const handleOpenModal = (record) => {
    if (record !== undefined && record !== null && isObject(record)) {
      setFields(record);
    }
    setVals(1);
    setOpen(true);
  };


  return (
    <div className={styles.boxKPI}>
      <div className={styles.headerData}>
        <div className={styles.kpiText}>
          <ArrowLeftOutlined
            style={{ marginRight: "20px" }}
            onClick={handleClick}
          />
          Status Master
        </div>
        <div>
          <Button type="primary" className={styles.btn} onClick={() => openModel()}>
            + NEW Status
          </Button>
        </div>
      </div>
      <div className={styles.midContainer}>
        {/* Search and dropdown components... */}
      </div>
      <div>
        <Table columns={columns} dataSource={categoryList} />
      </div>

      {open && (
        <Modals open={open} handleCancel={() => handleform()}>
          {vals == 1 && (
            <StatusForm
              field={field}
              key={1}
              form={form}
              getCategoryList={getCategoryList}
              setOpen={setOpen}
            />
          )}
        </Modals>
      )}
    </div>
  );
}

export default StatusList;
