import React from "react";
import { Button, Tabs } from "antd";
import GoalList from "./GoalList/GoalList";
import styles from "./index.module.css";
import { useNavigate } from "react-router-dom";
import PIPList from "./PIPList/PIPList";

const { TabPane } = Tabs;

const PIP = () => {
    let navigate = useNavigate();

  const onChange = (key) => {
    console.log(key);
  };

  const tabsContent = [
    {
      key: "1",
      label: "Goals",
      children: <GoalList />,
    },
    {
      key: "2",
      label: "PIP",
      children: <PIPList />,
    },
  ];

  const handleClick = () => {
      navigate(`creategoal`)
  }

  return (
    <div className={styles.main}>
      <div className={styles.goals}>
        <h2 className={styles.heads}>PIP</h2>
        <div>
          {/* <Button type="primary" className={styles.btn} onClick={() => handleClick()}>
            + ADD GOAL
          </Button> */}
        </div>
      </div>
      <Tabs defaultActiveKey="1" onChange={onChange}>
        {tabsContent.map((tab) => (
          <TabPane tab={tab.label} key={tab.key}>
            {tab.children}
          </TabPane>
        ))}
      </Tabs>
    </div>
  );
};

export default PIP;
