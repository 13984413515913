import React, { useContext, useState } from "react";
import styles from "./Forget.module.css";
import Logo from "../../Assets/ICONS/Logo.png";
import { Button, Col, Row, Form, Input, Switch } from "antd";
import { api_call } from "../../Utils/Network";
import { useNavigate } from "react-router-dom";
import { alertMessage } from "../../Utils/helperFunction";
import { UserCredsContext } from "../../Utils/UserCredsContext";

const Forget = () => {
  const [dataState, setDataState] = useState(1);
  const [userName, setUserName] = useState("");
  const [loading, setLoading] = useState(false);
  let { logout } = useContext(UserCredsContext);
  let navigate =useNavigate();

  const handleNextStep = (val) => {
    setDataState(val);
  };

  const onFinish = (vals) => {
    setLoading(true)
    console.log(vals)
    api_call
    .post(`forgetpassword`, vals)
    .then((res) => {
      if(res.status == 201 || res.status == 200 || res.status == 204){
        setLoading(true)
        alertMessage('Email sent ')
        logout()
        // setTimeout(() => {
        //   navigate('/login')
        // }, 1000)
      }else{
        setLoading(false)
      }
    })
    .catch(err => {
      setLoading(false)
      console.log(err)
    } )
  }

  const getData = () => {
    navigate('/login')
  }

  return (
    <div className={styles.mainContainer}>
      {dataState === 1 && (
        <div className={styles.forgetContainer}>
          <div>
            <img src={Logo} />
          </div>
          <h1 className={styles.headText}>Forgot Password</h1>
          <p className={styles.descriptionText}>
            Enter the username associated with your account and we’ll send an username
            with instructions to reset your password
          </p>
          <p style={{color: 'blue', cursor: 'pointer'}} onClick={() => getData()}>Get back to previous page</p>

          <Form onFinish={onFinish}>
            <div>
              <Form.Item name="username">
                <Input placeholder="Enter Username" />
              </Form.Item>
            </div>
            <div>
              <Button loading={loading} className={styles.btnEl} htmlType="submit">
                Next
              </Button>
            </div>
          </Form>
        </div>
      )}

      {dataState === 2 && (
        <div className={styles.forgetContainer}>
          <div>
            <img src={Logo} />
          </div>
          <h1 className={styles.miniHeadText}>set a new password</h1>
          <p className={styles.descriptionText}>
            Your new password must be different from previous used passwords.
          </p>
          <div>
            <Form.Item
              name="newpassword"
              rules={[
                {
                  required: true,
                  message: "Required",
                },
              ]}
            >
              <Input.Password placeholder="Enter new password" />
            </Form.Item>
          </div>
          <div>
            <Form.Item
              name="reenterPassowrd"
              rules={[
                {
                  required: true,
                  message: "Required",
                },
              ]}
            >
              <Input.Password placeholder="Re-enter new password" />
            </Form.Item>
          </div>
          <div>
            <Button
              className={styles.btnEl}
              htmlType="submit"
              onClick={() => handleNextStep(3)}
            >
              Reset Password
            </Button>
          </div>
        </div>
      )}

      {dataState === 3 && (
        <div className={styles.forgetContainer}>
          <div>
            <img src={Logo} />
          </div>
          <h1 className={styles.miniHeadText}>set a new password</h1>
          <p className={styles.descriptionText}>
            Your new password must be different from previous used passwords.
          </p>
          <div>
            <Form.Item
              name="newpassword"
              rules={[
                {
                  required: true,
                  message: "Required",
                },
              ]}
            >
              <Input.Password placeholder="Enter new password" />
              <label className={styles.strengthText}>
                Password Strength: Good
              </label>
            </Form.Item>
          </div>
          <div>
            <Form.Item
              name="rewritePassowrd"
              rules={[
                {
                  required: true,
                  message: "Required",
                },
              ]}
            >
              <Input.Password placeholder="Rewrite password" />
            </Form.Item>
          </div>
          <div>
            <Button
              className={styles.btnEl}
              htmlType="submit"
              //  onClick={() => handleNextStep(3)}
            >
              Reset Password
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Forget;
