import { Button, Col, Row, Form, Input, Switch } from "antd";
import React, { useContext, useState } from "react";
import { alertMessage } from "../../../../Utils/helperFunction";
import { api_call_token } from "../../../../Utils/Network";
import { UserCredsContext } from "../../../../Utils/UserCredsContext";

function GoalTypeForm({ field, key, getCategoryList = () => {}, setOpen = () => {} }) {
    const [form] = Form.useForm()
    const [isLoading, setIsLoading] = useState(false);
    const {logout} = useContext(UserCredsContext);
  const handleData = (vals) => {
    console.log(vals, field);
    setIsLoading(true);
    if(field?.goalTypeId){
        api_call_token
        .patch(`goaltype/${field?.goalTypeId}`, { ...vals, active: (vals?.active) ? 1 : 0 })
        .then((res) => {
            if(res.status == 201 ||res.status == 200 || res.status == 204){
                getCategoryList();
                setIsLoading(false);
                form.resetFields();
                setOpen(false);
            }else if(res.status  == 401){
              logout();
            } else if (res.status == 403) {
              alertMessage("Updating a Goal Type is restricted for users.", 2);
              setOpen(false);
            }
        })
        .catch(err => {
          setIsLoading(false);
          console.log(err)})
    }else{
        api_call_token
        .post(`goaltype`, { ...vals, active: (vals?.active) ? 1 : 0 })
        .then((res) => {
            if(res.status == 201 ||res.status == 200 || res.status == 204){
                getCategoryList();
                form.resetFields();
                setIsLoading(false);
                setOpen(false);
            }else if(res.status == 401){
              logout();
            } else if (res.status == 403) {
              alertMessage("Creating a Goal Type is restricted for users.", 2);
              setOpen(false);
            }
        })
        .catch(err => {
          console.log(err)
          setIsLoading(false);
        })
    }
  };
  return (
    <div>
      <h1>Goal Type</h1>
      <Form form={form} initialValues={field} key={key} onFinish={handleData}>
        <Row>
          <Col xs={24} md={24} lg={24}>
            <span>Goal Type Name</span>
            <Form.Item name="goalTypeName">
              <Input placeholder="Goal Type Name" />
            </Form.Item>
          </Col>
          <Col xs={24} md={24} lg={24}>
            <span>Goal Type Order</span>
            <Form.Item name="goalTypeOrder">
              <Input placeholder="Goal Type Order" type="number" />
            </Form.Item>
          </Col>
          <Col xs={24} md={24} lg={24}>
            <span>Active Status</span>
            {
              (field?.active == "true") ?
                <Form.Item name="active">
                  <Switch checkedChildren="Yes" unCheckedChildren="No" defaultChecked />
                </Form.Item>
                :
                <Form.Item name="active">
                  <Switch checkedChildren="Yes" unCheckedChildren="No" />
                </Form.Item>
            }
          </Col>
          <Col>
            <Button type="primary" htmlType="submit" loading={isLoading}>
              Submit
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
}

export default GoalTypeForm;
