import React, { useContext, useEffect, useState } from "react";
import styles from "./index.module.css";
import {
  Button,
  Col,
  DatePicker,
  Pagination,
  Row,
  Select,
  Table,
  Input,
  Space,
} from "antd";
import { api_call_token, base_url } from "../../Utils/Network";
import moment from "moment";
import { UserCredsContext } from "../../Utils/UserCredsContext";
import Green from "../../Assets/reports/green.png";
import Red from "../../Assets/reports/red.png";
import { SearchOutlined, DeleteFilled, EditFilled } from "@ant-design/icons";
import { isObject } from "../../Utils/helperFunction";
import Modals from "../../Component/Modals/Modals";
import PIPAchivementEditform from "./PIPAchivementEditform";
import PIPDelete from "./PIPDelete";
// import AchivementEditFrom from './AchivementEditFrom';

function PIPAchivementEdit() {
  const [goalType, setGoalType] = useState([]);
  const [department, setDepartment] = useState([]);
  const [categoryType, setCategory] = useState([]);
  const [userType, setUserType] = useState([]);
  const [open, setOpen] = useState(false);
  const [field, setFields] = useState(null);
  const [vals, setVals] = useState(null);
  const { userState, logout } = useContext(UserCredsContext);
  const [data, setData] = useState([]);
  const [expandedRowKey, setExpandedRowKey] = useState(null);
  const [filterObj, setFilterObj] = useState({
    page: 1,
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [totalNumberofPage, setTotalNumberofPage] = useState(null);
  const [selectedGoal, setSelectedGoal] = useState(null);
  const [kpiData, setKpiData] = useState([]);
  useEffect(() => {
    getKpi();
    getDepartment();
    getCategory();
    getallReports();
    getallUser();
  }, []);

  const getallUser = () => {
    api_call_token
      .get(`/createuser/alluser?size=100`)
      .then((res) => {
        setUserType(res.data.data);
      })
      .catch((err) => console.log(err));
  };

  const getallReports = () => {
    api_call_token
      .get(`goalkpiachievement/mydata`)
      .then((res) => {})
      .catch((err) => console.log(err));
  };

  const getCategory = () => {
    api_call_token
      .get(`category?size=100`)
      .then((res) => {
        setCategory(res.data.data);
      })
      .catch((err) => console.log(err));
  };

  const getDepartment = () => {
    api_call_token
      .get(`department?size=100`)
      .then((res) => {
        setDepartment(res.data.data);
      })
      .catch((err) => console.log(err));
  };

  const getKpi = () => {
    api_call_token
      .get(`goaltype`)
      .then((res) => {
        setGoalType(res.data.data);
      })
      .catch((err) => console.log(err));
  };

  const selectedGoals = (v) => {
    setSelectedGoal(v?.goalId);
    setKpiData(v.goalKpiList);
  };

  const handlePageChange = (page) => {
    console.log(page, "aaaaaaaaa");
    setFilterObj({ ...filterObj, page: page });
    setCurrentPage(page);
  };
  const handleRowClick = (record) => {
    console.log(record, "RECORDSSSSSS");
    setExpandedRowKey((prevKey) =>
      prevKey === record.key ? null : record.key
    );
  };

  const handleOpenModal = (record) => {
    if (record !== undefined && record !== null && isObject(record)) {
      setFields(record);
    }
    setVals(1);
    setOpen(true);
  };

  const handleOpenModalDelete = (record) => {
    if (record !== undefined && record !== null && isObject(record)) {
      setFields(record);
    }
    setVals(2);
    setOpen(true);
  };
  const expandedRowRender = (record) => {
    console.log(record, "RRRRRR");
    const columns = [
      { title: "Added By", dataIndex: "userName", key: "userName" },
      {
        title: "Achieved",
        dataIndex: "achivement",
        key: "achivement",
        render: (_, record) => {
          console.log(record, "sss");
          return (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                width: "100px",
                color: `${record.kpiTarget > _ ? "#DB4437" : "#2EB67D"}`,
              }}
            >
              {`${_} ${record.uomName}`}
              <img
                style={{ marginLeft: "10px" }}
                src={record.kpiTarget > _ ? Red : Green}
                alt="img"
              />
            </div>
          );
        },
      },
      {
        title: "Target",
        dataIndex: "kpiTarget",
        key: "kpiTarget",
        render: (_, rec) => {
          return <div>{`${_} ${rec.uomName}`}</div>;
        },
      },
      { title: "Date of Achievement", dataIndex: "date" },
      { title: "Date of Entry", dataIndex: "tillDate" },
      {
        title: "Month Target",
        dataIndex: "monthTarget",
        render: (_, rec) => {
          return <div>{`${_} ${rec.uomName}`}</div>;
        },
      },
      {
        title: "Balance",
        dataIndex: "achivementBalance",
        render: (_, record) => {
          console.log(_, "RECORD");
          return (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                width: "100px",
                color: `#DB4437`,
              }}
            >
              {`${_} ${record.uomName}`}
              <img style={{ marginLeft: "10px" }} src={Red} alt="img" />
            </div>
          );
        },
      },
      {
        title: "Reasone",
        dataIndex: "reasonAchievement",
        // key:"reasonAchievement",
        render: (_, record) => {
          console.log(_, record, "reasonAchievement");
          return <div>{_}</div>;
        },
      },
    ];

    const data = record.goalKpiAchievementList.map((kpi) => ({
      key: kpi.kpiId,
      userName: kpi.userName,
      goal: kpi.goalName,
      uomName: kpi.uomName,
      kpiTarget: kpi.kpiTarget,
      achivement: kpi.achivement,
      date: moment(kpi.achivementDate).format("DD-MMMM-YYYY"),
      tillDate: moment(kpi.createdAt).format("DD-MMMM-YYYY"),
      monthTarget: record.kpiTargetTotal, // Assuming kpiTarget is a date
      // balance: kpi.kpiTarget - kpi.kpiTargetAchievement, // Calculate balance here
      achivementBalance: kpi.achivementBalance, // Calculate balance here
      reasonAchievement: kpi.reasonAchievement,

      // Add more properties if needed
    }));

    return <Table columns={columns} dataSource={data} pagination={false} />;
  };

  useEffect(() => {
    getGoals();
  }, [filterObj]);

  const getGoals = (paramObj = { ...filterObj }) => {
    api_call_token
      .get(`goalkpiachievementpip`, { params: { ...paramObj } })
      .then((res) => {
        if (res.status === 200 || res.status === 201 || res.status === 204) {
          // let arr = [];
          // res.data.data.length > 0 && res?.data?.data?.map((v, i) => {
          //     v.goalKpiList.map((x, idx) => {
          //         arr.push(x);
          //     })
          // })
          // setData(arr);
          setKpiData(res.data.data);
          console.log(res.data.data, "seurhdkfh89798");
          setCurrentPage(res.data.currentPage);
          setTotalNumberofPage(res.data.totalRowCount);
        } else if (res.status === 401) {
          logout();
        }
      })
      .catch((err) => console.log(err));
  };

  const columns = [
    { title: "Name", dataIndex: "userName", key: "userName", width: 200 },
    {
      title: "Goal",
      dataIndex: "kpiName",
      key: "kpiName",
      width: 150,
      render: (_) => <div className={styles.goalInKpi}>{_}</div>,
    },
    {
      title: "Achieved",
      dataIndex: "achivement",
      key: "achivement",
      render: (_, record) => {
        console.log(record, "RECORD");
        const num = _;
        const roundedNum = parseFloat(num.toFixed(2));
        console.log(roundedNum, "RECORD");
        return (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              width: "100px",
              whiteSpace: "nowrap",
              color: `${record.totalTarget > _ ? "#DB4437" : "#2EB67D"}`,
            }}
          >
            {roundedNum}
            {/* {`${((_) % 1 !== 0) ? Math.round(_) : _} ${record.uomName}`} */}
            <img
              style={{ marginLeft: "10px" }}
              src={record.totalTarget > _ ? Red : Green}
              alt="img"
            />
          </div>
        );
      },
    },
    {
      title: "Target",
      dataIndex: "kpiTarget",
      key: "kpiTarget",
      render: (_, record) => {
        const num = _;
        const roundedNum = parseFloat(num.toFixed(2));
        return <div>{roundedNum}</div>;
      },
    },
    {
      title: "Achivement Date",
      dataIndex: "achivementDate",
      key: "achivementDate",
      render: (_) => <div>{moment(_).format("DD-MMMM-YYYY")}</div>,
    },
    {
      title: "Balance",
      dataIndex: "achivement",
      key: "achivement",
      render: (_, record) => {
        console.log(record, "RECORD");
        const num = _ - record?.kpiTarget;
        const roundedNum = parseFloat(num.toFixed(2));
        return (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              width: "100px",
              color: `${record.kpiTarget < _ ? "#DB4437" : "#2EB67D"}`,
              whiteSpace: "nowrap",
            }}
          >
            {`${roundedNum} ${record.uomName}`}
            <img
              style={{ marginLeft: "10px" }}
              src={record.kpiTarget < _ ? Red : Green}
              alt="img"
            />
          </div>
        );
      },
    },
    {
      title: "Reason",
      dataIndex: "reasonAchievement",
      key: "reasonAchievement",
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <DeleteFilled onClick={() => handleOpenModalDelete(record)} />
          <EditFilled onClick={() => handleOpenModal(record)} />
        </Space>
      ),
    },
  ];

  const paginationConfig = {
    current: currentPage,
    pageSize: 10, // Number of items per page
    total: totalNumberofPage, // Total number of items
    onChange: handlePageChange,
  };
  const onSearch = (e) => {
    let value = e.target.value;
    let obj = filterObj;
    if (value) {
      obj.search = value;
      setCurrentPage(1);
    } else delete obj.search;
    setFilterObj({ ...obj, page: 1 });
  };

  const saveExcel = (paramObj = { ...filterObj }) => {
    api_call_token
      .get(`excel/PIPAchivement`, { params: { ...paramObj } })
      .then((res) => {
        console.log(res.data, "res");
        if (res.status == 200) {
          const data = res.data.filePath;
          let val = data.split("public/");
          window.open(`${base_url}${val[1]}`);
        } else {
          alert("Something went wrong");
        }
      })
      .catch((err) => console.log(err));
  };

  const saveAssingment = (paramObj = { ...filterObj }) => {
    api_call_token
      .get(`excel/goalUserAssignment`, { params: { ...paramObj } })
      .then((res) => {
        console.log(res.data, "res");
        if (res.status == 200) {
          const data = res.data.filePath;
          let val = data.split("public/");
          window.open(`${base_url}${val[1]}`);
        } else {
          alert("Something went wrong");
        }
      })
      .catch((err) => console.log(err));
  };

  const handleform = () => {
    setFields({});
    setOpen(false);
    setVals(0);
  };

  return (
    <div>
      <div className={styles.main}>
        <h2>PIP Achievement Edit</h2>
      </div>
      <div className={styles.reportsFilter}>
        <Row gutter={[12, 6]} style={{ alignItems: "end" }}>
          <Col xs={24} lg={8}>
            <div style={{ display: "flex" }}>
              <div style={{ marginRight: "10px" }}>
                <span>From Date</span>
                <DatePicker
                  style={{ width: "100%" }}
                  onChange={(e) => {
                    // debugger;
                    let value = e?.$d || e;
                    let obj = filterObj;
                    if (value) {
                      obj.fromDate = moment(value).format("YYYY-MM-DD");
                      setCurrentPage(1);
                    } else delete obj.fromDate;
                    setFilterObj({ ...obj });
                  }}
                />
              </div>
              <div>
                <span>To Date</span>
                <DatePicker
                  style={{ width: "100%" }}
                  onChange={(e) => {
                    let value = e?.$d || e;
                    let obj = filterObj;
                    if (value) {
                      obj.endDate = moment(value).format("YYYY-MM-DD");
                      setCurrentPage(1);
                    } else delete obj.endDate;
                    setFilterObj({ ...obj });
                  }}
                />
              </div>
            </div>
          </Col>
          <Col xs={24} lg={8}>
            {/* <span>Category</span> */}
            <Input
              placeholder="Search by Goal, Goal Category"
              prefix={<SearchOutlined />}
              style={{ width: "100%" }}
              onChange={(e) => onSearch(e)}
            />
          </Col>
          <Col xs={4} lg={3}>
            <span>Type</span>

            <Select
              showSearch
              style={{
                width: "100%",
              }}
              placeholder="Goal Type"
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              filterSort={(optionA, optionB) =>
                (optionA?.label ?? "")
                  .toLowerCase()
                  .localeCompare((optionB?.label ?? "").toLowerCase())
              }
              allowClear
              onChange={(e) => {
                let value = e;
                let obj = filterObj;
                if (value) {
                  obj.kpiType = value;
                  obj.page = 1;
                  setCurrentPage(1);
                } else delete obj.kpiType;
                setFilterObj({ ...obj });
              }}
              options={
                goalType &&
                goalType?.map((obj) => {
                  const keys = Object.keys(obj);
                  const convertedObj = {
                    value: obj["goalTypeId"],
                    label: obj["goalTypeName"],
                  };

                  for (let i = 2; i < keys.length; i++) {
                    convertedObj[keys[i]] = obj[keys[i]];
                  }

                  return convertedObj;
                })
              }
            />
          </Col>

          <Col xs={24} lg={4}>
            <div className={styles.btnContainer}>
              {/* <Button
                className={`${styles.btn} ${styles.orange}`}
                onClick={() => saveAssingment()}
              >
                Fetch User Goal Mapping
              </Button> */}
              <Button className={styles.btn} onClick={() => saveExcel()}>
                Save as Excel
              </Button>
            </div>
          </Col>
        </Row>
      </div>
      <div className={styles.mainData}>
        <div className={styles.container}>
          <p className={styles.goalHeader}>Goal</p>
          {data.map((v, i) => (
            <div
              className={`${styles.boxGoal} ${
                selectedGoal == v.goalId ? styles.selected : ""
              }`}
              onClick={() => selectedGoals(v)}
            >
              {v.goalName}
            </div>
          ))}
        </div>
        <Table
          className={styles.table_striped_rows}
          columns={columns}
          pagination={false}
          // expandable={{
          //     expandedRowRender,
          //     expandedRowKeys: [expandedRowKey],
          //     onExpand: (_, record) => handleRowClick(record),
          //     expandIcon: ({ expanded, onExpand, record }) => (
          //         <span onClick={() => handleRowClick(record)}>
          //             {expanded ? "-" : "+"}
          //         </span>
          //     ),
          // }}
          dataSource={kpiData}
          // className={styles.customTable}
        />

        <div style={{ textAlign: "right", margin: "10px" }}>
          <Pagination {...paginationConfig} showSizeChanger={false}/>
        </div>
      </div>

      {open && (
        <Modals open={open} handleCancel={() => handleform()}>
          {vals == 1 && (
            <>
              <PIPAchivementEditform
                data={field}
                setOpen={setOpen}
                getGoals={getGoals}
              />
            </>
            // <AchivementEditFrom  data={field} setOpen={setOpen} getGoals={getGoals}/>
          )}
          {vals == 2 && (
            <PIPDelete
              data={field}
              setOpen={setOpen}
              getGoals={getGoals}
              text={"Are You Sure Want to delete this PIP"}
            />
          )}
        </Modals>
      )}
    </div>
  );
}

export default PIPAchivementEdit;
