import { Button, Col, DatePicker, Input, Row, Select } from "antd";
import moment from "moment";
import dayjs from "dayjs";
import React, { useContext, useEffect, useState } from "react";
import { api_call_token } from "../../../Utils/Network";
import { UserCredsContext } from "../../../Utils/UserCredsContext";
import styles from './index.module.css';
import { useLocation } from "react-router-dom";

function KpiCreation({
  setKpiListData = () => { },
  kpiListData,
  uom,
  goalType,
  categoryType,
  department,
  userData
}) {
  // const today = moment();
  const today = dayjs();
  let location = useLocation();
  const [kpiList, setKpiList] = useState([]);
  const [kpiData, setKpiData] = useState({
    kpiId: null,
    kpiMasterId: null,
    kpiName: "",
    kpiWeightage: null,
    kpiLevel: null,
    kpiDescription: "",
    kpiTarget: null,
    uomId: null,
    kpiTargetAchievement: "",
    kpiCategory: null,
    departmentIds: null,
    userIds: null,
    kpiType: null,
    kpiStartDate: "",
    kpiDueDate: "",
    priorityId: null,
    uomName: "",
    permissionUserIds: null,
  });
  // const [uom, setUom] = useState([]);
  // const [goalType, setGoalType] = useState([]);
  // const [categoryType, setCategory] = useState([]);
  // const [department, setDepartment] = useState([]);
  const { userState, logout } = useContext(UserCredsContext);
  const [filter, setFilter] = useState({});
  // permission
const [selectedReadUsers, setSelectedReadUsers] = useState([]);
const [selectedWriteUsers, setSelectedWriteUsers] = useState([]);

  useEffect(() => {
    getkpi();
  }, [filter]);

  const handleData = (value, data) => {
    console.log(value, data, "Tndfjdsjdhsjfh");
    if (!data) {
      setKpiData({
        kpiId: null,
        kpiMasterId: null,
        kpiName: "",
        kpiWeightage: null,
        kpiLevel: null,
        kpiDescription: "",
        kpiTarget: null,
        uomId: null,
        kpiTargetAchievement: "",
        kpiCategory: null,
        departmentIds: null,
        userIds: null,
        kpiType: null,
        kpiStartDate: "",
        kpiDueDate: "",
        priorityId: null,
        uomName: "",
        permissionUserIds:null,
      });
      return;
    }
    const {
      departmentList,
      departmentId,
      kpiCategory,
      kpiDescription,
      kpiLevel,
      kpiTarget,
      kpiTargetAchievement,
      kpiType,
      kpiWeightage,
      priorityId,
      uomId,
      kpiStartDate,
      kpiDueDate,
      label,
      ...rest
    } = data;

    setKpiData({
      ...kpiData,
      kpiId: null,
      kpiMasterId: value,
      kpiCategory,
      kpiDescription,
      kpiLevel,
      kpiTarget,
      kpiTargetAchievement,
      kpiType,
      kpiWeightage,
      priorityId,
      uomId,
      kpiStartDate,
      kpiDueDate,
      kpiName: label,
      // departmentIds: [departmentId],
      departmentIds:
      departmentList.map((v, i) => { return v.departmentId }),
    });
  };

  const getkpi = (paramObj = { ...filter }) => {
    api_call_token
      .get(`kpimaster`, {params: paramObj })
      .then((res) => {
        if (res.status == 200 || res.status == 201 || res.status == 204) {
          setKpiList(res.data.data);
        } else if (res.status == 401) {
          logout();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handlAdd = () => {
    setKpiListData([...kpiListData, kpiData]);
    setKpiData({
      kpiId: null,
      kpiMasterId: null,
      kpiName: "",
      kpiWeightage: null,
      kpiLevel: null,
      kpiDescription: "",
      kpiTarget: null,
      uomId: null,
      kpiTargetAchievement: "",
      kpiCategory: null,
      departmentIds: null,
      userIds: null,
      kpiType: null,
      kpiStartDate: "",
      kpiDueDate: "",
      priorityId: null,
      permissionUserIds: null,
    });
  };

  const handleAdd = (e, val) => {
    debugger
    console.log(e,{[val]: [e]}  )
    setKpiData({ ...kpiData, [val]: [e] })
  }

  const handleSearch = (e) => {
    console.log(e, "eeeeee")
    setFilter({search: e})
  }

  const handleChanges = (e, data) => {
    console.log(e, data, "dddddddd");
    let val = data;
    if (
      typeof e === "object" &&
      (e?.$d instanceof Date || e?._d instanceof Date)
    ) {
      if (e?.$d) {
        // data = moment(e?.$d).format("YYYY-MM-DD");
        data = dayjs(e?.$d).format("YYYY-MM-DD");
      } else {
        data = dayjs(e?._d).format("YYYY-MM-DD");
      }
    }
    let dates = {
      ...kpiData,
      [val]: data,
      departmentIds:[kpiData?.departmentIds],
    };

    console.log(e, data, val, "dddddddd");
    setKpiData(dates);
  };

  const handleNew = (e, val) => {
    setKpiData({ ...kpiData, [val]: e })
  }
  const handle = (e, val) => {
    console.log(e, val)
    let name;
    if (val == "uomId") {
      name = "uomName"
    }
    setKpiData({ ...kpiData, [val]: e?.value, [name]: e.label })
  }

  const handleChange = (e) => {
    console.log(e.target.name, e.target.value)
    const { name, value } = e.target;
    setKpiData({ ...kpiData, [name]: value })
  }
  // console.log(kpiData, "this is kpi Data");
  console.log(location, "this is kpi Data");



      //  chages with user permission  selcte drop down for goal

const handleReadUserChange = (selectedUsers) => {
  console.log(selectedUsers, 'handleReadUserChange');
  setSelectedReadUsers(selectedUsers);
  setKpiData({ ...kpiData, userIds: selectedUsers })
};
console.log(selectedReadUsers, "selectedReadUsers");


const handleWriteUserChange = (selectedUsers) =>{
  setSelectedWriteUsers(selectedUsers)
  setKpiData({ ...kpiData, permissionUserIds: selectedUsers })
}
  return (
    <div>
      <h4>Enter KPI Details</h4>
      <div className={styles.kpiDetails}>
        <Row gutter={[25, 12]}>
          <Col lg={6} sm={6} xs={12}>
            <span>Kpi Id</span>
            <Select
              showSearch
              onSearch={handleSearch}
              style={{
                width: "100%",
              }}
              placeholder="Search to Select"
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              filterSort={(optionA, optionB) =>
                (optionA?.label ?? "")
                  .toLowerCase()
                  .localeCompare((optionB?.label ?? "").toLowerCase())
              }
              allowClear
              onChange={handleData}
              options={
                kpiList &&
                kpiList?.map((obj) => {
                  const keys = Object.keys(obj);
                  const convertedObj = {
                    value: obj["kpiMasterId"],
                    label: obj["kpiName"],
                  };

                  for (let i = 2; i < keys.length; i++) {
                    convertedObj[keys[i]] = obj[keys[i]];
                  }

                  return convertedObj;
                })
              }
            />
          </Col>
          <Col lg={6} sm={6} xs={12}>
            <span>Target</span>
            <Input value={kpiData?.kpiTarget} name="kpiTarget" onChange={handleChange} />
          </Col>
          <Col lg={6} sm={6} xs={12}>
            <span>Achievement</span>
            <Select
              showSearch
              value={kpiData?.kpiTargetAchievement}
              style={{
                width: "100%",
              }}
              onChange={(e) => setKpiData({ ...kpiData, kpiTargetAchievement: e })}
              placeholder="Target Achievement"
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              filterSort={(optionA, optionB) =>
                (optionA?.label ?? "")
                  .toLowerCase()
                  .localeCompare((optionB?.label ?? "").toLowerCase())
              }
              allowClear
              options={[
                {
                  value: "Overachievement",
                  label: "Overachievement",
                },
                {
                  value: "Underachievement",
                  label: "Underachievement",
                },
              ]}
            />
          </Col>
          <Col lg={6} sm={6} xs={12}>
            <span>UOM</span>
            <Select
              showSearch
              style={{
                width: "100%",
              }}
              value={kpiData?.uomId}
              placeholder="UOM"
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              filterSort={(optionA, optionB) =>
                (optionA?.label ?? "")
                  .toLowerCase()
                  .localeCompare((optionB?.label ?? "").toLowerCase())
              }
              getOptionLabel={(option) => option.label}
              labelInValue
              onChange={(e) => handle(e, "uomId")}
              allowClear
              options={
                uom &&
                uom?.map((obj) => {
                  const keys = Object.keys(obj);
                  const convertedObj = {
                    value: obj["uomId"],
                    label: obj["uomName"],
                  };

                  for (let i = 2; i < keys.length; i++) {
                    convertedObj[keys[i]] = obj[keys[i]];
                  }

                  return convertedObj;
                })
              }
            />
          </Col>
          <Col lg={6} sm={6} xs={12}>
            <span>Weightage</span>
            <Input value={kpiData?.kpiWeightage} name="kpiWeightage" onChange={handleChange} />
          </Col>
          <Col lg={6} sm={6} xs={12}>
            <span>Kpi Type</span>
            <Select
              showSearch
              style={{
                width: "100%",
              }}
              value={kpiData?.kpiType}
              placeholder="Kpi Type"
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              filterSort={(optionA, optionB) =>
                (optionA?.label ?? "")
                  .toLowerCase()
                  .localeCompare((optionB?.label ?? "").toLowerCase())
              }
              onChange={(e) => handleNew(e, "kpiType")}
              allowClear
              options={
                goalType &&
                goalType?.map((obj) => {
                  const keys = Object.keys(obj);
                  const convertedObj = {
                    value: obj["goalTypeId"],
                    label: obj["goalTypeName"],
                  };

                  for (let i = 2; i < keys.length; i++) {
                    convertedObj[keys[i]] = obj[keys[i]];
                  }

                  return convertedObj;
                })
              }
            />
          </Col>
          <Col lg={6} sm={6} xs={12}>
            <span>Category Type</span>
            <Select
              showSearch
              style={{
                width: "100%",
              }}
              value={kpiData?.kpiCategory}
              placeholder="Kpi Type"
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              filterSort={(optionA, optionB) =>
                (optionA?.label ?? "")
                  .toLowerCase()
                  .localeCompare((optionB?.label ?? "").toLowerCase())
              }
              onChange={(e) => handleNew(e, "kpiCategory")}
              allowClear
              options={
                categoryType &&
                categoryType?.map((obj) => {
                  const keys = Object.keys(obj);
                  const convertedObj = {
                    value: obj["categoryId"],
                    label: obj["categoryName"],
                  };

                  for (let i = 2; i < keys.length; i++) {
                    convertedObj[keys[i]] = obj[keys[i]];
                  }

                  return convertedObj;
                })
              }
            />
          </Col>
          <Col lg={6} sm={6} xs={12}>
            <span>Level</span>
            <Select
              showSearch
              style={{
                width: "100%",
              }}
              value={kpiData?.kpiLevel}
              placeholder="Goal Type"
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              filterSort={(optionA, optionB) =>
                (optionA?.label ?? "")
                  .toLowerCase()
                  .localeCompare((optionB?.label ?? "").toLowerCase())
              }
              onChange={(e) => handleNew(e, "kpiLevel")}
              allowClear
              options={[
                {
                  value: "1",
                  label: "Low",
                },
                {
                  value: "2",
                  label: "Medium",
                },
                {
                  value: "3",
                  label: "high",
                },
              ]}
            />
          </Col>
          <Col lg={6} sm={6} xs={12}>
            <span>Start Date</span>
            <DatePicker
              value={(kpiData?.kpiStartDate) ? dayjs(kpiData?.kpiStartDate) : null}
              //  value={(kpiData?.kpiStartDate) ?moment(kpiData?.kpiStartDate.split("T")[0], "YYYY-MM-DD"): moment(today, "YYYY-MM-DD")}
              style={{ display: "block", width: "100%" }}
              onChange={(e) => handleChanges(e, "kpiStartDate")}
            />
          </Col>
          <Col lg={6} sm={6} xs={12}>
            <span>End Date</span>
            <DatePicker
              value={(kpiData?.kpiDueDate) ? dayjs(kpiData?.kpiDueDate) : null}
              style={{ display: "block", width: "100%" }}
              onChange={(e) => handleChanges(e, "kpiDueDate")}
            />
          </Col>
          <Col lg={6} sm={6} xs={12}>
            <span>Department</span>
            <Select
              // mode="multiple"
              showSearch
              style={{
                width: "100%",
              }}
              value={kpiData?.departmentIds}
              placeholder="Search to Select"
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              filterSort={(optionA, optionB) =>
                (optionA?.label ?? "")
                  .toLowerCase()
                  .localeCompare((optionB?.label ?? "").toLowerCase())
              }
              allowClear
              onChange={(e) => handleAdd(e, "departmentIds")}
              options={
                department &&
                department?.map((obj) => {
                  const keys = Object.keys(obj);
                  const convertedObj = {
                    value: obj["departmentId"],
                    label: obj["departmentName"],
                  };

                  for (let i = 2; i < keys.length; i++) {
                    convertedObj[keys[i]] = obj[keys[i]];
                  }

                  return convertedObj;
                })
              }
            />
          </Col>
          <Col lg={6} sm={6} xs={12}>
            <span>User With Read Permission</span>
            <Select
              mode="multiple"
              showSearch
              style={{
                width: "100%",
              }}
              value={kpiData?.userIds}
              placeholder="Search to Select"
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              filterSort={(optionA, optionB) =>
                (optionA?.label ?? "")
                  .toLowerCase()
                  .localeCompare((optionB?.label ?? "").toLowerCase())
              }
              allowClear
              // onChange={(e) => handleAdd(e, "userIds")}
              onChange={handleReadUserChange}
              options={
                userData &&
                userData?.map((obj) => {
                  const keys = Object.keys(obj);
                  const convertedObj = {
                    value: obj["userId"],
                    label: `${obj["firstName"]} ${obj["lastName"]}`,
                    disabled: selectedWriteUsers.includes(obj["userId"]) // Disable option if it's selected in the first Select
                  };

                  for (let i = 2; i < keys.length; i++) {
                    convertedObj[keys[i]] = obj[keys[i]];
                  }

                  return convertedObj;
                })
              }
            />
          </Col>
          <Col lg={6} sm={6} xs={12}>
            <span>User with Write Permission</span>
            <Select
              mode="multiple"
              showSearch
              style={{
                width: "100%",
              }}
              value={kpiData?.permissionUserIds}
              placeholder="Search to Select"
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              filterSort={(optionA, optionB) =>
                (optionA?.label ?? "")
                  .toLowerCase()
                  .localeCompare((optionB?.label ?? "").toLowerCase())
              }
              allowClear
              // onChange={(e) => handleAdd(e, "userIds")}
              onChange={handleWriteUserChange}
              options={
                userData &&
                userData?.map((obj) => {
                  const keys = Object.keys(obj);
                  const convertedObj = {
                    value: obj["userId"],
                    label: `${obj["firstName"]} ${obj["lastName"]}`,
                    disabled: selectedReadUsers.includes(obj["userId"]) // Disable option if it's selected in the first Select
                  };

                  for (let i = 2; i < keys.length; i++) {
                    convertedObj[keys[i]] = obj[keys[i]];
                  }

                  return convertedObj;
                })
              }
            />
          </Col>
        </Row>
        <div style={{ margin: "20px", textAlign: "right" }}>
          <Button onClick={() => handlAdd()}>Save KPI</Button>
        </div>
      </div>
    </div>
  );
}

export default KpiCreation;
