import React, { useEffect, useState } from 'react'
import styles from './index.module.css'
import { Tabs } from 'antd'
import TabPane from 'antd/es/tabs/TabPane'
import Subcomponent from './Subcomponent'
import { api_call_token } from '../../Utils/Network'

function PIPAchievement() {
    const [goals, setGaols] = useState([])
    const [current, setCurrent] = useState(1);

    useEffect(() => {
        getGoalType();
    }, [])

    const getGoalType = () => {
        
        api_call_token
        .get(`goaltype`)
        .then((res) => {
            const data = res.data.data;
            let arr = [];
            data.map((v,i) => {
                let vals = {
                    key: v?.goalTypeId,
                    label: v?.goalTypeName,
                    children: <Subcomponent id={current}/>
                }

                arr.push(vals)
            })
            let newArr = arr.reverse();
            setCurrent(newArr[0]?.key)
            setGaols(newArr);
        })
        .catch((err) => console.log(err))
    }

    // const tabsContent = [
    //     {
    //         key: 1,
    //         label: "Night Shift",
    //         children: <Subcomponent />
    //     },
    //     {
    //         key: 2,
    //         label: "Day Shift",
    //         children: <Subcomponent />
    //     },
    //     {
    //         key: 3,
    //         label: "Daily",
    //         children: <Subcomponent />
    //     },
    //     {
    //         key: 4,
    //         label: "Weekly",
    //         children: <Subcomponent />
    //     },
    //     {
    //         key: 5,
    //         label: "Quarterly",
    //         children: <Subcomponent />
    //     },
    //     {
    //         key: 6,
    //         label: "Monthly",
    //         children: <Subcomponent />
    //     },
    // ]

    const onChange = (v) => {
        console.log(v, "vvvvvv")
        setCurrent(v)
    }
    console.log(goals, "Goal")
    return (
        <div>
            <div>
                <div className={styles.goals}>
                    <h2 className={styles.heads}>Add PIP Achievement</h2>
                    
                </div>
                <Tabs defaultActiveKey="1" onChange={onChange}>
                    {goals.map((tab) => (
                        <TabPane tab={tab.label} key={tab.key}>
                            <Subcomponent id={current} getGoalType={getGoalType}/>
                        </TabPane>
                    ))}
                </Tabs>
            </div>
        </div>
    )
}

export default PIPAchievement

