import React, {useState} from "react";
import { Tabs } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import AllList from "./AllList/AllList";
import PendingList from "./PendingList/PendingList";
import ApprovedList from "./ApprovedList/ApprovedList";
import Rejected from "./Rejected/Rejected";
import styles from './index.module.css';

const { TabPane } = Tabs;
function GoalPIP() {
  const { id } = useParams();
  let navigate = useNavigate();

  const [activeTab, setActiveTab] = useState('1');
  const [selectedRows, setSelectedRows] = useState([]);

  const handleTabChange = (key) => {
    setActiveTab(key);
  };
  const items = [
    {
      key: "1",
      label: "All Goals",
      children: <AllList />,
    },
    {
      key: "2",
      label: "Pending",
      children: <PendingList />
    //   children: <IngotList id={id} setActiveTab={setActiveTab} selectedRows={selectedRows} setSelectedRows={setSelectedRows}/>,
    },
    {
      key: "3",
      label: "Approved",
      children: <ApprovedList />
    //   children: <PackagingList />,
    },
    {
      key: "4",
      label: "Rejected",
      children: <Rejected />
    //   children: <PackagingList />,
    },
  ];

  const handleClick = () => {
    navigate(`/heat`);
  };
  return (
    <div>
      <div className={styles.main}>
        <h2 className={styles.heads}>
          Goal PIP
        </h2>
        {/* <Tabs defaultActiveKey="1" items={items} onChange={onChange} /> */}
        <Tabs activeKey={activeTab} onChange={handleTabChange}>
          {items.map((v,i) => (<TabPane tab={v.label} key={v.key}>
            {/* {v.children} */}
            {activeTab === v.key ? v.children : null}
          </TabPane>))}
        </Tabs>
      </div>
    </div>
  );
}

export default GoalPIP;
