import { Button, Col, Row, Form, Input, Switch } from "antd";
import React, { useContext } from "react";
import { alertMessage } from "../../../../Utils/helperFunction";
import { api_call_token } from "../../../../Utils/Network";
import { UserCredsContext } from "../../../../Utils/UserCredsContext";

function RoleForm({ field, key, getCategoryList = () => { }, setOpen = () => { } }) {
  const [form] = Form.useForm()
  const {logout} = useContext(UserCredsContext)
  const handleData = (vals) => {
    console.log(vals, field);

    if (field?.roleId) {
      api_call_token
        .patch(`roles/${field?.roleId}`, { ...vals, active: (vals?.active) ? 1 : 0 })
        .then((res) => {
          if (res.status == 201 || res.status == 200 || res.status == 204) {
            getCategoryList();
            form.resetFields();
            setOpen(false);
          }else if(res.status == 401){
            logout();
          } else if (res.status == 403) {
            alertMessage("Updating a Role is restricted for users.", 2);
            setOpen(false);
          }
        })
        .catch(err => console.log(err))
    } else {
      api_call_token
        .post(`roles`, { ...vals, active: (vals?.active) ? 1 : 0 })
        .then((res) => {
          if (res.status == 201 || res.status == 200 || res.status == 204) {
            getCategoryList();
            form.resetFields();
            setOpen(false);
          }else if(res.status == 401){
            logout();
          } else if (res.status == 403) {
            alertMessage("Creating a Role is restricted for users.", 2);
            setOpen(false);
          }
        })
        .catch(err => console.log(err))
    }
  };
  return (
    <div>
      <h1>Role</h1>
      <Form form={form} initialValues={field} key={key} onFinish={handleData}>
        <Row>
          <Col xs={24} md={24} lg={24}>
            <span>Role Name</span>
            <Form.Item name="roleName">
              <Input placeholder="Role Name" />
            </Form.Item>
          </Col>
          <Col xs={24} md={24} lg={24}>
            <span>Active Status</span>
            {
              (field?.active == "true") ?
                <Form.Item name="active">
                  <Switch checkedChildren="Yes" unCheckedChildren="No" defaultChecked />
                </Form.Item>
                :
                <Form.Item name="active">
                  <Switch checkedChildren="Yes" unCheckedChildren="No" />
                </Form.Item>
            }
          </Col>
          <Col>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
}

export default RoleForm;
