import { message } from "antd";


//Debounce method
export const debounce = (func, delay) => {
    let timerId;
    return function (...args) {
        if (timerId) {
            clearTimeout(timerId);
        }
        timerId = setTimeout(() => {
            func(...args);
        }, delay);
    };
};

//Alert Message
export function alertMessage(msg = "Data Added", type = 0) {
    if (type == 1) return message.warning({ content: msg })
    else if (type == 2) return message.error({ content: msg })
    return message.success({ content: msg + "Successfully" })
}

// Get the Local Data
export const getLocalData = (type) => {
    const value = localStorage.getItem(type);
    return JSON.parse(value);
};

//Save in Local Storage
export const saveLocalData = (type, value) => {
    localStorage.setItem(type, JSON.stringify(value));
};

export const isObject = (variable) => {
    return typeof variable === 'object' && variable !== null;
}