import React, { useContext, useEffect, useState } from "react";
import styles from "./Forget.module.css";
import Logo from "../../Assets/ICONS/Logo.png";
import { Button, Col, Row, Form, Input, Switch } from "antd";
import { api_call } from "../../Utils/Network";
import { useNavigate, useParams } from "react-router-dom";
import { alertMessage, getLocalData } from "../../Utils/helperFunction";
import { UserCredsContext } from "../../Utils/UserCredsContext";

const Reset = () => {
  const [loading, setLoading] = useState(false);
  const [checkData, setCheckData] = useState(true);
  const [form] = Form.useForm();
  let navigate = useNavigate();
  let params = useParams();
  let { logout } = useContext(UserCredsContext);

  useEffect(() => {
    let auth = getLocalData("dmc_user");
    if (auth.token) {
      logout();
    }
    getLink();
  }, []);

  const getLink = () => {
    api_call
      .get(`linkvalidity/${params?.token}`)
      .then((res) => {
        if (res.status == 200 || res.status == 201) {
          setCheckData(res.data.isValid);
        }
      })
      .catch((err) => console.log(err));
  };
  const handleSubmit = (values) => {
    setLoading(true);
    let data = {
      password: values.newpassword,
      token: params?.token, // Pass the token parameter to the API
    };

    api_call
      .post("updateuserpassword", data)
      .then((res) => {
        if (res.status == 200 || res.status == 201 || res.status == 204) {
          setLoading(false);
          alertMessage("Password reset ");
          navigate("/login"); // Redirect to login page after successful password reset
        } else {
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  const getData = () => {
    navigate("/login");
  };

  console.log(params, "Paramssss");

  return (
    <div className={styles.mainContainer}>
      <div className={styles.forgetContainer}>
        <div>
          <img src={Logo} />
        </div>
        <div>
          {checkData ? (
            <div>
              <h1 className={styles.miniHeadText}>set a new password</h1>
              <p className={styles.descriptionText}>
                Your new password must be different from previous used
                passwords.
              </p>
              <p
                style={{ color: "blue", cursor: "pointer" }}
                onClick={() => getData()}
              >
                Get back to previous page
              </p>
              <Form
                form={form}
                onFinish={handleSubmit}
                layout="vertical"
                autoComplete="off"
              >
                <Form.Item
                  name="newpassword"
                  label="New Password"
                  rules={[
                    {
                      required: true,
                      message: "Please enter your new password",
                    },
                  ]}
                >
                  <Input.Password placeholder="Enter new password" />
                </Form.Item>
                <Form.Item
                  name="reenterPassowrd"
                  label="Confirm Password"
                  dependencies={["newpassword"]}
                  rules={[
                    {
                      required: true,
                      message: "Please re-enter your new password",
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue("newpassword") === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          new Error("The two passwords do not match")
                        );
                      },
                    }),
                  ]}
                >
                  <Input.Password placeholder="Re-enter new password" />
                </Form.Item>
                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    loading={loading}
                    className={styles.btnEl}
                  >
                    Reset Password
                  </Button>
                </Form.Item>
              </Form>
            </div>
          ) : (
            <div>Link has been Expired</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Reset;
