import React, { useEffect } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'

export  function MasterRoute() {
  return (
    <Outlet />
  )
}

export  function LoginRouter() {
  // let navigate = useNavigate();

  // useEffect(() => {
  //   navigate('/login')
  // }, [])
  return (
    <Outlet />
  )
}

export  function GoalRoute() {
  return (
    <Outlet />
  )
}

export  function PIPRoute() {
  return (
    <Outlet />
  )
}


export  function AchievementRoute() {
  return (
    <Outlet />
  )
}

export  function PIPAchievementRoute() {
  return (
    <Outlet />
  )
}

export  function AdminGoalRoute() {
  return (
    <Outlet />
  )
}

export  function AdminPIPRoute() {
  return (
    <Outlet />
  )
}

export  function ReportsRoute() {
  return (
    <Outlet />
  )
}

export  function PIPReportsRoute() {
  return (
    <Outlet />
  )
}

export  function UserAchievementReportsRoute() {
  return (
    <Outlet />
  )
}

export  function UserAchievementEditRoute() {
  return (
    <Outlet />
  )
}
export  function UserAchievementPIPEditRoute() {
  return (
    <Outlet />
  )
}

export  function ForgetRoute() {
  return (
    <Outlet />
  )
}


export  function KPIRoute() {
  return (
    <Outlet />
  )
}

export  function DepartmentRoute() {
  return (
    <Outlet />
  )
}

export  function UserRoute() {
  return (
    <Outlet />
  )
}

export  function CategoryRoute() {
  return (
    <Outlet />
  )
}

export  function GoalStatusRoute() {
  return (
    <Outlet />
  )
}

export  function GoalTypeRoute() {
  return (
    <Outlet />
  )
}

export  function PriorityRoute() {
  return (
    <Outlet />
  )
}

export  function RoleRoute() {
  return (
    <Outlet />
  )
}

export  function UOMRoute() {
  return (
    <Outlet />
  )
}

export  function StatusRoute() {
  return (
    <Outlet />
  )
}