import {
  ArrowLeftOutlined,
  DeleteFilled,
  EditFilled,
  SearchOutlined,
} from "@ant-design/icons";
import { Button, Input, Select, Table, Space, Tag, Pagination } from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./index.module.css";
import { api_call_token } from "../../../../Utils/Network";
import moment from "moment";

function UserList() {
  let navigate = useNavigate();
  const [userList, setUserList] = useState([]);
  const [role, setRole] = useState([]);
  const [filterObj, setFilterObj] = useState({
    page: 1,
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [totalNumberofPage, setTotalNumberofPage] = useState(null);

  useEffect(() => {
    getRole();
  }, [])

  const getRole = () => {
    api_call_token
      .get(`roles`)
      .then((res) => {
        setRole(res.data.data);
      })
      .catch((err) => console.log(err))
  }

  useEffect(() => {
    getUser();
  }, [filterObj])


  const getUser = (paramObj = { ...filterObj }) => {
    api_call_token
      .get(`createuser/alluser`, { params: { ...paramObj } })
      .then((res) => {
        setUserList(res.data.data);
        setCurrentPage(res.data.currentPage);
        setTotalNumberofPage(res.data.totalRowCount);
      })
      .catch(err => console.log(err))
  }

  const handleClick = () => {
    navigate("/master");
  };

  const handleCreate = () => {
    navigate('create')
  }

  const handlePageChange = (page) => {
    console.log(page, "aaaaaaaaa");
    setFilterObj({ ...filterObj, page: page });
    setCurrentPage(page);
  };

  const paginationConfig = {
    current: currentPage,
    pageSize: 10, // Number of items per page
    total: totalNumberofPage, // Total number of items
    onChange: handlePageChange,
  };

  const handleSelect = (record) => {
    console.log(record)
    navigate('create', { state: record })
  }

  const onSearch = (e) => {
    let value = e.target.value;
    let obj = filterObj;
    if (value) { obj.search = value; setCurrentPage(1) }
    else delete obj.search;
    setFilterObj({ ...obj, page: 1 });
  }

  const handleChange = (e) => {
    console.log(e, "DDDDDD")
    let value = e;
    let obj = filterObj;
    if (value) {
      obj.userRole = value;
      setCurrentPage(1)
    }
    else delete obj.userRole;
    setFilterObj({ ...obj });
  }
  const columns = [
    {
      title: "Emp ID",
      dataIndex: "userId",
      key: "userId",
      flex: 1,
      render: (_, record) => {
        return <div onClick={() => handleSelect(record)} style={{ cursor: 'pointer' }}>{_}</div>
      }
    },
    {
      title: "Employee Name",
      dataIndex: "employeeName",
      key: "employeeName",
      render: (_, record) => {
        return <div>{`${record?.firstName} ${record?.lastName}`}</div>
      },
      flex: 1,
    },
    {
      title: "Joined",
      dataIndex: "createdAt",
      key: "createdAt",
      flex: 1,
      render: (_) => <div>{(_) ? moment(_).format("DD-MMMM-YYYY") : _}</div>
    },
    {
      title: "Email ID",
      dataIndex: "email",
      key: "email",
      flex: 1,
    },
    {
      title: "Phone Number",
      dataIndex: "mobile",
      key: "mobile",
      flex: 1,
    },
    // {
    //   title: "Department",
    //   key: "departmentList",
    //   dataIndex: "departmentList",
    //   flex: 1,
    //   render: (_) => {
    //     return (

    //         _.map((val, id) => (
    //           <div
    //             className={`${styles.container} ${val.id == 1 ? styles.green : val.id == 2 ? styles.red : styles.lightBlue
    //               }`}
    //           >
    //             {val}
    //           </div>
    //         ))


    //     );
    //   },
    // },
    {
      title: "Role",
      key: "roleName",
      dataIndex: "roleName",
      flex: 1,
    },
    {
      title: "Status",
      key: "active",
      dataIndex: "active",
      flex: 1,
      render: (_) => {
        return (
          <div
            className={`${styles.container} ${_ ? styles.green : styles.orange
              }`}
          >
            {_
              ? "Active"
              : "Inactive"}
          </div>
        );
      },
    },
    {
      title: "Action",
      key: "action",
      flex: 1,
      render: (_, record) => (
        <Space size="middle">
          {/* <DeleteFilled /> */}
          <EditFilled onClick={() => handleSelect(record)} />
        </Space>
      ),
    },
  ];

  return (
    <div className={styles.boxKPI}>
      <div className={styles.headerData}>
        <div className={styles.kpiText}>
          <ArrowLeftOutlined
            style={{ marginRight: "20px" }}
            onClick={handleClick}
          />
          User Master
        </div>
        <div>
          <Button type="primary" onClick={handleCreate} className={styles.btn}>
            + NEW EMPLOYEE
          </Button>
        </div>
      </div>
      <div className={styles.midContainer}>
        <div className={styles.inputData}>
          <Input
            placeholder="Search by Employee Name"
            onChange={(e) => onSearch(e)}
            prefix={<SearchOutlined />}
            style={{ width: "100%" }}
          />
        </div>

        {/* <div className={styles.dropData}>
          <div className={styles.dropDataText}>Department</div>
          <Select
            showSearch
            style={{
              width: "100%",
              margin: "0 10px",
            }}
            placeholder="Employee Name"
            optionFilterProp="children"
            filterOption={(input, option) =>
              (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
            filterSort={(optionA, optionB) =>
              (optionA?.label ?? "")
                .toLowerCase()
                .localeCompare((optionB?.label ?? "").toLowerCase())
            }
            allowClear
            // bordered={false}
            // onChange={handleFurnance}
            options={[
              {
                value: "jack",
                label: "Jack",
              },
              {
                value: "lucy",
                label: "Lucy",
              },
              {
                value: "Yiminghe",
                label: "yiminghe",
              },
            ]}
          // options={
          //   furnanceName &&
          //   furnanceName?.map((obj) => {
          //     const keys = Object.keys(obj);
          //     const convertedObj = {
          //       value: obj["furnaceName"],
          //       label: obj["furnaceName"],
          //     };

          //     for (let i = 2; i < keys.length; i++) {
          //       convertedObj[keys[i]] = obj[keys[i]];
          //     }

          //     return convertedObj;
          //   })
          // }
          />
        </div> */}
        <div className={styles.dropData}>
          <div className={styles.dropDataText}>Role</div>
          <Select
            showSearch
            style={{
              width: "100%",
              margin: "0 10px",
            }}
            placeholder="Role"
            optionFilterProp="children"
            filterOption={(input, option) =>
              (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
            filterSort={(optionA, optionB) =>
              (optionA?.label ?? "")
                .toLowerCase()
                .localeCompare((optionB?.label ?? "").toLowerCase())
            }
            allowClear
            // bordered={false}
            onChange={handleChange}
            options={
              role &&
              role?.map((obj) => {
                const keys = Object.keys(obj);
                const convertedObj = {
                  value: obj["roleId"],
                  label: obj["roleName"],
                };

                for (let i = 2; i < keys.length; i++) {
                  convertedObj[keys[i]] = obj[keys[i]];
                }

                return convertedObj;
              })
            }
          // options={
          //   furnanceName &&
          //   furnanceName?.map((obj) => {
          //     const keys = Object.keys(obj);
          //     const convertedObj = {
          //       value: obj["furnaceName"],
          //       label: obj["furnaceName"],
          //     };

          //     for (let i = 2; i < keys.length; i++) {
          //       convertedObj[keys[i]] = obj[keys[i]];
          //     }

          //     return convertedObj;
          //   })
          // }
          />
        </div>
        {/* <div className={styles.dropData}>
          <div className={styles.dropDataText}>Status</div>
          <Select
            showSearch
            style={{
              width: "100%",
              margin: "0 10px",
            }}
            placeholder="Status"
            optionFilterProp="children"
            filterOption={(input, option) =>
              (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
            filterSort={(optionA, optionB) =>
              (optionA?.label ?? "")
                .toLowerCase()
                .localeCompare((optionB?.label ?? "").toLowerCase())
            }
            allowClear
            // bordered={false}
            // onChange={handleFurnance}
            options={[
              {
                value: "jack",
                label: "Jack",
              },
              {
                value: "lucy",
                label: "Lucy",
              },
              {
                value: "Yiminghe",
                label: "yiminghe",
              },
            ]}
          // options={
          //   furnanceName &&
          //   furnanceName?.map((obj) => {
          //     const keys = Object.keys(obj);
          //     const convertedObj = {
          //       value: obj["furnaceName"],
          //       label: obj["furnaceName"],
          //     };

          //     for (let i = 2; i < keys.length; i++) {
          //       convertedObj[keys[i]] = obj[keys[i]];
          //     }

          //     return convertedObj;
          //   })
          // }
          />
        </div> */}
      </div>
      <div className={styles.tableData}>
        <Table columns={columns} dataSource={userList} pagination={false} />

        <div style={{ textAlign: "right", margin: "10px" }}>
          <Pagination {...paginationConfig} showSizeChanger={false}/>

        </div>
      </div>
    </div>
  );
}

export default UserList;

const data = [
  {
    empId: 101,
    employeeName: "David Long",
    department: 1, //1 --> Finance, 2 --> Sales, 3 --> Vehicle
    role: "Sr.Employee",
    joined: "12 Oct'23",
    emailId: "david@email.com",
    phoneNumber: '+253 45498549',
    status: 1, //1 --> Active, 2 --> Inactive, 3 --> Cancelled
  },
  {
    empId: 102,
    employeeName: "David Long",
    department: 3, //1 --> Finance, 2 --> Sales, 3 --> Vehicle
    role: "Sr.Employee",
    joined: "12 Oct'23",
    emailId: "david@email.com",
    phoneNumber: '+253 45498549',
    status: 2, //1 --> Active, 2 --> Inactive, 3 --> Cancelled
  }, {
    empId: 103,
    employeeName: "David Long",
    department: 2, //1 --> Finance, 2 --> Sales, 3 --> Vehicle
    role: "Sr.Employee",
    joined: "12 Oct'23",
    emailId: "david@email.com",
    phoneNumber: '+253 45498549',
    status: 2, //1 --> Active, 2 --> Inactive, 3 --> Cancelled
  }, {
    empId: 104,
    employeeName: "David Long",
    department: 1, //1 --> Finance, 2 --> Sales, 3 --> Vehicle
    role: "Sr.Employee",
    joined: "12 Oct'23",
    emailId: "david@email.com",
    phoneNumber: '+253 45498549',
    status: 1, //1 --> Active, 2 --> Inactive, 3 --> Cancelled
  }, {
    empId: 105,
    employeeName: "David Long",
    department: 1, //1 --> Finance, 2 --> Sales, 3 --> Vehicle
    role: "Sr.Employee",
    joined: "12 Oct'23",
    emailId: "david@email.com",
    phoneNumber: '+253 45498549',
    status: 1, //1 --> Active, 2 --> Inactive, 3 --> Cancelled
  }, {
    empId: 106,
    employeeName: "David Long",
    department: 1, //1 --> Finance, 2 --> Sales, 3 --> Vehicle
    role: "Sr.Employee",
    joined: "12 Oct'23",
    emailId: "david@email.com",
    phoneNumber: '+253 45498549',
    status: 1, //1 --> Active, 2 --> Inactive, 3 --> Cancelled
  }, {
    empId: 107,
    employeeName: "David Long",
    department: 1, //1 --> Finance, 2 --> Sales, 3 --> Vehicle
    role: "Sr.Employee",
    joined: "12 Oct'23",
    emailId: "david@email.com",
    phoneNumber: '+253 45498549',
    status: 1, //1 --> Active, 2 --> Inactive, 3 --> Cancelled
  }, {
    empId: 108,
    employeeName: "David Long",
    department: 1, //1 --> Finance, 2 --> Sales, 3 --> Vehicle
    role: "Sr.Employee",
    joined: "12 Oct'23",
    emailId: "david@email.com",
    phoneNumber: '+253 45498549',
    status: 1, //1 --> Active, 2 --> Inactive, 3 --> Cancelled
  },
];
