import React, { useContext, useEffect, useState } from "react";
import GoalList from "./GoalList";
import Graphs from "./Graphs";
import styles from "./index.module.css";
import Achievement from "../../Assets/Dashboard/achievement.png";
import AchievementInd from "../../Assets/Dashboard/achievement2.png";
import Department from "../../Assets/Dashboard/Department.png";
import PriorityChart from "./PriorityChart";
import Donut from "./Donut/Donut";
import { api_call_token } from "../../Utils/Network";
import { Select } from "antd";
import { UserCredsContext } from "../../Utils/UserCredsContext";

function Dashboard() {

  const [donutData, setDonutData] = useState([]);
  const [filterObj, setFilterObj] = useState({
    // page: 1,
  });
  const { userState } = useContext(UserCredsContext);
  const [userType, setUserType] = useState([]);
  const [department, setDepartment] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);

  console.log(userState?.role,"useState");

  useEffect(() => {
    // setDonutData([
    //   { name: "Pending", value: 80, color: '#ffc53d' },
    //   { name: "Approved", value: 10, color: '#3e9b4f' },
    //   { name: "Rejected", value: 20, color: '#e5484d' },
    // ])
    getallUser();
    getDonutData();
    getDepartment();
  }, [])

  const getallUser = () => {
    api_call_token
    .get(`/createuser/alluser?size=100`)
    .then((res) => {
      setUserType(res.data.data);
    })
    .catch((err) => console.log(err))
  }

  const getDepartment = () => {
    api_call_token
      .get(`department?size=100`)
      .then((res) => {
        setDepartment(res.data.data);
      })
      .catch((err) => console.log(err));
  };

  const getDonutData=() => {
    api_call_token.get(`assigned`)
    .then((response) => {
      if (response?.data){
        setDonutData(response?.data)
      }
    }).catch((error) => {
      console.log(error)
    })
  }

  console.log(donutData, "donuDaaaa")
  return (
    <div style={{margin: '10px 0'}}>
      <h2>Dashboard</h2>

      <div className={styles.mainProgress}>
        <div className={styles.innerContain}>
          <div className={styles.innerContent}>
            <div style={{display:"flex", justifyContent:"space-between"}}>
            <h3>Goal Progress</h3>
            {
              userState?.role === 1 && (
                <div style={{width:"50%", display:"flex", justifyContent:'space-between'}}>
                <div>
                <span>User</span>
              <Select
                showSearch
                style={{
                  width: "100%",
                }}
                placeholder="User"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? "")
                    .toLowerCase()
                    .localeCompare((optionB?.label ?? "").toLowerCase())
                }
                allowClear
                onChange={(e) => {
                  let value = e;
                  let obj = filterObj;
                  if (value) {
                    obj.userFilter = value;
                    // obj.page = 1;
                    // setCurrentPage(1);
                  } else delete obj.userFilter;
                  setFilterObj({ ...obj });
                }}
                options={
                  userType &&
                  userType?.map((obj) => {
                    const keys = Object.keys(obj);
                    const convertedObj = {
                      value: obj["userId"],
                      label: `${obj["firstName"]} ${obj["lastName"]}`,
                    };
  
                    for (let i = 2; i < keys.length; i++) {
                      convertedObj[keys[i]] = obj[keys[i]];
                    }
  
                    return convertedObj;
                  })
                }
              />
              </div>
              <div>
               <span>Department</span>
            <Select
              showSearch
              style={{
                width: "100%",
              }}
              placeholder="Department"
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              filterSort={(optionA, optionB) =>
                (optionA?.label ?? "")
                  .toLowerCase()
                  .localeCompare((optionB?.label ?? "").toLowerCase())
              }
              allowClear
              onChange={(e) => {
                let value = e;
                let obj = filterObj;
                if (value) {
                  obj.departmentFilter = value;
                  obj.page = 1;
                  setCurrentPage(1);
                } else delete obj.departmentFilter;
                setFilterObj({ ...obj });
              }}
              options={
                department &&
                department?.map((obj) => {
                  const keys = Object.keys(obj);
                  const convertedObj = {
                    value: obj["departmentId"],
                    label: obj["departmentName"],
                  };

                  for (let i = 2; i < keys.length; i++) {
                    convertedObj[keys[i]] = obj[keys[i]];
                  }

                  return convertedObj;
                })
              }
            />
              </div>
                </div>
              )
            }
              
              </div>

            <Graphs  filterObj={filterObj}/>
          </div>
          <div className={styles.innerContent}>
            <h3>Kpi by Priority</h3>
            <div>
              <PriorityChart />
            </div>
          </div>
        </div>
        <div className={styles.innerContainSecond}>
          

          <div className={styles.innerContent}>
            <h3>Kpi of Assigned Issue</h3>
            <div style={{ display: 'flex' }}>
              <Donut data={donutData} />
              <div>
                {donutData && donutData.map((v, i) => (
                  <div style={{ display: 'flex', margin: '10px' }}>
                    <div style={{ width: '20px', height: '20px', backgroundColor: `${v.color}`, borderRadius: '50%', marginRight: '10px' }}></div>
                    {v.name}
                  </div>
                ))}
              </div>

            </div>
          </div>

          <div className={styles.innerContent}>
            <h3>Recent Achievements</h3>
            <div className={styles.mainAchievement}>
              <div className={styles.boxdata}>
                <img
                  src={Achievement}
                  alt="achievement"
                  className={styles.img}
                />
                <p className={styles.colorWhite}>
                  <span>10</span> targets Achieved Overall
                </p>
              </div>

              <div className={`${styles.boxdata} ${styles.green}`}>
                <img
                  src={AchievementInd}
                  alt="achievement"
                  className={styles.img}
                />
                <p>3/5</p>
                <p className={styles.colorWhite}>Individual Target Achieved</p>
              </div>

              <div className={`${styles.boxdata} ${styles.orangeDiv}`}>
                <img
                  src={Department}
                  alt="achievement"
                  className={styles.img}
                />
                <p>3/5</p>
                <p className={styles.colorWhite}>Department Target Achieved</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <GoalList />
    </div>
  );
}

export default Dashboard;
