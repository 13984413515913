import React, { useEffect, useState } from "react";
import { api_call_token } from "../../../../Utils/Network";
import { Button, Col, Form, Input, Pagination, Row, Space, Table } from "antd";
import { ArrowLeftOutlined, DeleteFilled, EditFilled, SearchOutlined } from "@ant-design/icons";
import styles from "./index.module.css";
import { useNavigate } from "react-router-dom";
import Modals from "../../../../Component/Modals/Modals";
import { isPlainObject } from "@reduxjs/toolkit";
import { isObject } from "../../../../Utils/helperFunction";
import CategoryForm from "./CategoryForm";

function CategoryList() {
  const [categoryList, setCategoryList] = useState();
  const [open, setOpen] = useState(false);
  const [field, setFields] = useState(null);
  const [vals, setVals] = useState(null);
  const [filterObj, setFilterObj] = useState({
    page: 1,
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [totalNumberofPage, setTotalNumberofPage] = useState(null);
  const [form] = Form.useForm();

  let navigate = useNavigate();

  useEffect(() => {
    getCategoryList();
  }, [filterObj]);

  const getCategoryList = (paramObj = { ...filterObj }) => {
    api_call_token
      .get(`category` , { params: { ...paramObj } })
      .then((res) => {
        setCategoryList(res.data.data);
        setCurrentPage(res.data.currentPage);
        setTotalNumberofPage(res.data.totalRowCount);
      })
      .catch((err) => console.log(err));
  };

  const handleClick = () => {
    navigate(`/master`);
  };

  const handleform = () => {
    setFields({});
    setOpen(false);
    setVals(0);
  };

  const openModel = () => {
    setFields({ active: 1 });
    setVals(1);
    setOpen(true);
  };

  const handleOpenModal = (record) => {
    if (record !== undefined && record !== null && isObject(record)) {
      setFields(record);
    }
    setVals(1);
    setOpen(true);
  };

  const columns = [
    {
      title: "Category ID",
      dataIndex: "categoryId",
      key: "categoryId",
    },
    {
      title: "Name",
      dataIndex: "categoryName",
      key: "categoryName",
    },
    {
      title: "Category Order",
      dataIndex: "categoryOrder",
      key: "categoryOrder",
    },
    {
      title: "Status",
      key: "active",
      dataIndex: "active",
      render: (_) => {
        return (
          <div
            className={`${styles.container} ${_ ? styles.green : styles.orange
              }`}
          >
            {_ ? "Active" : "Inactive"}
          </div>
        );
      },
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <DeleteFilled />
          <EditFilled onClick={() => handleOpenModal(record)} />
        </Space>
      ),
    },
  ];

  const handlePageChange = (page) => {
    console.log(page, "aaaaaaaaa");
    setFilterObj({ ...filterObj, page: page });
    setCurrentPage(page);
  };

  const paginationConfig = {
    current: currentPage,
    pageSize: 10, // Number of items per page
    total: totalNumberofPage, // Total number of items
    onChange: handlePageChange,
  };


  const onSearch = (e) => {
    let value = e.target.value;
    let obj = filterObj;
    if (value) { obj.search = value; setCurrentPage(1) }
    else delete obj.search;
    setFilterObj({ ...obj, page: 1 });
  }

  console.log(field, "FIELSSSSSS");
  return (
    <div className={styles.boxKPI}>
      <div className={styles.headerData}>
        <div className={styles.kpiText}>
          <ArrowLeftOutlined
            style={{ marginRight: "20px" }}
            onClick={handleClick}
          />
          Category Master
        </div>
        <div>
          <Button
            type="primary"
            className={styles.btn}
            onClick={() => openModel()}
          >
            + NEW CATEGORY
          </Button>
        </div>
      </div>
      <div className={styles.midContainer}>
        <div className={styles.inputData}>
          <Input
            placeholder="Search by Category Name"
            prefix={<SearchOutlined />}
            style={{ width: "100%" }}
            onChange={(e) => onSearch(e)}
          />
        </div>
      </div>
      <div className={styles.tableData}>
        <Table columns={columns} dataSource={categoryList} pagination={false} />

        <div style={{ textAlign: "right", margin: "10px" }}>
          <Pagination {...paginationConfig} showSizeChanger={false}/>
        </div>
      </div>

      {open && (
        <Modals
          open={open}
          handleCancel={() => handleform()
          }
        >
          {vals == 1 && <CategoryForm field={field} key={1} form={form} getCategoryList={getCategoryList} setOpen={setOpen} />}
        </Modals>
      )}
    </div>
  );
}

export default CategoryList;
