import { Button, Col, Row, Form, Input, Switch } from "antd";
import React, { useContext, useState } from "react";
import { alertMessage } from "../../../../Utils/helperFunction";
import { api_call_token } from "../../../../Utils/Network";
import { UserCredsContext } from "../../../../Utils/UserCredsContext";

function CategoryForm({
  field,
  key,
  getCategoryList = () => {},
  setOpen = () => {},
}) {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const { logout } = useContext(UserCredsContext);
  const handleData = (vals) => {
    if (field?.categoryId) {
      setIsLoading(true);
      api_call_token
        .patch(`category/${field?.categoryId}`, {
          ...vals,
          active: vals?.active ? 1 : 0,
        })
        .then((res) => {
          if (res.status == 201 || res.status == 200 || res.status == 204) {
            getCategoryList();
            form.resetFields();
            setIsLoading(false);
            setOpen(false);
          } else if (res.status == 403) {
            alertMessage("Updating a Category is restricted for users.", 2);
            setOpen(false);
          } else if (res.status == 401) {
            logout();
          }
        })
        .catch((err) => console.log(err));
    } else {
      api_call_token
        .post(`category`, { ...vals, active: vals?.active ? 1 : 0 })
        .then((res) => {
          if (res.status == 201 || res.status == 200 || res.status == 204) {
            getCategoryList();
            form.resetFields();
            setIsLoading(false);
            setOpen(false);
          } else if (res.status == 403) {
            alertMessage("Creating a Category is restricted for users.", 2);
            setOpen(false);
          } else if (res.status == 401) {
            logout();
          }
        })
        .catch((err) => {
          setIsLoading(false);
          console.log(err);
        });
    }
  };

  console.log(field, "dfdsdfsdfs");
  return (
    <div>
      <h1>Category</h1>
      <Form form={form} initialValues={field} key={key} onFinish={handleData}>
        <Row>
          <Col xs={24} md={24} lg={24}>
            <span>Category Name</span>
            <Form.Item name="categoryName">
              <Input placeholder="Category Name" />
            </Form.Item>
          </Col>
          <Col xs={24} md={24} lg={24}>
            <span>Category Order</span>
            <Form.Item name="categoryOrder">
              <Input placeholder="Category Order" type="number" />
            </Form.Item>
          </Col>
          <Col xs={24} md={24} lg={24}>
            <span>Active Status</span>
            {field?.active == "true" ? (
              <Form.Item name="active">
                <Switch
                  checkedChildren="Yes"
                  unCheckedChildren="No"
                  defaultChecked
                />
              </Form.Item>
            ) : (
              <Form.Item name="active">
                <Switch checkedChildren="Yes" unCheckedChildren="No" />
              </Form.Item>
            )}
          </Col>

          <Col>
            <Button type="primary" htmlType="submit" loading={isLoading}>
              Submit
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
}

export default CategoryForm;
