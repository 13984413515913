import React, { useState, useEffect } from 'react'
import { Outlet, useNavigate } from 'react-router-dom';
import Layout from '../Component/Layout/Layout';
// import Breadcrumbs from '../Components/Breadcrumbs/Breadcrumbs';
import styles from './index.module.css';
import Menu from '../Assets/ICONS/menus.png';

function RouteLayout() {
  const [visible, setVisible] = useState(false);
  let navigate = useNavigate();

  const handleData = () => {
    setVisible(!visible)
  }

  useEffect(() => {
    console.log(window.location, "djfdfdkdfjdkj")
    // navigate('/dashboard')
    const handleResize = () => {
      const screenWidth = window.innerWidth;
      setVisible(screenWidth > 768);
    };

    // Add event listener to track window resize
    window.addEventListener('resize', handleResize);

    // Set initial value based on screen width
    handleResize();

    // Cleanup: remove event listener when component unmounts
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div>
      <div onClick={() => handleData()} className={styles.menuRoute}><img src={Menu} alt="images" className={styles.menus}/></div>
      <div style={{ display: 'flex' }}>
        <Layout visible={visible} setVisible={setVisible} />
        <div className={styles.content}>
          <Outlet />
        </div>
      </div>
    </div>

  )
}

export default RouteLayout