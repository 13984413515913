import React, { Component, createContext } from "react";
import { api_call_token } from "./Network";
// import { createTheme } from "@mui/material/styles";
// import { api_open, api_token } from "../../Utils/Network";
export const UserCredsContext = createContext();

const localstorage_state = localStorage.getItem("dmc_user");

function getLocalData(keyname) {
  // debugger;
  // Function to return values from local storage

  let object = null;

  try {
    object = JSON.parse(localstorage_state);
  } catch {
    console.error("There was error while parsing data from localstorage.");
  }

  if (object) {
    if (object[keyname]) {
      return object[keyname];
    }
  }

  if (keyname === "themeMode") return "dark";
  if (keyname === "user_state") return {};
  if (keyname === "token") return "";
  if (keyname === "statusMessage") return false;
  if (keyname === "menu_state") return [];
  if (keyname === "nftData") return {};
  return "";
}

class UserCredsContextProvider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user_state: getLocalData("user_state"),
      token: getLocalData("token"),
      statusMessage: getLocalData('statusMessage'),
      menu_state: getLocalData("menu_state"),
    };
    // this.setUserState = this.setUserState.bind(this);
    this.setUsers = this.setUsers.bind(this);
    this.setMenuState = this.setMenuState.bind(this);
    this.setToken = this.setToken.bind(this);
    this.setStatus = this.setStatus.bind(this);
    this.setBase = this.setBase.bind(this);
  }

  componentDidMount() {
    this.setBase();
    window.addEventListener("beforeunload", () => {
      localStorage.setItem("dmc_user", JSON.stringify(this.state));
    });

  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.user_state !== this.state.user_state) {
      // localStorage.setItem("user_cred_context", JSON.stringify(this.state));
    }
  }


  componentWillUnmount() { }
  setBase = () => {
    // api_call_token
    //   .get(`category`)
    //   .then((res) => {
    //     // setCategoryList(res.data.data);
    //   })
    //   .catch((err) => console.log(err));
  }
  // setUserState = (user_data, token_data, menu_state, statusMessage) => {
  //   this.setState({ user_state: user_data, token: token_data, menu_state: menu_state, statusMessage: statusMessage });
  // };

  setUsers = (user_datas) => {
    this.setState({ user_state: user_datas})
  }

  setStatus = (user_datas) => {
    this.setState({ statusMessage: user_datas})
  }

  setToken(token_data) {
    this.setState({ token: token_data })
  }
  
  setMenuState(menu_state) {
    this.setState({menu_state: menu_state})
  }

  logout = () => {
    this.setState(
      {
        themeMode: "dark",
        user_state: {},
        token: "",
        menu_state: [],
      },
      () => {
        localStorage.clear("dmc_user");
        setTimeout(() => {
          window.location.href = "/login";

        }, 500);
      }
    );
  };

  render() {
    return (
      <UserCredsContext.Provider
        value={{
          // setUserState: this.setUserState,
          setUsers: this.setUsers,
          setMenuState: this.setMenuState,
          setStatus: this.setStatus,
          menu_state: this.state.menu_state,
          // setUser: this.setUser,
          logout: this.logout,
          userState: this.state.user_state,
          token_data: this.state.token,
          setToken: this.setToken,
        }}
      >
        {this.props.children}
      </UserCredsContext.Provider>
    );
  }
}

export default UserCredsContextProvider;
