import React, { useEffect, useState } from 'react';
import { Form, Input, Select, Row, Col, Upload, Button, message, Switch } from 'antd';
import { ArrowLeftOutlined, CameraFilled, CameraOutlined, UploadOutlined } from '@ant-design/icons';
import { api_call_token, base_url, formDataApi } from '../../../../Utils/Network';
import styles from './index.module.css';
import { useLocation, useNavigate } from 'react-router-dom';
import User from '../../../../Assets/profile/users.png';
import { alertMessage } from '../../../../Utils/helperFunction';

const { Option } = Select;

const UserForm = () => {

  let navigate = useNavigate();
  const [department, setDepartment] = useState([]);
  const [role, setRole] = useState([]);
  const [menus, setMenus] = useState([]);
  let location = useLocation();

  console.log(location, "Location")

  useEffect(() => {
    getRole();
    getDepartments();
    getMenus();
  }, [])

  const getMenus = () => {
    api_call_token
      .get(`menulist/all`)
      .then((res) => {
        setMenus(res.data.data);
      })
      .catch(err => console.log(err))
  }

  const getRole = () => {
    api_call_token
      .get(`roles`)
      .then((res) => {
        setRole(res.data.data);
      })
      .catch((err) => console.log(err))
  }

  const getDepartments = () => {
    api_call_token
      .get(`department?size=100`)
      .then((res) => {
        setDepartment(res.data.data);
      })
      .catch((err) => console.log(err))
  }

  const onFinish = (values) => {
    console.log('Received values:', values);
    let arr = []
    if (values?.menuList.length > 0) {

      const filteredArray = menus.filter(item => values?.menuList.includes(item.menuId));
      console.log(filteredArray)
      arr = [...filteredArray];
    }

    const { active, profileImage,username , ...rest } = values;
    let empUserDatanew = {}

    if(location?.state?.userId){
      empUserDatanew = { ...rest, menuList: arr, active: (active) ? 1 : 0 , username: location?.state?.username}
    } else{
      empUserDatanew = { ...rest, menuList: arr, active: (active) ? 1 : 0}
    }

    const data = empUserDatanew
    console.log(data, "HHHH")
    if (location?.state?.userId) {
      api_call_token
        .patch(`createuser/${location?.state?.userId}`, data)
        .then((res) => {
          console.log(res)
          if (res.data.id) {
            if (profileImage) {
              let data = {
                userName: rest.firstName,
                userId: res.data.id,
                userImageFile: profileImage,
              }
              let result = UploadImage(data)
              if (result) {
                alertMessage("User Updated ")
                setTimeout(() => {
                  navigate('/master/user')
                }, 2000);
              } else {
                alertMessage('Something went wrong', 2)
              }
            } else {
              alertMessage("User Updated ")
              setTimeout(() => {
                navigate('/master/user')
              }, 2000);
            }

          } else {
            alertMessage('Something went wrong', 2)
          }
        })
        .catch(err => console.log(err))

    } else {
      api_call_token
        .post(`createuser`, data)
        .then((res) => {
          console.log(res)
          if (res.data.result.responsecode == 409) {
            alertMessage(res.data.result.message, 2);
          } else {
            if (profileImage) {
              const { firstName, userId, ...rest } = res.data.result;
              let data = {
                userName: firstName,
                userId,
                userImageFile: profileImage,
              }
              let result = UploadImage(data)
              if (result) {
                alertMessage("User Updated ")
                setTimeout(() => {
                  navigate('/master/user')
                }, 2000);
              } else {
                alertMessage('Something went wrong', 2)
              }
            } else {
              alertMessage("User Created ")
              setTimeout(() => {
                navigate('/master/user')
              }, 2000);
            }
          }

        })
        .catch(err => console.log(err))

    }

    // You can add your logic to handle form submission here
  };

  const UploadImage = async (datas) => {
    const fmDatas = new FormData();
    for (const key in datas) {
      if (datas.hasOwnProperty(key)) {
        const value = datas[key];
        if (value !== undefined) {
          if (Array.isArray(value)) {
            // Handle arrays separately
            value.forEach((item, index) => {
              if (item !== undefined && item.originFileObj !== undefined) {
                fmDatas.append(key, item.originFileObj, `${key}_${index}`);
              }
            });
          } else {
            fmDatas.append(key, value);
          }
        }
      }
    }

    console.log(...fmDatas)
    let apiEnds = "";

    const res = await formDataApi(
      apiEnds,
      fmDatas,
      false,
      `usermyimageupload`
    );
    console.log(res, "ressss");
    if (res.status == 200 || res.status == 201 || res.status == 204 || res.supplierId) {
      if (res.data.userId) {
        return 1
      } else {
        return 0
      }
      // setOpen(false)
      // getViewofPurchase();
    }
  }

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const handleClick = () => {
    navigate(`/master/user`)
  }

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  const uploadProps = {
    name: 'file',
    action: 'https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188',
    onChange(info) {
      if (info.file.status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
      // if (info.file.status === 'done') {
      //   message.success(`${info.file.name} file uploaded successfully`);
      // } else if (info.file.status === 'error') {
      //   message.error(`${info.file.name} file upload failed.`);
      // }
    },
  };

  return (
    <div className={styles.mainContainer}>
      <div className={styles.headerData}>
        <div className={styles.kpiText}>
          <ArrowLeftOutlined
            style={{ marginRight: "20px" }}
            onClick={handleClick}
          />
          Employee Detail
        </div>
      </div>
      <div className={styles.userInfo}>
        <Form
          name="userForm"
          initialValues={{
            ...location?.state, departmentIds: location?.state?.departmentList?.map(
              (obj) => obj?.departmentId
            ), menuList: location?.state?.userMenuList?.map(
              (obj) => obj?.id
            ),
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 20 }}
        >
          <Row gutter={16}>
            {/* Profile Image Section */}
            <Col span={8} style={{ textAlign: 'center' }}>
              <Form.Item
                name="profileImage"
                valuePropName="fileList"
                getValueFromEvent={normFile}
              >
                <Upload {...uploadProps} accept=".png,.jpg,.jpeg,.svg">
                  <div style={{ width: 200, height: 200, borderRadius: '50%', position: 'relative' }}>
                    <img
                      src={(location?.state?.userImageName) ? `${base_url}userimage/${location?.state?.userImageName}` : User}
                      alt="profile"
                      className={styles?.profiles}
                    />
                    <div
                      style={{
                        position: 'absolute',
                        bottom: 0,
                        right: 0,
                        transform: 'translate(-50%, -50%)',
                      }}
                    >
                      <CameraFilled style={{ fontSize: 24, color: '#1890ff' }}/>
                    </div>
                  </div>
                </Upload>
              </Form.Item>
            </Col>

            {/* User Information Section */}
            <Col span={16}>
              <Row gutter={16}>
                <Col span={12}>
                  <span>First Name</span>
                  <Form.Item name="firstName" rules={[
                    {
                      required: true,
                      message: 'Please Enter First Name!',
                    },
                  ]}>
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <span>Last Name</span>

                  <Form.Item name="lastName" rules={[
                    {
                      required: true,
                      message: 'Please Enter Last Name!',
                    },
                  ]}>
                    <Input />
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <span>Mobile Number</span>

                  <Form.Item name="mobile" rules={[
                    {
                      required: true,
                      message: 'Please Enter Mobile Number!',
                    },
                  ]}>
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <span>Email</span>
                  <Form.Item name="email" rules={[
                    {
                      required: true,
                      message: 'Please Enter Email Id!',
                    },
                  ]}>
                    <Input />
                  </Form.Item>
                </Col>

                {location?.state?.userId ? <></> : <Col span={12}>
                  <span>Password</span>
                  <Form.Item name="password" rules={[
                    {
                      required: true,
                      message: 'Please Enter your Password!',
                    },
                  ]}>
                    <Input.Password />
                  </Form.Item>
                </Col>}
                <Col span={12}>
                  <span>Department</span>
                  <Form.Item name="departmentIds" rules={[
                    {
                      required: true,
                      message: 'Please select Department!',
                    },
                  ]}>
                    <Select
                      mode="multiple"
                      showSearch
                      style={{ width: "100%" }}
                      placeholder="Search to Select"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        (option?.label ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      filterSort={(optionA, optionB) =>
                        (optionA?.label ?? "")
                          .toLowerCase()
                          .localeCompare(
                            (optionB?.label ?? "").toLowerCase()
                          )
                      }
                      allowClear
                      // onChange={(e) => handleAdd(e, "departmentIds")}
                      options={
                        department &&
                        department?.map((obj) => {
                          const keys = Object.keys(obj);
                          const convertedObj = {
                            value: obj["departmentId"],
                            label: obj["departmentName"],
                          };

                          for (let i = 2; i < keys.length; i++) {
                            convertedObj[keys[i]] = obj[keys[i]];
                          }

                          return convertedObj;
                        })
                      }
                    />
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <span>Role</span>
                  <Form.Item name="role" rules={[
                    {
                      required: true,
                      message: 'Please select your role!',
                    },
                  ]}>
                    <Select
                      showSearch
                      style={{ width: "100%" }}
                      placeholder="Search to Select"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        (option?.label ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      filterSort={(optionA, optionB) =>
                        (optionA?.label ?? "")
                          .toLowerCase()
                          .localeCompare(
                            (optionB?.label ?? "").toLowerCase()
                          )
                      }
                      allowClear
                      // onChange={(e) => handleAdd(e, "departmentIds")}
                      options={
                        role &&
                        role?.map((obj) => {
                          const keys = Object.keys(obj);
                          const convertedObj = {
                            value: obj["roleId"],
                            label: obj["roleName"],
                          };

                          for (let i = 2; i < keys.length; i++) {
                            convertedObj[keys[i]] = obj[keys[i]];
                          }

                          return convertedObj;
                        })
                      }
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <span>Menu Selection</span>
                  <Form.Item name="menuList" rules={[
                    {
                      required: true,
                      message: 'Please select Menu!',
                    },
                  ]}>
                    <Select
                      mode="multiple"
                      showSearch
                      style={{ width: "100%" }}
                      placeholder="Search to Select"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        (option?.label ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      filterSort={(optionA, optionB) =>
                        (optionA?.label ?? "")
                          .toLowerCase()
                          .localeCompare(
                            (optionB?.label ?? "").toLowerCase()
                          )
                      }
                      allowClear
                      // onChange={(e) => handleAdd(e, "departmentIds")}
                      options={
                        menus &&
                        menus?.map((obj) => {
                          const keys = Object.keys(obj);
                          const convertedObj = {
                            value: obj["menuId"],
                            label: obj["menuName"],
                          };

                          for (let i = 2; i < keys.length; i++) {
                            convertedObj[keys[i]] = obj[keys[i]];
                          }

                          return convertedObj;
                        })
                      }
                    />
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <span>Gender</span>
                  <Form.Item name="gender">
                    <Select>
                      <Option value="male">Male</Option>
                      <Option value="female">Female</Option>
                      {/* Add other gender options as needed */}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <span>Current Status</span>
                  <Form.Item name="active">
                    <Switch />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>

          <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
            <Button type="primary" htmlType="submit">
              {location?.state?.userId ? "Update" : 
              "Submit"}
            </Button>
          </Form.Item>
        </Form>
      </div>

    </div>
  );
};

export default UserForm;
