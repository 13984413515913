import { ArrowLeftOutlined } from "@ant-design/icons";
import { Button, Col, DatePicker, Form, Input, Row, Select, Switch } from "antd";
import TextArea from "antd/es/input/TextArea";
import React, { useEffect, useState, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styles from "./index.module.css";
import moment from "moment";
import { api_call_token } from "../../../../Utils/Network";
import { UserCredsContext } from "../../../../Utils/UserCredsContext";
import { alertMessage } from "../../../../Utils/helperFunction";

function CreateKPI() {
  const [form] = Form.useForm();
  const [uom, setUom] = useState([]);
  const [goalType, setGoalType] = useState([]);
  const [categoryType, setCategory] = useState([]);
  const [department, setDepartment] = useState([]);
  const { userState, logout } = useContext(UserCredsContext);
  const [isLoading, setIsLoading] = useState(false);

  let location = useLocation();

  let navigate = useNavigate();

  useEffect(() => {
    getUom();
    getKpi();
    getCategory();
    getDepartment();
  }, [])

  const getDepartment = () => {
    api_call_token
      .get(`department?size=100`)
      .then((res) => {
        setDepartment(res.data.data)
      })
      .catch((err) => console.log(err))
  }

  const getCategory = () => {
    api_call_token
      .get(`category?size=100`)
      .then((res) => {
        setCategory(res.data.data)
      })
      .catch((err) => console.log(err))
  }

  const getKpi = () => {
    api_call_token
      .get(`goaltype?size=100`)
      .then((res) => {
        setGoalType(res.data.data)
      })
      .catch((err) => console.log(err))
  }

  const getUom = () => {
    api_call_token
      .get(`uom?size=100`)
      .then((res) => {
        setUom(res.data.data)
      })
      .catch((err) => console.log(err))
  }

  const handleCancel = () => {
    navigate('/master/kpi')
  }

  const handleFinish = (values) => {
    console.log(values)
    for (const key in values) {
      if (values.hasOwnProperty(key)) {
        const value = values[key];
        if (Array.isArray(value)) {
          continue;
        }
        if (typeof value === 'object' && (value.$d instanceof Date || value._d instanceof Date)) {
          if (value.$d) {
            values[key] = moment(value.$d).format('YYYY-MM-DD');
          } else {
            values[key] = moment(value._d).format('YYYY-MM-DD');
          }
        } else if (typeof value === 'string' && !isNaN(value)) {
          values[key] = Number(value);
        }
      }
    }
    console.log(values)
    const { createdAt, createdByName, ...rest } = values;

    console.log(rest, "DEMOSSSSSSS")
    if (location?.state?.data?.kpiMasterId) {
      api_call_token
        .patch(`kpimaster/${location?.state?.data?.kpiMasterId}`, { ...rest, priorityId: 1, active: (values?.active) ? 1 : 0 })
        .then((res) => {
          if (res.status == 200 || res.status == 201 || res.status == 204) {
            navigate('/master/kpi')
            setIsLoading(false);
          } else if (res.status == 403){
            alertMessage("Updating a KPI is restricted for users.", 2)
          } else if (res.status == 401) {
            logout();
          }
        })
        .catch((err) => {
          setIsLoading(false);
          console.log(err)
        })
    } else {
      api_call_token
        .post(`kpimaster`, { ...rest, priorityId: 1, active: (values?.active) ? 1 : 0 })
        .then((res) => {
          if (res.status == 200 || res.status == 201) {
            navigate('/master/kpi')
            setIsLoading(false);
          } else if (res.status == 403){
            alertMessage("Creating a KPI is restricted for users.", 2)
          } else if (res.status == 401) {
            logout();
          }
        })
        .catch((err) => {
          setIsLoading(false);
          console.log(err)
        })
    }

  };

  console.log(location, "UserState")
  return (
    <div className={styles.boxKPI}>
      <div className={styles.headerData}>
        <div className={styles.kpiText}>
          <ArrowLeftOutlined
            style={{ marginRight: "20px" }}
            onClick={handleCancel}
          />
          Create New KPI
        </div>
      </div>
      <div>
        <p className={`${styles.kpiText} ${styles.fontW}`}>Set KPI Details</p>
      </div>
      <div className={styles.formBox}>
        <Form
          onFinish={handleFinish}
          form={form}
          initialValues={{
            ...location?.state?.data,
            departmentIds: location?.state?.data?.departmentList?.map((obj) => obj?.departmentId),
            createdByName: location?.state?.data?.createdByName ? location?.state?.data?.createdByName : `${userState?.firstName} ${userState?.lastName}`,
            kpiDueDate: location?.state?.data?.kpiDueDate
              ? moment(
                location?.state?.data?.kpiDueDate,
                "YYYY-MM-DD"
              )
              : undefined,
            kpiStartDate: location?.state?.data?.kpiStartDate
              ? moment(
                location?.state?.data?.kpiStartDate,
                "YYYY-MM-DD"
              )
              : undefined,
            createdAt: location?.state?.data?.createdAt ? moment(
              location?.state?.data?.createdAt,
              "YYYY-MM-DD"
            )
              : moment(),
            priorityId: 1,
            // createdAt: 
          }}
        >
          <Row gutter={[26, 7]}>
            <Col xs={24} md={14} lg={12}>
              <span>KPI Name</span>
              <Form.Item name="kpiName">
                <Input placeholder="KPI Name" />
              </Form.Item>
            </Col>
            <Col xs={24} md={14} lg={6}>
              <span>KPI Weightage</span>
              <Form.Item name="kpiWeightage">
                <Input placeholder="KPI Weightage" />
              </Form.Item>
            </Col>
            <Col xs={24} md={14} lg={6}>
              <span>Level</span>
              <Form.Item name="kpiLevel">
                <Select
                  showSearch
                  style={{
                    width: "100%",
                  }}
                  placeholder="Goal Type"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
                  }
                  filterSort={(optionA, optionB) =>
                    (optionA?.label ?? "")
                      .toLowerCase()
                      .localeCompare((optionB?.label ?? "").toLowerCase())
                  }
                  allowClear
                  options={
                    [
                      {
                        value: "1",
                        label: "Low",
                      },
                      {
                        value: "2",
                        label: "Medium",
                      },
                      {
                        value: "3",
                        label: "high",
                      },
                    ]
                  }
                />
              </Form.Item>
            </Col>
            <Col xs={24} md={24} lg={24}>
              <span>KPI Description</span>
              <Form.Item name="kpiDescription">
                <TextArea rows={4} placeholder="KPI Description" />
              </Form.Item>
            </Col>
            <Col xs={24} md={14} lg={6}>
              <span>Target</span>
              <Form.Item name="kpiTarget">
                <Input placeholder="Target" type="number" />
              </Form.Item>
            </Col>
            <Col xs={24} md={14} lg={6}>
              <span>UOM</span>
              <Form.Item name="uomId">
                <Select
                  showSearch
                  style={{
                    width: "100%",
                  }}
                  placeholder="UOM"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
                  }
                  filterSort={(optionA, optionB) =>
                    (optionA?.label ?? "")
                      .toLowerCase()
                      .localeCompare((optionB?.label ?? "").toLowerCase())
                  }
                  allowClear
                  options={
                    uom &&
                    uom?.map((obj) => {
                      const keys = Object.keys(obj);
                      const convertedObj = {
                        value: obj["uomId"],
                        label: obj["uomName"],
                      };

                      for (let i = 2; i < keys.length; i++) {
                        convertedObj[keys[i]] = obj[keys[i]];
                      }

                      return convertedObj;
                    })
                  }
                />
              </Form.Item>
            </Col>
            <Col xs={24} md={14} lg={6}>
              <span>Target Achievement</span>
              <Form.Item name="kpiTargetAchievement">
                <Select
                  showSearch
                  style={{
                    width: "100%",
                  }}
                  placeholder="Target Achievement"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
                  }
                  filterSort={(optionA, optionB) =>
                    (optionA?.label ?? "")
                      .toLowerCase()
                      .localeCompare((optionB?.label ?? "").toLowerCase())
                  }
                  allowClear
                  options={[
                    {
                      value: "Overachievement",
                      label: "Overachievement",
                    },
                    {
                      value: "Underachievement",
                      label: "Underachievement",
                    }
                  ]}
                />
              </Form.Item>
            </Col>

            <Col xs={24} md={14} lg={6}>
              <span>Category</span>
              <Form.Item name="kpiCategory">
                <Select
                  showSearch
                  style={{
                    width: "100%",
                  }}
                  placeholder="Category"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
                  }
                  filterSort={(optionA, optionB) =>
                    (optionA?.label ?? "")
                      .toLowerCase()
                      .localeCompare((optionB?.label ?? "").toLowerCase())
                  }
                  allowClear
                  options={
                    categoryType &&
                    categoryType?.map((obj) => {
                      const keys = Object.keys(obj);
                      const convertedObj = {
                        value: obj["categoryId"],
                        label: obj["categoryName"],
                      };

                      for (let i = 2; i < keys.length; i++) {
                        convertedObj[keys[i]] = obj[keys[i]];
                      }

                      return convertedObj;
                    })
                  }
                />
              </Form.Item>
            </Col>

            <Col xs={24} md={14} lg={6}>
              <span>Department</span>
              <Form.Item name="departmentIds">
                <Select
                  mode="multiple"
                  showSearch
                  style={{
                    width: "100%",
                  }}
                  placeholder="Department"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
                  }
                  filterSort={(optionA, optionB) =>
                    (optionA?.label ?? "")
                      .toLowerCase()
                      .localeCompare((optionB?.label ?? "").toLowerCase())
                  }
                  allowClear
                  options={
                    department &&
                    department?.map((obj) => {
                      const keys = Object.keys(obj);
                      const convertedObj = {
                        value: obj["departmentId"],
                        label: obj["departmentName"],
                      };

                      for (let i = 2; i < keys.length; i++) {
                        convertedObj[keys[i]] = obj[keys[i]];
                      }

                      return convertedObj;
                    })
                  }
                />
              </Form.Item>
            </Col>

            <Col xs={24} md={14} lg={6}>
              <span>Goal Type</span>
              <Form.Item name="kpiType">
                <Select
                  showSearch
                  style={{
                    width: "100%",
                  }}
                  placeholder="Goal Type"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
                  }
                  filterSort={(optionA, optionB) =>
                    (optionA?.label ?? "")
                      .toLowerCase()
                      .localeCompare((optionB?.label ?? "").toLowerCase())
                  }
                  allowClear
                  options={
                    goalType &&
                    goalType?.map((obj) => {
                      const keys = Object.keys(obj);
                      const convertedObj = {
                        value: obj["goalTypeId"],
                        label: obj["goalTypeName"],
                      };

                      for (let i = 2; i < keys.length; i++) {
                        convertedObj[keys[i]] = obj[keys[i]];
                      }

                      return convertedObj;
                    })
                  }
                />
              </Form.Item>
            </Col>

            <Col xs={24} md={14} lg={6}>
              <span>Active Status</span>
              {
                (location?.state?.data?.active == "true") ?
                  <Form.Item name="active">
                    <Switch checkedChildren="Yes" unCheckedChildren="No" defaultChecked />
                    
                  </Form.Item>
                  :
                  <Form.Item name="active">
                    <Switch checkedChildren="Yes" unCheckedChildren="No" />
                  </Form.Item>
              }
            </Col>

            <Col xs={24} md={24} lg={24}>
              <div className={styles.kpiDetails}>
                <span>Set KPI Dates</span>
              </div>
            </Col>

            <Col xs={24} md={24} lg={6}>
              <span>Start Date</span>
              <Form.Item name="kpiStartDate">
                <DatePicker style={{ width: "100%" }} />
              </Form.Item>
            </Col>
            <Col xs={24} md={24} lg={6}>
              <span>Due Date</span>

              <Form.Item name="kpiDueDate">
                <DatePicker style={{ width: "100%" }} />
              </Form.Item>
            </Col>
            <Col xs={24} md={24} lg={24}>
              <div className={styles.kpiDetails}>
                <span>Creator Data</span>
              </div>
            </Col>
            <Col xs={24} md={24} lg={6}>
              <span>Created On</span>
              <Form.Item name="createdAt">
                <DatePicker style={{ width: "100%" }} disabled />
              </Form.Item>
            </Col>
            <Col xs={24} md={24} lg={6}>
              <span>Created By</span>
              <Form.Item name="createdByName">
                <Input disabled />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item>
            <div className={styles.buttonContainer}>
              <Button className={`${styles.btn} ${styles.cancel}`} onClick={handleCancel}>Cancel</Button>
              <Button className={styles.btn} htmlType="submit" loading={isLoading}>{location?.state?.data?.kpiMasterId ? `Update KPI` : `Save KPI`}</Button>
            </div>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
}

export default CreateKPI;
