import React, { useContext, useEffect, useState } from "react";
import { Pagination, Table } from "antd";
import { api_call_token } from "../../../../Utils/Network";
import { UserCredsContext } from "../../../../Utils/UserCredsContext";
import moment from "moment";
import EditIcon from "../../../../Assets/ICONS/pen_solid.png";
import { useNavigate } from "react-router-dom";
import styles from "./index.module.css";

const ApprovedList = () => {
  const [data, setData] = useState([]);
  const [expandedRowKey, setExpandedRowKey] = useState(null);
  const [filterObj, setFilterObj] = useState({
    page: 1,
    goalApprovedStatus: 1,
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [totalNumberofPage, setTotalNumberofPage] = useState(null);
  let navigate = useNavigate();
  let { logout } = useContext(UserCredsContext);
  const expandedRowRender = (record) => {
    const columns = [
      { title: "KPI Id", dataIndex: "kpiId", key: "kpiId" },
      {
        title: "KPI Target", dataIndex: "kpiTarget", key: "kpiTarget",
        render: (_) => {
          const num = _;
          const roundedNum = parseFloat(num.toFixed(2))
          return(
            <>
              {roundedNum}
            </>
          )
        }
        
      },
      { title: "KPI Achievement", dataIndex: "kpiTargetAchievement", key: "kpiTargetAchievement" },
      { title: "Priority Name", dataIndex: "priorityName", key: "priorityName" },
      { title: "UOM Name", dataIndex: "uomName", key: "uomName" },
      {
        title: "Start Date", dataIndex: "kpiStartDate", key: "kpiStartDate", render: (_) => {
          return <div>{moment(_).format("DD-MMMM-YYYY")}</div>;
        },
      },
      {
        title: "Due Date", dataIndex: "kpiDueDate", key: "kpiDueDate", render: (_) => {
          return <div>{moment(_).format("DD-MMMM-YYYY")}</div>;
        },
      },
      // Add more columns as needed
    ];

    const data = record.goalKpiList.map((kpi) => ({
      key: kpi.kpiId,
      kpiId: kpi.kpiId,
      kpiTarget: kpi.kpiTarget,
      kpiTargetAchievement: kpi.kpiTargetAchievement,
      priorityName: kpi.priorityName,
      uomName: kpi.uomName,
      kpiStartDate: kpi.kpiStartDate,
      kpiDueDate: kpi.kpiDueDate,
      // Map other properties accordingly
    }));

    return <Table columns={columns} dataSource={data} pagination={false} />;
  };

  useEffect(() => {
    getGoals();
  }, []);

  const redirectTo = (para) => {
    console.log(para);
    navigate(`approvegoalpip`, { state: para });
  };

  const getGoals = (paramObj = { ...filterObj }) => {
    api_call_token
      .get(`goalpip`, { params: { ...paramObj } })
      .then((res) => {
        if (res.status == 200 || res.status == 201 || res.status == 204) {
          setData(res.data.data);
          setCurrentPage(res.data.currentPage);
          setTotalNumberofPage(res.data.totalRowCount)
        } else if (res.status == 401) {
          logout();
        }
      })
      .catch((err) => console.log(err));
  };

  const columns = [
    { title: "Id", dataIndex: "goalId", key: "goalId" },
    { title: "Goal Unique Id", dataIndex: "goalUniqueId", key: "goalUniqueId" },
    { title: "Name", dataIndex: "goalName", key: "goalName" },
    { title: "Goal Category", dataIndex: "goalCategory", key: "goalCategory" },
    { title: "Goal Type", dataIndex: "goalType", key: "goalType" },
    {
      title: "Start Date",
      dataIndex: "goalStartDate",
      key: "goalStartDate",
      render: (_) => {
        return <div>{moment(_).format("DD-MMMM-YYYY")}</div>;
      },
    },
    {
      title: "End Date",
      dataIndex: "goalEndDate",
      key: "goalEndDate",
      render: (_) => {
        return <div>{moment(_).format("DD-MMMM-YYYY")}</div>;
      },
    },
    {
      title: "Approval Status",
      dataIndex: "goalApprovedStatus",
      key: "goalApprovedStatus",
      render: (_) => {
        return (
          <div
            className={`${styles.category} ${_ == 0
              ? styles.pending
              : _ == 1
                ? styles.approved
                : styles.cancelled
              }`}
          >
            {_ == 0 ? "Pending" : _ == 1 ? "Approved" : "Rejected"}
          </div>
        );
      },
    },
    {
      title: "Action",
      dataIndex: "",
      render: (_, record) => {
        // const { purchaseOrderID } = record;
        const handleViewClick = () => {
          redirectTo(record);
        };
        return (
          <div>
            <div
              onClick={handleViewClick}
              style={{ width: "max-content", cursor: "pointer" }}
            >
              <img src={EditIcon} alt="consignment" />
            </div>
          </div>
        );
      },
    },
  ];

  const handlePageChange = (page) => {
    console.log(page, "aaaaaaaaa");
    setFilterObj({ ...filterObj, page: page });
    setCurrentPage(page);
  };

  

  const handleRowClick = (record) => {
    setExpandedRowKey((prevKey) =>
      prevKey === record.key ? null : record.key
    );
  };

  const paginationConfig = {
    current: currentPage,
    pageSize: 10, // Number of items per page
    total: totalNumberofPage, // Total number of items
    onChange: handlePageChange,
  };

  return (
    <div className={styles.mainData}>
      <Table
        columns={columns}
        pagination={false}
        expandable={{
          expandedRowRender,
          expandedRowKeys: [expandedRowKey],
          onExpand: (_, record) => handleRowClick(record),
          expandIcon: ({ expanded, onExpand, record }) => (
            <span onClick={() => handleRowClick(record)}>
              {expanded ? "-" : "+"}
            </span>
          ),
        }}
        dataSource={data.map((item, index) => ({
          ...item,
          key: index,
        }))}
      />

      <div style={{ textAlign: "right", margin: "10px" }}>
        <Pagination {...paginationConfig} showSizeChanger={false}/>
      </div>
    </div>

  );
};

export default ApprovedList;
