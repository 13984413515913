import { Table } from 'antd'
import React, { useContext, useEffect, useState } from 'react'
import styles from './index.module.css'
import moment from 'moment';
import { UserCredsContext } from '../../Utils/UserCredsContext';
import { api_call_token } from '../../Utils/Network';

function GoalList() {

    const [data, setData] = useState([]);
    const [expandedRowKey, setExpandedRowKey] = useState(null);

    let { logout } = useContext(UserCredsContext);


    useEffect(() => {
        getGoals();
    }, []);

    const getGoals = () => {
        api_call_token
            .get(`goal/foruser`)
            .then((res) => {
                if (res.status === 200 || res.status === 201 || res.status === 204) {
                    setData(res.data.data);
                } else if (res.status === 401) {
                    logout();
                }
            })
            .catch((err) => console.log(err));
    };

    const expandedRowRender = (record) => {
        const columns = [
            { title: "KPI Name", dataIndex: "kpiName", key: "kpiName" },
            { title: "Due Date", dataIndex: "kpiDueDate", key: "kpiDueDate", render: (_) => <div>{moment(_).format("DD-MMMM-YYYY")}</div> },
            {
                title: "KPI Description",
                dataIndex: "kpiDescription",
                key: "kpiDescription",
            },
            { title: "KPI Start Date", dataIndex: "kpiStartDate", key: "kpiStartDate", render: (_) => <div>{moment(_).format("DD-MMMM-YYYY")}</div>, },
            { title: "KPI Target", dataIndex: "kpiTarget", key: "kpiTarget", render: (_) => <div>{moment(_).format("DD-MMMM-YYYY")}</div>, },
            {
                title: "KPI Target Achievement",
                dataIndex: "kpiTargetAchievement",
                key: "kpiTargetAchievement",
            },
            {
                title: "KPI Target Achievement Value",
                dataIndex: "kpiTargetAchievmentValue",
                key: "kpiTargetAchievmentValue",
                render: (_) =>{
                    const num = _ ;
                    const roundedNum = parseFloat(num.toFixed(2));
                    return(
                        <div>{roundedNum}</div>
                    )
                }
             },
            { title: "KPI Type", dataIndex: "goalTypeName", key: "goalTypeName" },
        ];

        const data = record.goalKpiList.map((kpi) => ({
            key: kpi.kpiId,
            kpiName: kpi.kpiName,
            kpiDueDate: kpi.kpiDueDate,
            kpiDescription: kpi.kpiDescription,
            kpiTarget: kpi.kpiTarget,
            kpiTargetAchievement: kpi.kpiTargetAchievement,
            kpiTargetAchievmentValue: kpi.kpiTargetAchievmentValue,
            goalTypeName: kpi.goalTypeName,
            // Map other properties accordingly
        }));

        return <Table columns={columns} dataSource={data} pagination={false} />;
    };

    const handleRowClick = (record) => {
        setExpandedRowKey((prevKey) =>
          prevKey === record.key ? null : record.key
        );
      };

    const columns = [
        { title: "Id", dataIndex: "goalId", key: "goalId" },
        { title: "Goal Unique Id", dataIndex: "goalUniqueId", key: "goalUniqueId" },
        { title: "Name", dataIndex: "goalName", key: "goalName" },
        { title: "Goal Category", dataIndex: "goalCategory", key: "goalCategory" },
        { title: "Goal Type", dataIndex: "goalType", key: "goalType" },
        { title: "Goal Progress", dataIndex: "goalProgress", key: "goalProgress", render: (_) => <div className={`${(_ > 100) ? styles.darkOrange : (_ > 60) ? styles?.category : styles?.orange} `}><div style={{ width: `${(_ > 100) ? 100 : _}%`, background: `${(_ > 100) ? '#b16a00' : (_ > 60) ? `#13CE66` : "#FD8902"}`, height: '100%' }}></div></div> },
        {
            title: "Start Date",
            dataIndex: "goalStartDate",
            key: "goalStartDate",
            render: (_) => <div>{moment(_).format("DD-MMMM-YYYY")}</div>,
        },
        {
            title: "End Date",
            dataIndex: "goalEndDate",
            key: "goalEndDate",
            render: (_) => <div>{moment(_).format("DD-MMMM-YYYY")}</div>,
        }
    ];

    return (
        <div>

            <Table
                columns={columns}
                pagination={false}
                expandable={{
                    expandedRowRender,
                    expandedRowKeys: [expandedRowKey],
                    onExpand: (_, record) => handleRowClick(record),
                    expandIcon: ({ expanded, onExpand, record }) => (
                        <span onClick={() => handleRowClick(record)}>
                            {expanded ? "-" : "+"}
                        </span>
                    ),
                }}
                dataSource={data.map((item, index) => ({
                    ...item,
                    key: index,
                }))}
            />

        </div>
    )
}

export default GoalList