import React, { useEffect, useState } from "react";
import styles from "./graph.module.css"; // Assuming your CSS file is named styles.css
import { api_call_token } from "../../Utils/Network";

function Box({ countNum, priority, count }) {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  if (count > 0) {
    return (
      //   ${isHovered ? styles.hovered : ""}
      <div
        style={{
          width: `${(count / countNum) * 100}%`,
          position: "relative",
          margin: "0 5px 0 0",
        }} // Adjust the multiplier for desired width
      >
        <div
          className={`${styles.box} ${styles[priority]}`}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <div className={styles.inner}>{priority}</div>
        </div>
        <div style={{ marginTop: "5px", marginLeft: '2px', display: "flex", fontSize: '14px' }}>
          {priority == "urgent" ? (
            <div className={`${styles.icons} ${styles[`${priority}s`]}`}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="14"
                height="14"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="lucide lucide-alert-circle text-white"
              >
                <circle cx="12" cy="12" r="10"></circle>
                <line x1="12" x2="12" y1="8" y2="12"></line>
                <line x1="12" x2="12.01" y1="16" y2="16"></line>
              </svg>
            </div>
          ) : priority == "high" ? (
            <div className={`${styles.icons} ${styles[`${priority}s`]}`}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="14"
                height="14"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="lucide lucide-signal-high translate-x-[0.0625rem]"
              >
                <path d="M2 20h.01"></path>
                <path d="M7 20v-4"></path>
                <path d="M12 20v-8"></path>
                <path d="M17 20V8"></path>
              </svg>
            </div>
          ) : priority == "medium" ? (
            <div className={`${styles.icons} ${styles[`${priority}s`]}`}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="14"
                height="14"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="lucide lucide-signal-medium translate-x-0.5"
              >
                <path d="M2 20h.01"></path>
                <path d="M7 20v-4"></path>
                <path d="M12 20v-8"></path>
              </svg>
            </div>
          ) : priority == "low" ? (
            <div className={`${styles.icons} ${styles[`${priority}s`]}`}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="14"
                height="14"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="lucide lucide-signal-low translate-x-1"
              >
                <path d="M2 20h.01"></path>
                <path d="M7 20v-4"></path>
              </svg>
            </div>
          ) : (
            <div className={`${styles.icons} ${styles[`${priority}s`]}`}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="14"
                height="14"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="lucide lucide-ban "
              >
                <circle cx="12" cy="12" r="10"></circle>
                <path d="m4.9 4.9 14.2 14.2"></path>
              </svg>
            </div>
          )}
          {Math.round((count / countNum) * 100)}%
        </div>
      </div>
    );
  } else return null;
}

function PriorityChart() {
  const [countNum, setCountNum] = useState(null);
  // const data = [
  //   { priority: "urgent", count: 1 },
  //   { priority: "high", count: 4 },
  //   { priority: "medium", count: 4 },
  //   { priority: "low", count: 1 },
  //   { priority: "none", count: 1 },
  // ];
  const [data, setData] = useState([]);

  const getChartData = () => {
    api_call_token
      .get(`dashboardpriority`)
      .then((response) => {
        if (response?.data) {
          setData(response?.data);
          let resData = response?.data;
          let count = 0;
          resData.map((v, i) => {
            count = count + v?.count;
          });
          setCountNum(count);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getChartData();
  }, []);
  return (
    <div className={styles.container}>
      {data.map((item, index) => (
        <>
          <Box
            key={index}
            priority={item.priority}
            count={item.count}
            countNum={countNum}
          />
        </>
      ))}
    </div>
  );
}

export default PriorityChart;
